import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CameraService } from '../../camera/camera.service';
import { LoggerService } from '../../logger/logger.service';
import { Attachment } from '../attachment.model';
import { AttachmentService } from '../attachment.service';
import { switchMap } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils.service';
import { Storage } from '@ionic/storage-angular';
import { environment } from '../../../../environments/environment';
import S3 from 'aws-sdk/clients/s3';
import { NotificationService } from '../../notification/notification.service';
import { from } from 'rxjs';
import { SettingsService } from 'src/app/settings/settings.service';
import { Directory } from '@capacitor/filesystem';
import { CameraOrientation } from '../../camera/camera-orientation.enum';

@Component({
    selector: 'app-attachment-select',
    templateUrl: './attachment-select.component.html',
    styleUrls: ['./attachment-select.component.scss'],
})
export class AttachmentSelectComponent implements OnInit {
    private s3: S3;

    @Input() dialog = true;
    @Input() buttonText = 'Datei hochladen';
    @Input() forceDirectUpload = false;
    @Input() noUpload = false; // will not upload or save files if true, will not output fileSelected event, instead outputs noUploadFileSelected event
    @Input() lockCameraOrientation: CameraOrientation = CameraOrientation.NONE;
    @Input() multipleFiles = false;

    @Input() folder = ''; // optional if the attachment shall be created in a sub folder (e.g. project folder)
    @Input() btnColor = 'primary';
    @Input() allowedFileTypes: Array<'image' | 'pdf'> = [];
    @Output() compInitialized = new EventEmitter<any>(); // emitted as soon as ngOnInit is done
    @Output() fileSelected = new EventEmitter<Attachment | Attachment[]>();
    @Output() noUploadFileSelected = new EventEmitter<File | File[]>();
    @ViewChild('fileSelectInput') fileSelectInput: ElementRef;

    allowedImageMimeTypes = ['image/png', 'image/jpeg']; // quick fix image/webp removed because of report generation

    // direct upload helper variables (progress calculation, etc.)
    currentlyUploading = false;
    uploadedBytesPerAttachment: Array<{
        attachmentId: string;
        uploadedBytes: number;
    }> = [];
    totalBytesToUpload = 0;
    uploadedBytes = 0;

    // helper
    selectModalVisible = false;
    fileOverDropZone = false;
    uniqueId: string;

    constructor(
        protected storage: Storage,
        protected cameraSrv: CameraService,
        protected attachmentSrv: AttachmentService,
        public utils: UtilsService,
        protected logger: LoggerService,
        protected notify: NotificationService,
        protected settingsSrv: SettingsService
    ) {
        this.s3 = new S3({
            apiVersion: '2006-03-01',
            httpOptions: {
                timeout: 3600000, // 1 hour timeout
            },
            correctClockSkew: true,
        });
    }

    async ngOnInit() {
        // If using a custom driver:
        // await this.storage.defineDriver(MyCustomDriver)
        await this.storage.create();
        this.uniqueId = this.utils.generateUuid();
        this.compInitialized.emit(undefined);
    }

    /**
     * Triggered when the user chooses one or multiple files via the choose-dialog or the dropzone.
     * They will be uploaded (or saved for later upload) and if everything has finished the fileSelected Event will be triggered
     */
    async onFilesChosen(files: FileList) {
        this.logger.log('[ATTACHMENT SELECT] files chosen', files);

        let filesArray = Array.from(files);
        let attachments = [];

        let allowFileUpload = true;
        if (this.allowedFileTypes.length > 0) {
            for (let file of filesArray) {
                if (file.type == 'application/pdf') {
                    if (!this.allowedFileTypes.includes('pdf')) {
                        allowFileUpload = false;
                    }
                } else if (this.allowedImageMimeTypes.includes(file.type)) {
                    if (!this.allowedFileTypes.includes('image')) {
                        allowFileUpload = false;
                    }
                } else {
                    allowFileUpload = false;
                }
            }
        }

        // check if file upload is allowed
        if (!allowFileUpload) {
            this.notify.error('Das Hochladen dieses Datei-Typs ist nicht erlaubt!');
            return;
        }

        if (this.noUpload) {
            this.logger.log('[ATTACHMENT SELECT] no-upload required - only emitting selected files');
            this.selectModalVisible = false;
            if (this.fileSelectInput) {
                this.fileSelectInput.nativeElement.value = ''; // reset input to allow new uploads
            }

            // no upload is used, we only emit the filesArray and stop execution
            if (filesArray.length === 1) {
                this.noUploadFileSelected.emit(filesArray[0]);
            } else {
                this.noUploadFileSelected.emit(filesArray);
            }
            return;
        }

        // reset upload progress (only used if directUpload is true)
        this.totalBytesToUpload = 0;
        this.uploadedBytesPerAttachment = [];
        this.uploadedBytes = 0;

        // create attachment models for each chosen file
        for (let file of filesArray) {
            this.totalBytesToUpload += file.size;
            let attachment = new Attachment();
            attachment.displayName = file.name;
            attachment.fileName = attachment._id + '-og' + '.' + file.name.split('.').pop();
            attachment.fileMimeType = file.type;

            // build attachment path
            let folderToUse = this.getFileSubdirectory();
            attachment.filePath = (folderToUse != '' ? folderToUse : attachment._id) + '/' + attachment.fileName;

            attachments.push(attachment);
        }

        if (this.forceDirectUpload) {
            // direct upload is required, we will upload the files immediately
            this.logger.log('[ATTACHMENT SELECT] direct upload required - uploading files now');
            this.currentlyUploading = true;
            let successfulAttachments = [];
            try {
                let fileIndex = 0;
                for (let file of filesArray) {
                    let s3UploadResponse = await this.s3
                        .putObject({
                            Bucket: environment.AWS_S3_BUCKET_NAME,
                            Body: file,
                            Key: environment.AWS_S3_CUSTOMER_DATA_BASE_PATH + attachments[fileIndex].filePath,
                            ContentType: this.utils.getMimeTypeForFileEnding(this.utils.getFileEnding(attachments[fileIndex].filePath)),
                        })
                        .on('httpUploadProgress', (progress) => {
                            let existingProgress = this.uploadedBytesPerAttachment.find(
                                (x) => x.attachmentId == attachments[fileIndex]._id
                            );
                            if (existingProgress) {
                                existingProgress.uploadedBytes = progress.loaded;
                            } else {
                                this.uploadedBytesPerAttachment.push({
                                    attachmentId: attachments[fileIndex]._id,
                                    uploadedBytes: progress.loaded,
                                });
                            }
                            let calculateUploadedBytes = 0;
                            for (let progressPerAttachment of this.uploadedBytesPerAttachment) {
                                calculateUploadedBytes += progressPerAttachment.uploadedBytes;
                            }
                            this.uploadedBytes = calculateUploadedBytes;
                        })
                        .promise();
                    this.logger.log('[ATTACHMENT SELECT] direct upload for attachment successful', attachments[fileIndex]);
                    successfulAttachments.push(attachments[fileIndex]);
                    fileIndex++;
                }
                this.logger.log('[ATTACHMENT SELECT] finished direct upload');
            } catch (s3UploadErr) {
                this.logger.error('[ATTACHMENT SELECT] could not upload files to s3', s3UploadErr);
                this.notify.error(
                    'Ein oder mehrere Dateien konnten nicht hochgeladen werden. Bitte prüfe deine Verbindung und versuche es erneut.'
                );
            }

            this.selectModalVisible = false;
            if (this.fileSelectInput) {
                this.fileSelectInput.nativeElement.value = ''; // reset input to allow new uploads
            }
            if (successfulAttachments.length == 1) {
                this.fileSelected.emit(successfulAttachments[0]); // emit only one item instead of array, if there is only one entry
            } else {
                this.fileSelected.emit(successfulAttachments);
            }
            this.currentlyUploading = false;
        } else {
            // no direct upload required, we will save the files on disk and upload them later (during upstream sync)
            this.logger.log('[ATTACHMENT SELECT] no direct upload - saving files on disk for later upload during sync');

            let fileIndex = 0;
            let successfulAttachments = [];
            for (let file of filesArray) {
                try {
                    let fileContent = await this.attachmentSrv.convertBlobToBase64Promise(file);
                    let fileSaved = await this.attachmentSrv.saveAttachmentToDiskPromise(
                        attachments[fileIndex].filePath,
                        fileContent as string,
                        true
                    );
                    successfulAttachments.push(attachments[fileIndex]);
                    this.logger.log('[ATTACHMENT SELECT] saved file content to disk', attachments[fileIndex]);
                } catch (e) {
                    this.logger.error('[ATTACHMENT SELECT] could not save file contents to disk', e, attachments[fileIndex], file);
                }
                fileIndex++;
            }

            // we have saved the file contents to our local disk for later upload,

            this.selectModalVisible = false;
            if (this.fileSelectInput) {
                this.fileSelectInput.nativeElement.value = ''; // reset input to allow new uploads
            }
            if (successfulAttachments.length == 1) {
                this.fileSelected.emit(successfulAttachments[0]); // emit only one item instead of array, if there is only one entry
            } else {
                this.fileSelected.emit(successfulAttachments);
            }
        }
    }

    /**
     * Triggered when the user clicks the 'open-camera' button
     */
    onTakePhoto() {
        this.logger.log('[ATTACHMENT SELECT] trying to take photo');
        let photo = new Attachment();

        let photoProcess;

        if (this.utils.isNative) {
            // when native we just move the file from the cache directory to data

            if (this.settingsSrv.getUserSettings().enableNewCamera) {
                photoProcess = this.cameraSrv.takePhotoModal(true, this.lockCameraOrientation).pipe(
                    switchMap((photoUri) => {
                        photo.displayName = 'Foto';
                        photo.fileName = Date.now() + '-og' + '.jpg';
                        photo.fileMimeType = 'image/jpg';
                        let fromDirectory = undefined;
                        if (photoUri.indexOf('file://') < 0) {
                            fromDirectory = Directory.Cache;
                        }
                        let folderToUse = this.getFileSubdirectory();
                        photo.filePath = (folderToUse != '' ? folderToUse : photo._id) + '/' + photo.fileName;

                        return new Promise(async (resolve, reject) => {
                            try {
                                let moveResult = await this.attachmentSrv.moveFile(photoUri, photo.filePath, fromDirectory);
                                await this.attachmentSrv.copyAttachmentToSyncFolder(photo.filePath);
                                resolve(moveResult);
                            } catch (err) {
                                if (err.message && err.message.toLowerCase().indexOf('unable to rename') !== -1) {
                                    this.logger.log('[ATTACHMENT SELECT] Falling back to file copy for NEW CAMERA');

                                    try {
                                        let moveResult = await this.attachmentSrv.copyFile(photoUri, photo.filePath, fromDirectory);
                                        await this.attachmentSrv.copyAttachmentToSyncFolder(photo.filePath);
                                        resolve(moveResult);
                                    } catch (copyErr) {
                                        reject(copyErr);
                                        return;
                                    }
                                }
                                reject(err);
                            }
                        });
                    })
                );
            } else {
                photoProcess = this.cameraSrv.takePhoto(true, this.lockCameraOrientation).pipe(
                    switchMap((cameraPhoto) => {
                        photo.displayName = 'Foto'; // set file display name
                        photo.fileName = photo._id + '-og' + '.' + cameraPhoto.format; // set file name
                        photo.fileMimeType = 'image/' + cameraPhoto.format; // set file mimetype

                        let folderToUse = this.getFileSubdirectory();
                        photo.filePath = (folderToUse != '' ? folderToUse : photo._id) + '/' + photo.fileName;

                        // check if file mime type is allowed
                        if (!this.allowedImageMimeTypes.includes(photo.fileMimeType)) {
                            throw new Error('file mime type not allowed');
                        }

                        if (!cameraPhoto.path && cameraPhoto.base64String) {
                            this.logger.warn('[ATTACHMENT SELECT] camera photo item should have path but base64 found');
                            return this.attachmentSrv.saveAttachmentToDiskPromise(photo.filePath, cameraPhoto.base64String, true);
                        }
                        return new Promise(async (resolve, reject) => {
                            try {
                                let moveResult = await this.attachmentSrv.moveFile(cameraPhoto.path, photo.filePath);
                                await this.attachmentSrv.copyAttachmentToSyncFolder(photo.filePath);
                                resolve(moveResult);
                            } catch (err) {
                                if (err.message && err.message.toLowerCase().indexOf('unable to rename') !== -1) {
                                    this.logger.log('[ATTACHMENT SELECT] Falling back to file copy');

                                    try {
                                        let moveResult = await this.attachmentSrv.copyFile(cameraPhoto.path, photo.filePath);
                                        await this.attachmentSrv.copyAttachmentToSyncFolder(photo.filePath);
                                        resolve(moveResult);
                                    } catch (copyErr) {
                                        reject(copyErr);
                                        return;
                                    }
                                }
                                reject(err);
                            }
                        });
                    })
                );
            }
        } else {
            // otherwise we need to store it through base64
            photoProcess = this.cameraSrv.takePhoto(true, this.lockCameraOrientation).pipe(
                switchMap((cameraPhoto) => {
                    photo.displayName = 'Foto'; // set file display name
                    photo.fileName = photo._id + '-og' + '.' + cameraPhoto.format; // set file name
                    photo.fileMimeType = 'image/' + cameraPhoto.format; // set file mimetype
                    let folderToUse = this.getFileSubdirectory();
                    photo.filePath = (folderToUse != '' ? folderToUse : photo._id) + '/' + photo.fileName;

                    // check if file mime type is allowed
                    if (!this.allowedImageMimeTypes.includes(photo.fileMimeType)) {
                        throw new Error('file mime type not allowed');
                    }

                    return this.attachmentSrv.readWebPathAsBase64(cameraPhoto.webPath);
                }),

                switchMap((base64Content: string) => {
                    return from(this.attachmentSrv.saveAttachmentToDiskPromise(photo.filePath, base64Content, true));
                })
            );
        }

        photoProcess.subscribe(
            (res) => {
                this.logger.log('[ATTACHMENT SELECT] saved attachment to disk', res, photo.filePath);

                this.selectModalVisible = false;
                this.fileSelected.emit(photo);
            },
            (err) => {
                if (err.message === 'file mime type not allowed') {
                    this.notify.error('Das Hochladen dieses Datei-Typs ist nicht erlaubt!');
                    return;
                }
                if (err.message != 'User cancelled photos app') {
                    this.logger.error('[ATTACHMENT SELECT] failed to take photo', err);
                    this.utils.sentryCaptureException(err, 'error');
                }
                this.selectModalVisible = false;
            }
        );
    }

    /**
     * Prepares the given folder @input data for use as subdirectory
     * @returns subdirectory which can be prepended to files or empty string if not applicable
     */
    protected getFileSubdirectory(): string {
        let folderToUse = this.utils.clone(this.folder);
        if (folderToUse != null && folderToUse != '') {
            // remove forward-slash at start or end, if there is one
            if (folderToUse.charAt(0) === '/') {
                folderToUse = folderToUse.substring(1);
            }
            if (folderToUse.charAt(folderToUse.length - 1) === '/') {
                folderToUse = folderToUse.substring(0, folderToUse.length - 1);
            }
            return folderToUse;
        } else {
            return '';
        }
    }

    /**
     * Triggered when the user drags a file over the drop zone
     * @param event
     */
    onDragEnter(event) {
        event.preventDefault();
        event.stopPropagation();
        this.fileOverDropZone = true;
    }

    /**
     * Triggers while the user holds the file over the dropzone (needed for dropping)
     * @param event
     */
    onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Triggered when the user leaves the dropzone while dragging an item
     * @param event
     */
    onDragLeave(event) {
        if (event.currentTarget.contains(event.relatedTarget)) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        this.fileOverDropZone = false;
    }

    /**
     * Triggered when the user drops files on the dropzone
     * @param event
     */
    onDropFiles(event) {
        event.preventDefault();
        event.stopPropagation();
        this.fileOverDropZone = false;
        this.logger.log('[ATTACHMENT SELECT] dropped files', event.dataTransfer.files);
        this.onFilesChosen(event.dataTransfer.files);
    }
}
