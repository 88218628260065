import { LoggerService } from './../logger/logger.service';
import { UtilsService } from './../utils/utils.service';
import { IonInput, Platform } from '@ionic/angular';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
// import { BarcodeScanner, CameraDirection } from '@capacitor-community/barcode-scanner';
import { BarcodeFormat, BarcodeScanner, LensFacing, ScanErrorEvent } from '@capacitor-mlkit/barcode-scanning';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { environment } from '../../../environments/environment';

interface ZXingCameraDevice {
    deviceId: string;
    groupId: string;
    kind: string;
    label: string;
}

const AGI_PREFIX = 'AGI';
const AGI_LINK = 'agi-id.ch/?agiid=';
const DP_WEBCODE_LINK = environment.WEBCODE_LINK;

@Component({
    selector: 'app-code-scanner',
    templateUrl: './code-scanner.component.html',
    styleUrls: ['./code-scanner.component.scss'],
})
export class CodeScannerComponent implements OnInit, OnDestroy {
    @ViewChild('codeSearchInput') codeSearchInput: IonInput;
    @ViewChild('webScanner', { static: false }) webScanner: ZXingScannerComponent;

    @Output() onScanResult = new EventEmitter<string>();
    @Output() onScanCancel = new EventEmitter<string>();
    @Output() isEnabledChange = new EventEmitter<boolean>();

    @Input() containerType: 'default' | 'modal' | 'dialog' = 'default';
    @Input() searchIcon = 'search';
    @Input() searchTextPlaceholder = 'Code eingeben';
    @Input() set isEnabled(value: boolean) {
        if (this._isEnabled && value === false && this.utils.isNative) {
            this.stopNativeScan();
        }

        this._isEnabled = value;
        this.isEnabledChange.emit(this._isEnabled);

        if (this._isEnabled && this.utils.isNative) {
            this.doPrep();
            this.startNativeScan();
        }
    }
    get isEnabled() {
        return this._isEnabled;
    }

    isSearchAvailable = false;
    webScannerEnabled = false;
    supportedFormats = ['QR_CODE', 'CODE_39', 'CODE_128'];
    supportedNativeFormats = [BarcodeFormat.QrCode, BarcodeFormat.Code39, BarcodeFormat.Code128];
    torchActive = false;
    allowTorchActivation = false;
    allowCameraSwap = false;
    availableDevices: Array<ZXingCameraDevice>;
    desiredDevice: ZXingCameraDevice;
    webScannerLoading = false;

    private _isEnabled: boolean;
    private _isPrepared: boolean;

    constructor(
        public platform: Platform,
        public utils: UtilsService,
        private logger: LoggerService,
        private zone: NgZone,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.doPrep();
    }

    ngOnDestroy() {
        this.resetScanState();
    }

    async doPrep() {
        // speeds up camera feed but eats away at the battery when starting the app due to always being in background
        // BarcodeScanner.prepare();

        if (this.utils.isNative) {
            // only run this when being native in order for the web based event function
            // onTorchCompatible to work without race condition
            let torchAvail = await BarcodeScanner.isTorchAvailable();
            this.allowTorchActivation = torchAvail.available;
            // while using our scanner in components with onPush change detection it can happen for the torch check to
            // need long enough to not show up on the ui - this fixes it even though there could be a better way
            this.cdRef.detectChanges();
        }

        if (this.utils.isNative && !this._isPrepared) {
            try {
                if (!(await this.didUserGrantPermission())) {
                    const c = confirm(
                        'DokuPit wurde anscheinend der Zugriff auf die Kamera verweigert. Damit der Scanner funktioniert ist das aber notwendig. Sollen die Berechtigungseinstellungen geöffnet werden um das zu ändern?.'
                    );
                    if (c) {
                        await BarcodeScanner.openSettings();
                    }
                }
            } catch (er) {
                this.logger.error('[SCANNER] prep', er);
                this.utils.sentryCaptureException(er);
            }
            this._isPrepared = true;
        }
    }

    async startNativeScan() {
        try {
            try {
                // try locking orientation while running scanner
                await ScreenOrientation.lock({ orientation: 'portrait' });
            } catch (lockEx) {
                this.logger.warn('[SCANNER] orientation lock failed', lockEx);
            }

            document.body.classList.add('scanner-visible');
            if (this.containerType == 'modal') {
                document.body.classList.add('scanner-in-modal');
            }
            if (this.containerType == 'dialog') {
                document.body.classList.add('scanner-in-dialog');
            }
            if (this.utils.isPhone) {
                document.body.classList.add('scanner-mobile');
            }

            // Register error and result listeners but run the results in the Angular zone to trigger the change detection correctly
            const scanErrorListener = await BarcodeScanner.addListener('scanError', async (errorEvent: ScanErrorEvent) => {
                await this.zone.run(async () => {
                    await scanErrorListener.remove();
                    this.logger.error('[SCANNER] error during scan', errorEvent.message);

                    await this.resetScanState();
                });
            });

            const scanListener = await BarcodeScanner.addListener('barcodeScanned', async (result) => {
                await this.zone.run(async () => {
                    await scanListener.remove();

                    // if the result has content
                    if (result.barcode != null) {
                        if (environment.debug) {
                            this.logger.log('[SCANNER] got result', result.barcode);
                        }
                        let code = this.preprocessCode(result.barcode.rawValue);
                        this.onScanResult.emit(code);
                    }

                    await this.resetScanState();
                });
            });

            await BarcodeScanner.startScan({ lensFacing: LensFacing.Back, formats: this.supportedNativeFormats }); // start scanning and wait for a result
        } catch (er) {
            this.logger.error('[SCANNER] scan', er);
            this.utils.sentryCaptureException(er);
        }
    }

    /**
     * Function to stop the native camera and remove CSS classes
     * DON'T call this function directly! It automatically is triggered by setting isEnabled
     */
    async stopNativeScan() {
        try {
            await BarcodeScanner.stopScan();
            this.isSearchAvailable = false;
            document.body.classList.remove('scanner-visible');
            if (this.containerType == 'modal') {
                document.body.classList.remove('scanner-in-modal');
            }
            if (this.containerType == 'dialog') {
                document.body.classList.remove('scanner-in-dialog');
            }
            if (this.utils.isPhone) {
                document.body.classList.remove('scanner-mobile');
            }
        } catch (er) {
            this.logger.error('[SCANNER] stop', er);
            this.utils.sentryCaptureException(er);
        }

        // Keep device orientation locked or unlock it based on device type
        this.utils.applyBaseScreenlock();
    }

    async searchCode() {
        if (this.codeSearchInput && (this.codeSearchInput.value !== '' || this.codeSearchInput.value !== undefined)) {
            this.onScanResult.emit(this.preprocessCode(this.codeSearchInput.value as string));
        }

        await this.resetScanState();
    }

    async cancelScan() {
        if (this.isSearchAvailable) {
            this.codeSearchInput.value = undefined;
            this.isSearchAvailable = false;
        } else {
            await this.resetScanState();
            this.onScanCancel.emit();
        }
    }

    async codeChanged(inputData) {
        if (inputData.detail.value !== '' && inputData.detail.value !== undefined) {
            this.isSearchAvailable = true;
        } else {
            this.isSearchAvailable = false;
        }
    }

    async didUserGrantPermission() {
        // check if user already granted permission
        const { camera } = await BarcodeScanner.checkPermissions();

        if (camera == 'granted') {
            // user granted permission
            return true;
        }

        if (camera == 'denied') {
            // the user denied permission for good
            // redirect user to app settings if they want to grant it anyway
            const c = confirm('DokuPit wurde der Zugriff auf die Kamera verweigert. Bitte erlaube es in den Systemeinstellungen.');
            if (c) {
                await BarcodeScanner.openSettings();
            }
            return false;
        }
        // there are other states: prompt abd prompt-with-rationale - none are more in-depth documented so we leave them for the time being

        const requestStatus = await BarcodeScanner.requestPermissions();

        if (requestStatus.camera == 'granted') {
            // user allowed
            return true;
        }

        // user did not grant the permission, so he must have declined the request
        return false;
    }

    private async resetScanState() {
        this.isSearchAvailable = false;

        if (this.webScanner) {
            this.webScannerLoading = true;
            if (this.webScanner.isAutostarting) {
                await this.delay(400);
                await this.resetScanState();
                return;
            }
            this.desiredDevice = undefined;
            this.webScanner.reset();
        }
        this.webScannerLoading = false;
        this.isEnabled = false;
        this.webScannerEnabled = false;
    }

    private delay(ms: number): Promise<any> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    // WEB SCANNER METHODS

    /**
     * Tells if we can use the torch on the device
     * @param isCompatible boolean if the torch on the device is compatible with the scanner
     */
    onTorchCompatible(isCompatible) {
        this.allowTorchActivation = isCompatible;
    }

    async toggleTorch() {
        if (this.utils.isNative) {
            if (this.torchActive) {
                await BarcodeScanner.disableTorch();
            } else {
                await BarcodeScanner.enableTorch();
            }
        }

        this.torchActive = !this.torchActive;
    }

    onWebCamerasFound(cameraDevices) {
        if (cameraDevices && cameraDevices.length > 0) {
            this.availableDevices = cameraDevices;
            console.log('[SCANNER] found devices', cameraDevices);

            this.desiredDevice = this.availableDevices[0];

            if (this.availableDevices.length > 1) {
                this.allowCameraSwap = true;
            }
        }
    }
    onWebCamerasNotFound(event) {
        this.logger.error('[SCANNER] Camera devices could not be found', event);
        this.utils.sentryCaptureMessage('[SCANNER] Failed getting cameras for scan');
    }

    toggleCameraDevice() {
        // TODO: When there are 3 or more devices, only the first 2 will be used
        if (this.availableDevices) {
            this.desiredDevice = this.availableDevices.find((a) => a.deviceId != this.desiredDevice?.deviceId);
        }
    }

    async onWebScanSuccess(result: string) {
        await this.resetScanState();
        this.onScanResult.emit(this.preprocessCode(result));
    }
    onWebScanError(err) {
        this.logger.error(err);
        this.utils.sentryCaptureException(err);
    }
    onWebScanFailure(err) {
        if (err != 'NotFoundException: No MultiFormat Readers were able to detect the code.') {
            this.logger.error(err);
        }
    }

    onInitWebScan() {
        this.webScannerEnabled = true;
        this.webScannerLoading = true;

        // Allow the camera to start up
        setTimeout((_) => {
            this.webScannerLoading = false;
        }, 4000);
    }

    private preprocessCode(code?: string) {
        if (!code) return code;

        if (code.indexOf(AGI_LINK) !== -1) {
            this.logger.log('[SCANNER] Found AGI QR Code! Using raw number...', code);

            let startOfCode = code.indexOf(AGI_LINK);
            code = AGI_PREFIX + code.substring(startOfCode + AGI_LINK.length);
            this.logger.log('[SCANNER] New code', code);
        }

        try {
            // support for webCodes with "app.dokupit.com/code......."
            if (code.trim().startsWith(DP_WEBCODE_LINK)) {
                let codeUrl = new URLSearchParams(new URL(code).search);
                let codeFromUrl = codeUrl?.get('cd');
                if (codeFromUrl != null) {
                    this.logger.log('[SCANNER] WEB-CODE -> parsed code', code);
                    code = codeFromUrl;
                }
            }
        } catch (urlParseErr) {
            // TODO: why we not handling this?
        }

        return code;
    }
}
