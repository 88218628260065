<div class="sync-status-wrap">
    <div class="sync-status-heading">
        {{ syncStatus === false ? 'Synchronisierung abgeschlossen' : 'Synchronisierung läuft' }}
    </div>
    <div class="sync-status-sub-heading">
        <span *ngIf="syncStatus === 'upstream'">Daten werden hochgeladen</span>
        <span *ngIf="syncStatus === 'downstream'">Daten werden heruntergeladen</span>
        <span *ngIf="syncStatus === 'cleanup'">Datenbank wird optimiert</span>
    </div>
    <div class="sync-footer">
        <ion-row>
            <ion-col size="9" class="no-padding d-flex ion-align-items-center">
                <div class="sync-count">
                    <span>{{ syncInfo.totalDownstreamCount }} Datensätze heruntergeladen</span>
                </div>
            </ion-col>
            <ion-col size="3" class="no-padding d-flex ion-align-items-center ion-justify-content-end">
                <div class="sync-debug-actions ion-text-end">
                    <ion-button color="warning" class="round" size="small" slot="end" (click)="onStartDebugDownstream()">
                        <fa-icon icon="cloud-download"></fa-icon>
                    </ion-button>
                </div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="9" class="no-padding d-flex ion-align-items-center">
                <div class="sync-count">
                    <span>{{ syncInfo.totalUpstreamCount }} Datensätze hochgeladen</span>
                </div>
            </ion-col>
            <ion-col size="3" class="no-padding d-flex ion-align-items-center ion-justify-content-end">
                <div class="sync-debug-actions ion-text-end">
                    <ion-button color="warning" class="round" size="small" (click)="onStartDebugUpstream()">
                        <fa-icon icon="cloud-upload"></fa-icon>
                    </ion-button>
                </div>
            </ion-col>
        </ion-row>
    </div>
</div>
