import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    CameraPreview,
    CameraPreviewFlashMode,
    CameraPreviewOptions,
    CameraPreviewPictureOptions,
} from '@capacitor-community/camera-preview';
import { ModalController, Platform } from '@ionic/angular';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { Storage } from '@ionic/storage-angular';
import { DpImageEditorProcessData } from '../../attachment/image-editor/image-editor.component';
import write_blob from 'capacitor-blob-writer';
import { VolumeButtons, VolumeButtonsOptions, VolumeButtonsResult } from '@capacitor-community/volume-buttons';
import { CameraOrientation } from '../camera-orientation.enum';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { NotificationService } from '../../notification/notification.service';

// Extended type to pass more data to native plugin
interface DpCameraPreviewOptions extends CameraPreviewOptions {
    captureWidth?: number;
    captureHeight?: number;
}

@Component({
    selector: 'app-dp-camera',
    templateUrl: './dp-camera.component.html',
    styleUrls: ['./dp-camera.component.scss'],
})
export class DpCameraComponent implements OnInit, OnDestroy {
    cameraPreviewOptions: DpCameraPreviewOptions = {
        position: 'rear',
        // height: 1920,
        // width: 1080,
        enableZoom: true,
        disableExifHeaderStripping: false,
        toBack: true,
        storeToFile: false,
        disableAudio: true, // prevent audio permission requests
    };
    supportedFlashModes: CameraPreviewFlashMode[] = [];
    @Input() captureOpts: CameraPreviewPictureOptions = {
        quality: 85,
    };
    @Input() orientation: CameraOrientation = CameraOrientation.NONE;
    flashState: CameraPreviewFlashMode = 'off';
    processing = false;
    volBtnOpts: VolumeButtonsOptions = {};
    extraClass = '';

    // this could be handled in ngOnInit but this way is device independent
    private orientationHandler = (event: any) => {
        this.extraClass = '';
        const type = event.target.type;
        const angle = event.target.angle;
        console.log(`[DPCAM] ScreenOrientation change: ${type}, ${angle} degrees.`);

        this.orientationClasses(event.target.type as string);
        this.deviceCssFix();
    };

    private orientationClasses(orientation?: string) {
        // Devices are rotated different:
        // Android: 90° landscape-primary (nach links)
        // iOS: 270° landscape-secondary (nach rechts)
        if (orientation === 'landscape-primary') {
            this.extraClass += 'r-end';
        } else if (orientation === 'landscape-secondary') {
            this.extraClass += 'r-start';
        } else {
            // nothing
        }
    }

    private deviceCssFix() {
        if (this.platform.is('ios')) {
            this.extraClass += ' reverse';
        }

        if (!this.platform.is('ipad')) {
            this.extraClass += ' no-gap';
        } else {
            this.extraClass += ' ipad-safe-areas';
        }
    }

    useEditor = false;
    showEditor = false;
    editorSrc: any;
    origin: string;

    async toggleImageEditor() {
        this.useEditor = !this.useEditor;

        if (this.useEditor) {
            this.notifySrv.info('Der Foto-Editor öffnet sich nun automatisch nach jedem Foto', 'Automatischer Foto-Editor ein');
        } else {
            this.notifySrv.info('Der Foto-Editor wird nicht mehr automatisch geöffnet', 'Automatischer Foto-Editor aus');
        }

        try {
            await this.storage.set('dp_cam_editor_enabled', this.useEditor);
        } catch (storageEx) {
            console.warn('[DPCAM] failed remembering editor state', storageEx);
        }
    }

    constructor(
        private modalCtrl: ModalController,
        private platform: Platform,
        private storage: Storage,
        private notifySrv: NotificationService
    ) {
        screen.orientation.addEventListener('change', this.orientationHandler);
    }

    async ngOnInit(): Promise<void> {
        this.showEditor = false;
        this.cameraPreviewOptions.storeToFile = true;
        this.processing = true;

        if (this.platform.is('android')) {
            this.volBtnOpts.suppressVolumeIndicator = true;
        } else if (this.platform.is('ios')) {
            if (this.captureOpts.width == null && this.captureOpts.height == null) {
                // the user wants 'raw' from the camera so we enable the high resolution preset
                this.cameraPreviewOptions.enableHighResolution = true;
                // Android already does correct resolution scaling of the images
            } else {
                // iOS relies on the dimensions of the AVCaptureSession and its presets for image sizes
                // By passing these 2 params to the plugin it can set the session settings accordingly
                this.cameraPreviewOptions.captureWidth = this.captureOpts.width;
                this.cameraPreviewOptions.captureHeight = this.captureOpts.height;
            }

            this.volBtnOpts.disableSystemVolumeHandler = true;
        }

        if (this.orientation != CameraOrientation.NONE) {
            try {
                await ScreenOrientation.lock({ orientation: this.orientation.toString() as OrientationLockType });
                this.cameraPreviewOptions.lockAndroidOrientation = true;
                this.cameraPreviewOptions.rotateWhenOrientationChanged = false;
            } catch (lockErr) {
                console.warn('[DPCAM] Could not lock orientation', lockErr);
            }
        } else {
            // this is especially important for phones where we lock the orientation
            try {
                await ScreenOrientation.unlock();
                this.cameraPreviewOptions.lockAndroidOrientation = false;
                this.cameraPreviewOptions.rotateWhenOrientationChanged = true;
            } catch (unlockErr) {
                console.warn('[DPCAM] Unlock to allow rotation did not work', unlockErr);
            }
        }

        let deviceOrientation;
        try {
            deviceOrientation = await ScreenOrientation.orientation();
        } catch (orientationEx) {
            console.error('[DPCAM] error getting screen orientation. Ignoring it', orientationEx);
        }

        // Calc preview when specific image dimensions are requested for capture
        // Still, screen size != possible image size and rotated(width<->height) to boot so
        // we take the current screen space and requested size, downscale the requested image size to fit the screen
        // and position it accordingly
        console.log('[DPCAM] device width', [this.platform.width(), this.platform.height()]);
        console.log('[DPCAM] preview width', [this.captureOpts.width, this.captureOpts.height]);

        if (this.captureOpts.width != null && this.captureOpts.height != null) {
            // as we are not using the full screen except when requesting 'raw' images we need to place the preview accordingly
            let htmlElement = document.querySelector('html');
            let compSafeAreaTop = window.getComputedStyle(htmlElement).getPropertyValue('--ion-safe-area-top');
            let safeArea = Number.isNaN(compSafeAreaTop) ? 0 : parseInt(compSafeAreaTop);
            let scaleFactor = Math.min(
                this.platform.width() / this.captureOpts.height,
                (this.platform.height() - safeArea) / this.captureOpts.width
            );

            // Calculate the size of the preview based on current device orientation and center it on the screen
            if (deviceOrientation?.type != 'portrait-secondary' && deviceOrientation?.type != 'portrait-primary') {
                scaleFactor = Math.min(this.platform.width() / this.captureOpts.width, this.platform.height() / this.captureOpts.height);

                // a non-fraction number is IMPORTANT!
                this.cameraPreviewOptions.width = Math.floor(scaleFactor * this.captureOpts.width);
                this.cameraPreviewOptions.height = Math.floor(scaleFactor * this.captureOpts.height);

                this.cameraPreviewOptions.x = Math.floor((this.platform.height() - this.cameraPreviewOptions.height) / 2);
                this.cameraPreviewOptions.y = Math.floor((this.platform.width() - this.cameraPreviewOptions.width) / 2);
            } else {
                this.cameraPreviewOptions.width = Math.floor(scaleFactor * this.captureOpts.height);
                this.cameraPreviewOptions.height = Math.floor(scaleFactor * this.captureOpts.width);

                this.cameraPreviewOptions.x = Math.floor((this.platform.width() - this.cameraPreviewOptions.width) / 2);
                this.cameraPreviewOptions.y = Math.floor((this.platform.height() - this.cameraPreviewOptions.height) / 2);
            }

            // additional sizing and centering for locked orientations
            if (this.orientation != CameraOrientation.NONE && this.orientation != CameraOrientation.PORTRAIT) {
                if (!this.platform.is('ios')) {
                    // android is rotated to the left and updates its viewport bounds, so we need to update that
                    let compSafeAreaLeft = window.getComputedStyle(htmlElement).getPropertyValue('--ion-safe-area-left');
                    safeArea = Number.isNaN(compSafeAreaLeft) ? 0 : parseInt(compSafeAreaLeft);

                    this.cameraPreviewOptions.width = Math.floor(scaleFactor * this.captureOpts.width);
                    this.cameraPreviewOptions.height = Math.floor(scaleFactor * this.captureOpts.height);
                    this.cameraPreviewOptions.y = safeArea;
                }
            } else if (!this.platform.is('ipad')) {
                // move according to notch
                this.cameraPreviewOptions.y = safeArea;
            }

            // set rotation classes from the beginning
            this.orientationClasses(deviceOrientation?.type);

            // apply CSS fixups before rotating - needed for tablets which can be rotated free form
            this.deviceCssFix();
            if (this.platform.is('ios') || this.platform.is('ipad')) {
            }

            console.warn('[DPCAM] cacled preview width', [this.cameraPreviewOptions.width, this.cameraPreviewOptions.height]);
        }

        // support for running from web
        if (Capacitor.getPlatform() == 'web') {
            this.cameraPreviewOptions.parent = 'camera-preview-container';
        }

        await this.startCameraPreview();

        try {
            await this.storage.create();
            this.useEditor = !!(await this.storage.get('dp_cam_editor_enabled'));
        } catch (storageEx) {
            console.warn('[DPCAM] Loading storage failed');
        }

        document.body.classList.add('camera-preview-visible');
    }

    async ngOnDestroy() {
        try {
            await CameraPreview.stop();
        } catch (camStopEx) {
            console.error(camStopEx);
        }

        await this.stopWatchingVolBtns();

        screen.orientation.removeEventListener('change', this.orientationHandler);
        document.body.classList.remove('camera-preview-visible');
    }

    async cancelCamera(): Promise<void> {
        this.modalCtrl.dismiss();
    }

    async stopWatchingVolBtns() {
        if (this.platform.is('capacitor')) {
            try {
                if (await VolumeButtons.isWatching()) {
                    await VolumeButtons.clearWatch();
                }
            } catch (volEx) {
                console.error('[DPCAM] error on clearing volume watching', volEx);
            }
        }
    }

    async startCameraPreview() {
        try {
            await CameraPreview.start(this.cameraPreviewOptions);
            // allow users to start capturing images as torch can be set afterwards
            this.processing = false;
            let flashRes = await CameraPreview.getSupportedFlashModes();
            this.supportedFlashModes = flashRes.result;

            // Set the camera flash to auto when available
            if (this.supportedFlashModes.indexOf('auto') != -1) {
                this.flashState = 'auto';
                await CameraPreview.setFlashMode({ flashMode: this.flashState });
                console.log('[DPCAM] Set flash mode initially to auto');
            }

            // Enable taking pictures with volume button - NOTICE camera view needs to have focus in order for it to capture correctly e.g. tap on screen first
            if (this.platform.is('capacitor')) {
                await VolumeButtons.watchVolume(this.volBtnOpts, (result: VolumeButtonsResult, err?: any) => {
                    if (err != null) {
                        console.error('[DPCAM] error on volume watcher', err);
                    } else {
                        console.log('[DPCAM] triggering camera from volume button', result);
                        this.takePicture();
                    }
                });
            }
        } catch (camEx) {
            this.processing = false;
            console.error(camEx);
        }
    }

    async toggleTorch(): Promise<void> {
        let newState: CameraPreviewFlashMode = this.flashState;
        try {
            if (this.flashState == 'auto') {
                if (this.supportedFlashModes.indexOf('on') != -1) {
                    newState = 'on';
                } else {
                    newState = 'off';
                }
            } else if (this.flashState == 'on') {
                newState = 'off';
            } else if (this.flashState == 'off') {
                if (this.supportedFlashModes.indexOf('auto') != -1) {
                    newState = 'auto';
                } else if (this.supportedFlashModes.indexOf('on') != -1) {
                    newState = 'on';
                }
            }

            if (newState != this.flashState) {
                this.flashState = newState;
                console.log('[DPCAM] Setting flash mode to ', this.flashState);
                await CameraPreview.setFlashMode({ flashMode: this.flashState });
            }
        } catch (torchEx) {
            console.error(torchEx);
        }
    }

    async takePicture(): Promise<void> {
        this.processing = true;
        let cameraRes = await CameraPreview.capture(this.captureOpts);

        try {
            let uri = '';
            if (this.platform.is('android')) {
                // When using store to file we get a Capacitor file path
                // in the form of /data/user/0... which can't be used with getUri or we
                // get a double encoded path which does not work
                // Capacitor.convertFileSrc would be a possible way but is less efficient
                // than using file URLs and breaks on a lot of devices as its a device and os version dependent output it seems
                if (cameraRes.value.indexOf('file://') != 0 && this.cameraPreviewOptions.storeToFile) {
                    uri = `file://${cameraRes.value}`;
                } else {
                    uri = cameraRes.value;
                }
            } else {
                // iOS in hindsight seems to just work
                uri = cameraRes.value;
            }
            // keep a reference for later
            this.origin = uri;

            // now either complete the process or launch the editor
            if (!this.useEditor) {
                this.modalCtrl.dismiss({ uri: uri });
            } else {
                // Launch the image editor either from files (fastest method) or via base64 DataURI
                if (this.cameraPreviewOptions.storeToFile) {
                    this.launchEditorView(Capacitor.convertFileSrc(uri));
                } else {
                    this.launchEditorView('data:image/jpeg;base64,' + cameraRes.value);
                }
            }
        } catch (fileEx) {
            console.error('[DPCAM] failed saving file', fileEx);
        }
        this.processing = false;
    }

    async launchEditorView(data: string | Blob) {
        this.showEditor = true;
        this.editorSrc = data;

        // stop the image editor to prevent flashes of the led light or wrong event captures
        // CameraPreview.setFlashMode({flashMode: 'off'})
        await CameraPreview.stop();
        await this.stopWatchingVolBtns();
    }

    async handleEditorCancel() {
        console.log('[IMAGE EDITOR] editor cancelled');
        await this.startCameraPreview();
        this.showEditor = false;
    }

    async handleEditorProcess(data: DpImageEditorProcessData): Promise<void> {
        let uri = '';
        if (data.changed) {
            uri = await write_blob({
                path: Date.now() + '-edit.jpg',
                directory: Directory.Cache,
                blob: data.editorData.dest as Blob,
            });
        } else {
            if (!this.cameraPreviewOptions.storeToFile) {
                // we got some base64 rawdata, convert it to blob for better file storage first
                uri = await write_blob({
                    path: Date.now() + '-og.jpg',
                    directory: Directory.Cache,
                    blob: await (await fetch('data:image/jpeg;base64,' + this.origin)).blob(),
                });
            } else {
                uri = this.origin;
            }
        }

        await this.modalCtrl.dismiss({ uri: uri });
    }
}
