import { debounceTime, retry } from 'rxjs/operators';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { LoggerService } from '../core/logger/logger.service';
import { Router } from '@angular/router';
import AWS from 'aws-sdk';

import { BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import packageJson from './../../../package.json';
import { NotificationService } from '../core/notification/notification.service';
import { Storage } from '@ionic/storage-angular';
import { DbService } from '../core/db/db.service';
import { AttachmentService } from '../core/attachment/attachment.service';
import { HttpClient } from '@angular/common/http';
import { User } from '../core/shared-models/user.model';
import { Company } from '../core/shared-models/company.model';
import { AuthInfo } from './auth-info.interface';
import { UtilsService } from '../core/utils/utils.service';

import * as Sentry from '@sentry/capacitor';
import { LicenseTypes } from './license-types.enum';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
// import { WebSocketSubject } from 'rxjs/internal-compatibility';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { LicenseType, UserRights } from '../core/shared-models/license-type.model';
import { productFruits } from 'product-fruits';
import { MsalService } from '@azure/msal-angular';
import { SessionRefreshAnswer } from './session-refresh-answer.interface';
import { ExternalPreAuthAnswer } from './external-pre-auth-answer.interface';
import { RedirectRequest } from '@azure/msal-browser';
import { Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';

export enum UserIdleStatus {
    ACTIVE = 'active',
    SHORT_IDLE = 'short_idle',
    LONG_IDLE = 'long_idle',
}

interface DpDeviceInfo extends DeviceInfo {
    uuid: string;
}

const DEFAULT_WEBSOCKET_CONNECTION_RETRY_TIME = 1500;
const AUTH_ENDPOINT_URL_PATH = '/auth';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    // login status handling
    public isLoggedIn = false;
    public loginStatusChanged$ = new BehaviorSubject<boolean | string>(false);

    // app ready subscribeable (will be emitted as soon as all services (db, filesystem, etc) are initialized
    public appReady$ = new BehaviorSubject<boolean>(false);

    // auth info (contains user and company data)
    public authInfo: AuthInfo;
    public authInfoChanged$: BehaviorSubject<AuthInfo>;
    public resellerId: string = null;

    // bindable properties to get current license type
    public currentLicenseType: string;
    public currentLicenseTypeModel: LicenseType;
    public isAdminLicense: boolean;
    public isMobileLicense: boolean;
    public isViewerLicense: boolean;
    public photoDocumentationOnly: boolean; // true if this is a photo-documentation only company (limited)

    // aws credentials changed (used to re-init sdk clients (e.g. s3 or dynamodb) so that they use the lastest credentials
    public awsCredentialsChanged = new EventEmitter();

    // current tokens
    public lastUsedAccessToken: string;
    public lastUsedIdToken: string;
    public lastUsedExternalIdpIDToken: string;
    public lastUsedRefreshToken: string;

    public deviceLocked = false;
    public blockSessionRefresh = false; // if set to true session will not be renewed on next auth

    // web socket connection
    private webSocketConnection: WebSocketSubject<any>;
    public webSocketMessageReceived$ = new EventEmitter();
    private webSocketConnectionRetryTime = DEFAULT_WEBSOCKET_CONNECTION_RETRY_TIME;
    private currentWebSocketConnectionStatus = false;
    public webSocketConnectionStatusChanged$ = new BehaviorSubject<boolean>(this.currentWebSocketConnectionStatus);

    // helper variables
    private cognitoISP;
    private currentCognitoUserName;
    private currentCognitoUserSession;
    private networkSub: Subscription;
    private sessionRefreshIntervalId = null; // the interval id to refresh the session every few minutes
    private quotaErrCnt = 0; // keep track of disc quota errors to step out of recursive functions

    // variables for idle checking
    private idleCheckIntervalId = null;
    private lastActionTimestamp = null;
    public userIdleStatus: UserIdleStatus = UserIdleStatus.ACTIVE;
    public userIdleStatusChanged = new BehaviorSubject<UserIdleStatus>(UserIdleStatus.ACTIVE);

    constructor(
        private router: Router,
        private storage: Storage,
        private db: DbService,
        private attSrv: AttachmentService,
        private http: HttpClient,
        private logger: LoggerService,
        private utils: UtilsService,
        private notify: NotificationService,
        private platform: Platform,
        private msalSrv: MsalService
    ) {
        this.initStorage();

        this.cognitoISP = new AWS.CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: environment.AWS_REGION,
            maxRetries: 8,
            retryDelayOptions: {
                base: 300,
            },
            credentials: {
                accessKeyId: 'anything',
                secretAccessKey: 'anything',
            },
        });
        this.authInfoChanged$ = new BehaviorSubject<AuthInfo>(this.utils.clone(this.authInfo));

        // handle app resumes like transitions from standby or background
        this.platform.resume.subscribe(() => {
            this.handleAppResume();
        });

        this.networkSub = this.utils.onlineChanged.pipe(debounceTime(4000)).subscribe(async (nowConnected) => {
            if (nowConnected && this.isLoggedIn) {
                this.logger.log('[AUTH] Network reconnected. will refresh session and connect websocket');
                await this.startLoginWithTokens();
                this.initWebsocketApiConnection();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.networkSub) {
            this.networkSub.unsubscribe();
        }
    }

    async initStorage(): Promise<void> {
        // If using a custom driver:
        // await this.storage.defineDriver(MyCustomDriver)
        await this.storage.create();
    }

    public async startNewLogin(username: string, password: string): Promise<void> {
        this.logger.log('[AUTH] starting login with name and pwd');

        try {
            this.currentCognitoUserName = username;
            let authResponse = await this.cognitoISP
                .initiateAuth({
                    AuthFlow: 'USER_PASSWORD_AUTH',
                    AuthParameters: {
                        USERNAME: username,
                        PASSWORD: password,
                    },
                    ClientId: environment.AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
                })
                .promise();
            let proceedLogin = await this.handleCognitoResponse(authResponse);
            if (proceedLogin) {
                try {
                    await this.finishLogin();
                    await this.storage.set('dp_auth_sso_idp', null);
                    // login successful, redirect to dashboard and replace nav stack as we don't want users to be able to get back to the auth screen afterwards (ex. back button)
                    await this.router.navigate(['/'], { replaceUrl: true });
                } catch (loginErr) {
                    await this.handleLoginError(loginErr);
                }
            }
        } catch (cognitoErr) {
            await this.handleCognitoError(cognitoErr);
        }
    }

    public async startLoginWithTokens(forceDevice = false) {
        let tokens = await this.getSavedCognitoTokens();
        if (tokens.refreshToken == null) {
            this.logger.log('[AUTH] refresh token does not exist', tokens);
            throw 'no refresh token';
        }

        if (this.blockSessionRefresh) {
            this.logger.log('[AUTH] will not refresh session - session refresh blocked');
            return;
        }

        this.logger.log('[AUTH] starting login with tokens - device ' + this.utils.isOnline ? 'online' : 'offline');

        // if sso is active we need to refresh our external idp token
        try {
            let idp = await this.storage.get('dp_auth_sso_idp');
            if (this.utils.isOnline && idp === 'microsoft') {
                let externalIdpTokenRefresh = await lastValueFrom(this.msalSrv.acquireTokenSilent({ scopes: ['user.read'] }));
                this.logger.log('[AUTH] refreshed external idp token', externalIdpTokenRefresh);
            }
        } catch (externalIdpTokenErr) {
            this.logger.error('[AUTH] could not refresh external idp token', externalIdpTokenErr);
            this.notify.error('Login-Daten von Microsoft konnten nicht abgerufen werden', 'Microsoft SSO Fehler');
            this.stopLogin();
            throw 'external idp token error';
        }

        try {
            if (this.utils.isOnline) {
                // try to refresh cognito login
                let authResponse = await this.cognitoISP
                    .initiateAuth({
                        AuthFlow: 'REFRESH_TOKEN_AUTH',
                        AuthParameters: {
                            REFRESH_TOKEN: tokens.refreshToken,
                        },
                        ClientId: environment.AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
                    })
                    .promise();
                let proceedLogin = await this.handleCognitoResponse(authResponse);
                if (!proceedLogin) {
                    this.logger.error('[AUTH] something went wrong during cognito token login', authResponse);
                    this.stopLogin();
                    throw false;
                }
            } else {
                this.logger.log('[AUTH] starting login with tokens - device offline, skipping cognito token refresh');
            }
            try {
                await this.finishLogin(forceDevice);
                // login successful
            } catch (loginErr) {
                await this.handleLoginError(loginErr);
            }
        } catch (cognitoErr) {
            await this.handleCognitoError(cognitoErr);
        }
    }

    public async startNewLoginWithExternalIdp(idp: 'microsoft') {
        try {
            if (idp === 'microsoft') {
                console.warn('[AUTH PAGE] starting login with microsoft');

                // show microsoft login popup
                if (this.utils.isNative) {
                    let msalRedirectRequest: RedirectRequest = {
                        scopes: ['user.read', 'openid', 'profile'],
                        prompt: 'select_account',
                    };
                    msalRedirectRequest.redirectUri = 'https://app.dokupit.com/auth';

                    let msalRes = this.msalSrv.loginRedirect(msalRedirectRequest);
                    // the navigation-redirect-handler will call the continueLoginWithExternalIdp() function after successful redirect auth
                } else {
                    // in browser, we can just use the loginPopup method
                    let msalRes = await lastValueFrom(
                        this.msalSrv.loginPopup({
                            scopes: ['user.read', 'openid', 'profile'],
                            prompt: 'select_account',
                        })
                    );
                    this.continueLoginWithExternalIdp('microsoft', msalRes);
                }
            }
        } catch (externalIdpLoginErr) {
            this.handleLoginError(externalIdpLoginErr);
        }
    }

    /**
     * Logins in a support user by given refresh Token
     */
    public async startSupportUserLogin(refreshToken: string) {
        // first we need to check if we were previously logged in, then we need to log out
        let existingTokens = await this.getSavedCognitoTokens();
        if (
            existingTokens.idToken != null ||
            existingTokens.accessToken != null ||
            existingTokens.refreshToken != null ||
            existingTokens.userName != null
        ) {
            // log out user and reload page for new login
            this.notify.info('DokuPit wird gerade mit einem anderen Login verwendet, Login wird gewechselt ...');
            try {
                // we need to init our db so we can destroy it during logout, we expect the "reset needed" error, so we catch it and logout afterwards
                await this.db.init(this.utils.generateUuid(), this.utils.generateUuid());
            } catch (dbInitErr) {
                if (dbInitErr !== 'reset needed') {
                    throw dbInitErr;
                }
            }
            // we init aws credentials so we can clean them up, otherwise they would be undefined during logout - not a safety problem, just to prevent an undefined error
            try {
                AWS.config.credentials = new AWS.CognitoIdentityCredentials(
                    {
                        IdentityPoolId: environment.AWS_COGNITO_IDENTITY_POOL_ID,
                    },
                    {
                        region: 'eu-central-1',
                        signatureCache: false,
                    }
                );
                (AWS.config.credentials as any).clearCachedId();
            } catch (awsCredsleanErr) {
                this.logger.error('[AUTH] could not clean aws credentials during support login', awsCredsleanErr);
            }
            this.logout(true, true);
            return;
        }
        // app is in clean state so we can login support user now - first save refresh token in local storage
        // save refreshToken in local storage
        await this.storage.set('dp_auth_refreshtoken', refreshToken);
        await this.startLoginWithTokens(true);
        await this.router.navigate(['/'], { replaceUrl: true });
    }

    /**
     * This is triggered from deeplink navigation if we are navigated to our app with a auth #code in the url
     * @param authCode
     */
    public handleNativeMsalAuthRedirectResult(authCode) {
        this.msalSrv.instance
            .handleRedirectPromise(authCode)
            .then((res) => {
                this.logger.log('[AUTH] native msal redirect auth completed', res);
                this.continueLoginWithExternalIdp('microsoft', res);
            })
            .catch((err) => {
                this.logger.error('[AUTH] native msal redirect auth failed', err);
            });
    }

    public async continueLoginWithExternalIdp(idp: 'microsoft', idpRes) {
        try {
            if (idp === 'microsoft') {
                console.warn('[AUTH] continuing login with microsoft');

                this.msalSrv.instance.setActiveAccount(idpRes.account);
                this.lastUsedExternalIdpIDToken = idpRes.idToken; // the id token is used to authenticate with cognito afterwards

                // with the id token we now aquire an additional accessToken to query the microsoft graph api (used to get app role and company-branch -> filialzuweisung)
                let aquireToken = await lastValueFrom(this.msalSrv.acquireTokenSilent({ scopes: ['user.read'] }));
                // TODO QUERY MICROSOFT API

                // call our api and handle pre-auth checks for this user, this checks if sso is actually enabled for this users company (microsoft tenant) and auto creates users if they do not exist in dokupit cognito
                let payload = {
                    idp: idp,
                    idt: this.lastUsedExternalIdpIDToken,
                };
                let preAuthResponse = await lastValueFrom(
                    this.http.post<ExternalPreAuthAnswer>(environment.AWS_API_URL + '/authenticate/external-pre-auth', payload)
                );
                if (preAuthResponse.errorType != null || preAuthResponse.errorMessage != null) {
                    throw preAuthResponse;
                }

                this.currentCognitoUserName = preAuthResponse.dokupitUsername;

                // now start CUSTOM auth with cognito
                let cognitoRes = await this.cognitoISP
                    .initiateAuth({
                        AuthFlow: 'CUSTOM_AUTH',
                        AuthParameters: {
                            USERNAME: preAuthResponse.dokupitUsername,
                        },
                        ClientId: environment.AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
                    })
                    .promise();
                let proceedLogin = await this.handleCognitoResponse(cognitoRes);
                if (!proceedLogin) {
                    this.logger.error('[AUTH] something went wrong during cognito token login', cognitoRes);
                    this.stopLogin();
                    throw false;
                }
                // login successful
                await this.finishLogin(true);
                await this.storage.set('dp_auth_sso_idp', idp);
                this.router.navigate(['/'], { replaceUrl: true });
            }
        } catch (externalIdpLoginErr) {
            this.handleLoginError(externalIdpLoginErr);
        }
    }

    private async handleCognitoResponse(cognitoResponse) {
        if (cognitoResponse.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
            this.logger.log('[AUTH] new password required');
            this.currentCognitoUserSession = cognitoResponse.Session;
            this.isLoggedIn = false;
            this.loginStatusChanged$.next('passwordchange');
            return false;
        } else if (cognitoResponse.ChallengeName === 'SMS_MFA') {
            this.logger.log('[AUTH] SMS MFA required', cognitoResponse, (<any>cognitoResponse).ChallengeParameters);
            this.currentCognitoUserSession = cognitoResponse.Session;
            this.isLoggedIn = false;
            this.loginStatusChanged$.next('smsmfa');
            return false;
        } else if (cognitoResponse.ChallengeName === 'CUSTOM_CHALLENGE' && cognitoResponse.ChallengeParameters.idp === 'microsoft') {
            this.currentCognitoUserSession = cognitoResponse.Session;
            let challengeRes = await this.cognitoISP
                .respondToAuthChallenge({
                    ChallengeName: 'CUSTOM_CHALLENGE',
                    ChallengeResponses: {
                        USERNAME: this.currentCognitoUserName,
                        ANSWER: this.lastUsedExternalIdpIDToken,
                    },
                    Session: this.currentCognitoUserSession,
                    ClientId: environment.AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
                })
                .promise();
            return await this.handleCognitoResponse(challengeRes);
        } else if (cognitoResponse.AuthenticationResult != null) {
            // successful login
            this.logger.log('[AUTH] cognito login successful');
            await this.saveCognitoTokens(cognitoResponse);
            return true;
        } else {
            this.logger.error('[AUTH] unhandled cognito response', cognitoResponse);
            // unhandled cognito response
            throw cognitoResponse;
        }
    }

    /**
     * Finishes the login process, loads session data and prepares the app for use (only called after a successful authentication)
     */
    private async finishLogin(forceDevice = false) {
        // load session data
        if (this.utils.isOnline) {
            this.logger.log('[AUTH] loading session data - online refresh');
            let deviceInfo = (await this.getDeviceInfo()) as DpDeviceInfo;
            let deviceId = await this.getDeviceId();
            deviceInfo.uuid = deviceId.identifier;
            let payload = {
                idt: this.lastUsedIdToken,
                deviceInfo: deviceInfo,
                version: packageJson.version,
                forceDevice: forceDevice,
            };
            let sessionResponse = await lastValueFrom(
                this.http
                    .post<SessionRefreshAnswer>(environment.AWS_API_URL + '/authenticate/session', payload)
                    .pipe(retry({ count: 8, delay: 2000 }))
            );
            if (sessionResponse.errorType != null || sessionResponse.errorMessage != null) {
                throw sessionResponse;
            }
            // TODO we should check if there are cases in live environment, where the http request gets send as the device is online, but the request fails (going offline while waiting for answer, server error, etc). Currently the user will get logged out as this error is not catched here. maybe we should check if offline session data is available and use this data in the meantime

            // check if the current user is a support user and spoofing another user, then we override our current username with the spoof username
            if (
                sessionResponse.AuthInfo.userData.dokupitSupportAccount === true &&
                sessionResponse.AuthInfo.userData.dokupitSupportSpoofUsername != null
            ) {
                sessionResponse.AuthInfo.userData.userName = sessionResponse.AuthInfo.userData.dokupitSupportSpoofUsername;
            }

            if (sessionResponse.allowDevice === false) {
                this.setDeviceLock(true);
            }

            this.authInfo = {
                userData: new User(sessionResponse.AuthInfo.userData),
                companyData: new Company(sessionResponse.AuthInfo.companyData),
                companyUsers: sessionResponse.AuthInfo.companyUsers.map((x) => new User(x)),
            };
            let saved = await this.storage.set('dp_auth_userdata', sessionResponse.AuthInfo); // save locally for offline use
        } else {
            this.logger.log('[AUTH] loading session data - offline refresh');
            // get local saved auth info
            let data = await this.storage.get('dp_auth_userdata');
            if (data.userData == null || data.companyData == null || data.companyData.pk == null || data.companyUsers.length <= 0) {
                throw {
                    msg: 'local userdata corrupt',
                    userData: data,
                };
            }
            this.authInfo = {
                userData: new User(data.userData),
                companyData: new Company(data.companyData),
                companyUsers: data.companyUsers.map((x) => new User(x)),
            };
        }

        this.currentLicenseType = this.authInfo.userData.licenseType;
        this.currentLicenseTypeModel = this.authInfo.companyData.availableLicenseTypes.find((x) => x.name === this.currentLicenseType);

        // check default license types
        this.isAdminLicense = this.authInfo.userData.licenseType === LicenseTypes.ADMIN;
        this.isMobileLicense = this.authInfo.userData.licenseType === LicenseTypes.MOBILE;
        this.isViewerLicense = this.authInfo.userData.licenseType === LicenseTypes.VIEWER;

        this.photoDocumentationOnly = this.authInfo.companyData.billingType.indexOf('fotodocumentation') !== -1;

        // check custom license types
        if (this.currentLicenseTypeModel != null) {
            this.isMobileLicense = this.currentLicenseTypeModel.viewer;
            this.isViewerLicense = this.currentLicenseTypeModel.viewer;
        }

        // set reseller info in app
        if (this.authInfo.companyData.reseller?.id != null) {
            document.body.classList.add('reseller-' + this.authInfo.companyData.reseller.id);
            this.resellerId = this.authInfo.companyData.reseller.id;
        }

        this.authInfoChanged$.next(this.utils.clone(this.authInfo));

        // update credentials for aws sdk
        let logins = {};
        logins['cognito-idp.eu-central-1.amazonaws.com/' + environment.AWS_COGNITO_USER_POOL_ID] = this.lastUsedIdToken;

        AWS.config.region = 'eu-central-1';
        AWS.config.correctClockSkew = true; // Let AWS SDK fix time deviations due to misconfigurations on system
        AWS.config.signatureCache = false;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials(
            {
                IdentityPoolId: environment.AWS_COGNITO_IDENTITY_POOL_ID,
                Logins: logins,
            },
            {
                region: 'eu-central-1',
                signatureCache: false,
            }
        );

        try {
            await new Promise((resolve, reject) => {
                if (this.utils.isOnline) {
                    (AWS.config.credentials as any).refresh((err) => {
                        if (err) {
                            reject(err);
                        } else {
                            this.logger.log('[AUTH] aws credentials refreshed');
                            this.awsCredentialsChanged.emit(null);
                            this.db.reInitDynamoDb();
                            resolve(undefined);
                        }
                    });
                } else {
                    this.logger.log('[AUTH] could not refresh aws credentials - device is offline');
                    resolve(undefined);
                }
            });
        } catch (awsCredsErr) {
            this.logger.error('[AUTH] could not refresh aws credentials', awsCredsErr);
        }

        // init everything (only once, not on recurring session refreshes)
        if (!this.isLoggedIn) {
            // init file system
            this.logger.log('[AUTH] initializing file system');
            await this.attSrv.initDirectories();
            this.logger.log('[AUTH] file system ready');

            // init db
            this.logger.log('[AUTH] initializing db');
            await this.db.init(this.authInfo.userData.userName, this.authInfo.companyData._id);
            this.logger.log('[AUTH] db ready');

            // update login status
            this.logger.log('[AUTH] login process concluded');
            this.isLoggedIn = true;
            this.loginStatusChanged$.next(this.isLoggedIn);
            this.appReady$.next(true);

            // set interval to refresh session every 3 minutes
            if (this.sessionRefreshIntervalId == null) {
                this.logger.log('[AUTH] setting interval for recurring session refresh');
                this.sessionRefreshIntervalId = setInterval(() => {
                    this.startLoginWithTokens().catch(() => {
                        // silence any bubbling error
                    });
                }, 180000);
            }

            this.initIdleCheck();

            this.initWebsocketApiConnection();

            // init productfruits onboarding
            try {
                let userRights: UserRights = null; // we want to check user rights to init product fruits accordingly
                let currentUserLicenseType = this.authInfo.companyData.availableLicenseTypes.find(
                    (x) => x.name === this.authInfo.userData.licenseType
                );
                if (currentUserLicenseType != null) {
                    userRights = currentUserLicenseType.rights;
                }
                productFruits.init(
                    '2xe7Rkw1mINBJmxf',
                    'de',
                    {
                        username: this.authInfo.userData.userName,
                        firstname: this.authInfo.userData.firstName,
                        lastname: this.authInfo.userData.lastName,
                        role: this.authInfo.userData.licenseType,
                        props: {
                            licenseType: this.authInfo.userData.licenseType,
                            companyName: this.authInfo.companyData.name,
                            companyId: this.authInfo.companyData._id,
                            environment: environment.production === true ? 'prod' : 'dev',
                            dokuPitInternal: this.authInfo.companyData.isDokuPitIntern as any, // any casting used to ignore wrong type definition of library, this is actually a boolean
                            testing: this.authInfo.companyData.billingType?.split('|').includes('testing'),
                            canManageProjects: userRights?.canManageProjects,
                        },
                    },
                    {
                        customNavigation: {
                            use: true,
                            navigate: (url) => {
                                // this function will be called by pf when it needs to navigate somewewhere
                                this.router.navigateByUrl(url);
                            },
                            onGet() {
                                // this callback will be called when pf needs to read the current url
                                console.log('pf reading current url', window.location, window.location.href);
                                return window.location.href;
                            },
                        },
                    }
                );
                this.logger.log('[AUTH] initialized productfruits onboarding');
            } catch (productFruitsInitErr) {
                this.logger.error('[AUTH] could not init productfruits', productFruitsInitErr);
            }
        }

        // Add data to Sentry
        Sentry.setTags({
            'company.name': this.authInfo.companyData.name,
            'company.id': this.authInfo.companyData._id,
            'user.name': this.authInfo.userData.userName,
        });
        Sentry.setUser({
            id: this.authInfo.userData._id,
            email: this.authInfo.userData.email,
            ip_address: '{{auto}}',
        });

        // save timestamp of last successful login
        try {
            await this.storage.set('dp_auth_last_login_timestamp', Date.now());
        } catch (loginTimestampErr) {
            this.logger.error('[AUTH] could not save last login timestamp', loginTimestampErr);
            this.utils.sentryCaptureException(loginTimestampErr);
        }

        this.logger.log('[AUTH] login finished');
    }

    private async saveCognitoTokens(cognitoResponse) {
        try {
            this.logger.log('[AUTH] saving cognito tokens');
            const infosToSave: Array<{ storageKey: string; data: any }> = [];
            if (cognitoResponse.AuthenticationResult.AccessToken) {
                this.lastUsedAccessToken = cognitoResponse.AuthenticationResult.AccessToken;
                infosToSave.push({
                    storageKey: 'dp_auth_accesstoken',
                    data: cognitoResponse.AuthenticationResult.AccessToken,
                });
            }
            if (cognitoResponse.AuthenticationResult.IdToken) {
                this.lastUsedIdToken = cognitoResponse.AuthenticationResult.IdToken;
                infosToSave.push({
                    storageKey: 'dp_auth_idtoken',
                    data: cognitoResponse.AuthenticationResult.IdToken,
                });
            }
            if (cognitoResponse.AuthenticationResult.RefreshToken) {
                this.lastUsedRefreshToken = cognitoResponse.AuthenticationResult.RefreshToken;
                infosToSave.push({
                    storageKey: 'dp_auth_refreshtoken',
                    data: cognitoResponse.AuthenticationResult.RefreshToken,
                });
            }

            // save current username
            infosToSave.push({
                storageKey: 'dp_auth_username',
                data: this.currentCognitoUserName,
            });

            // execute promises - save auth data in local storage
            let authInfoSaved = await Promise.all(
                infosToSave.map((info) => {
                    return this.storage.set(info.storageKey, info.data);
                })
            );

            this.quotaErrCnt = 0;
            return authInfoSaved;
        } catch (tokenSaveErr) {
            this.logger.error('[AUTH] tokens could not be saved', [tokenSaveErr, tokenSaveErr?.code]);
            if (tokenSaveErr?.code == DOMException.QUOTA_EXCEEDED_ERR) {
                // try to clear the attachment cache and do the last step again
                if (Capacitor.getPlatform() == 'web' && this.quotaErrCnt == 0) {
                    this.quotaErrCnt += 1;
                    try {
                        this.logger.warn('[AUTH] try to clear attachment cache and redo last step');
                        await this.attSrv.cleanFolder(environment.DISK_FOLDER_ATTACHMENTS, Directory.Data);
                        return await this.saveCognitoTokens(cognitoResponse);
                    } catch (failGuardEx) {
                        this.utils.sentryCaptureException(failGuardEx);
                    }
                }

                this.quotaErrCnt = 0;
                this.notify.error(
                    'Es ist kein freier Speicher mehr verfügbar und die Funktion von DokuPit kann daher nicht sichergestellt werden. Bitte gib auf deinem Gerät Speicher frei und versuche es erneut.',
                    'Speicher voll',
                    'QUOTA_EXCEEDED_ERR',
                    false,
                    30000
                );
                this.utils.sentryCaptureException(tokenSaveErr);

                this.stopLogin();
            } else {
                this.notify.error('Bitte versuche es erneut', 'Login fehlgeschlagen', 'TOKEN_ERR');
                this.utils.sentryCaptureException(tokenSaveErr);
                this.stopLogin(true);
            }
        }
    }

    /**
     * Gets saved tokens from local storage. Returns a promise with all tokens and username
     */
    async getSavedCognitoTokens() {
        let data = await Promise.all([
            this.storage.get('dp_auth_accesstoken'),
            this.storage.get('dp_auth_idtoken'),
            this.storage.get('dp_auth_refreshtoken'),
            this.storage.get('dp_auth_username'),
        ]);
        this.lastUsedAccessToken = data[0];
        this.lastUsedIdToken = data[1];
        this.lastUsedRefreshToken = data[2];
        this.currentCognitoUserName = data[3];
        return {
            accessToken: data[0],
            idToken: data[1],
            refreshToken: data[2],
            userName: data[3],
        };
    }

    public async respondToCognitoNewPasswordChallenge(newPassword: string) {
        try {
            let authResponse = await this.cognitoISP
                .respondToAuthChallenge({
                    ChallengeName: 'NEW_PASSWORD_REQUIRED',
                    ChallengeResponses: {
                        USERNAME: this.currentCognitoUserName,
                        NEW_PASSWORD: newPassword,
                    },
                    Session: this.currentCognitoUserSession,
                    ClientId: environment.AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
                })
                .promise();
            let proceed = await this.handleCognitoResponse(authResponse);
            if (!proceed) {
                throw authResponse;
            } else {
                try {
                    await this.finishLogin();
                    // login successful, redirect to dashboard and replace nav stack as we don't want users to be able to get back to the auth screen afterwards (ex. back button)
                    this.router.navigate(['/'], { replaceUrl: true });
                } catch (loginErr) {
                    await this.handleLoginError(loginErr);
                }
            }
        } catch (challengeErr) {
            switch (challengeErr.message) {
                case 'Password does not conform to policy: Password not long enough':
                case 'Password does not conform to policy: Password must have numeric characters':
                case 'Password does not conform to policy: Password must have lowercase characters': {
                    this.notify.error('Das eingegebene Passwort entspricht nicht den Passwortrichtlinien');
                    this.loginStatusChanged$.next('passwordchange');
                    break;
                }
                default: {
                    await this.handleCognitoError(challengeErr);
                    break;
                }
            }
        }
    }

    public async respondToCognitoSmsMFAChallenge(smsCode: string) {
        try {
            let authResponse = await this.cognitoISP
                .respondToAuthChallenge({
                    ChallengeName: 'SMS_MFA',
                    ChallengeResponses: {
                        USERNAME: this.currentCognitoUserName,
                        SMS_MFA_CODE: smsCode,
                    },
                    Session: this.currentCognitoUserSession,
                    ClientId: environment.AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
                })
                .promise();
            let proceed = await this.handleCognitoResponse(authResponse);
            if (!proceed) {
                throw authResponse;
            } else {
                try {
                    await this.finishLogin();
                    // login successful, redirect to dashboard and replace nav stack as we don't want users to be able to get back to the auth screen afterwards (ex. back button)
                    this.router.navigate(['/'], { replaceUrl: true });
                } catch (loginErr) {
                    await this.handleLoginError(loginErr);
                }
            }
        } catch (challengeErr) {
            await this.handleCognitoError(challengeErr);
        }
    }

    private async handleCognitoError(error) {
        this.logger.error('[AUTH] cognito error', error);
        switch (error.message) {
            case 'Incorrect username or password.':
            case 'User does not exist.': {
                this.notify.error('Benutzername oder Passwort falsch');
                this.stopLogin();
                break;
            }
            case 'Refresh Token has been revoked':
            case 'The user has been deleted for the associated refresh token': {
                this.notify.error('Benutzer wurde gesperrt');
                this.stopLogin();
                break;
            }
            case 'PreTokenGeneration failed with error test expired.': {
                this.notify.error('Dein Testzugang ist abgelaufen. Bitte kontaktiere uns für weitere Schritte');
                this.stopLogin();
                break;
            }
            case 'PreTokenGeneration failed with error license expired.': {
                this.notify.error('Diese Lizenz ist abgelaufen.');
                this.stopLogin();
                break;
            }
            default: {
                if (this.isLoggedIn) {
                    // something went wrong during login, but the user is already loggedin, so we ignore this error - maybe the connection is unstable and the next try will work
                    this.logger.log(
                        '[AUTH] unhandled cognito error occured, will ignore this because the user was logged in before - maybe unstable connection'
                    );
                } else {
                    if (!this.utils.isOnline && this.currentCognitoUserName != null) {
                        this.logger.log(
                            '[AUTH] unhandled cognito error occured, device went offline during login, will ignore this because the user was logged in before - maybe unstable connection'
                        );
                    } else {
                        this.notify.error('Beim Login ist ein Fehler aufgetreten. Versuche es erneut.');
                        this.stopLogin();
                        throw error;
                    }
                }
                break;
            }
        }
    }

    private async handleLoginError(error) {
        if (error === 'reset needed') {
            // this error comes from the db initialization, which fails if the current-logging-in user doesnt match the last logged in user
            this.logger.warn('[AUTH] reset is needed - triggering logout in 5 seconds');
            this.notify.info(
                'DokuPit wurde zuvor mit einem anderen Login verwendet. DokuPit führt nun eine Datenbereinigung aus und du kannst dich danach mit dem gewünschten Benutzer einloggen',
                'Datenbereinigung erforderlich',
                false,
                5000
            );
            setTimeout(() => {
                this.logout();
            }, 5000);
        } else {
            if (error.error?.errorMessage === 'concurrent admin session limit reached') {
                this.logger.log('[AUTH] concurrent admin session limit reached', error);
                this.notify.error(
                    'Die maximale Anzahl an gleichzeitigen Admin-Benutzern wurde erreicht. Bitte probier es in wenigen Minuten noch einmal'
                );
                this.stopLogin();
            } else if (error.errorMessage === 'sso not active for company') {
                this.notify.error(
                    'Für diesen Microsoft-Benutzer ist SSO nicht aktiv. Kontaktiere uns, um SSO in deinem Betrieb zu implementieren.',
                    'SSO nicht aktiv'
                );
                this.stopLogin(false);
            } else if (error.errorMessage === 'company does not allow sso new user creation') {
                this.notify.error(
                    'Für diesen Microsoft-Benutzer ist keine DokuPit-Lizenz aktiv. Kontaktiere deinen Admin, um eine DokuPit-Lizenz für dich freizuschalten.',
                    'SSO - Keine Lizenz für diesen Benutzer'
                );
                this.stopLogin(false);
            } else if (error.message === 'PreTokenGeneration failed with error test expired.') {
                this.notify.error('Dein Testzugang ist abgelaufen. Bitte kontaktiere uns für weitere Schritte');
                this.stopLogin();
            } else if (error.message === 'PreTokenGeneration failed with error license expired.') {
                this.notify.error('Diese Lizenz ist abgelaufen');
                this.stopLogin();
            } else if (error.status == 0 && this.isLoggedIn) {
                // login error with status 0 (connection issue) occured, check timestamp of last successful login
                let lastLoginTimestamp = 0;
                try {
                    lastLoginTimestamp = await this.storage.get('dp_auth_last_login_timestamp');
                    if (isNaN(lastLoginTimestamp)) {
                        lastLoginTimestamp = 0;
                    }
                } catch (loginTimestampCheckErr) {
                    this.logger.error('[AUTH] could not check timestamp of last successful login', loginTimestampCheckErr);
                    this.utils.sentryCaptureException(loginTimestampCheckErr);
                }
                if (lastLoginTimestamp > Date.now() - 604800000) {
                    // last successful login was in the last week, so we will grafecully keep the user logged in
                    this.logger.error(
                        '[AUTH] login error with status 0 (connection issue) occured, but user was logged in previously, will stay logged in',
                        error
                    );
                } else {
                    // last login timestamp is older than 1 week, we will logout
                    this.logger.error(
                        '[AUTH] login error with status 0 (connection issue) occured, and last successful login is too long ago, will log out',
                        error
                    );
                    this.utils.sentryCaptureException(error, 'warning');
                    this.notify.error(
                        'Beim Login ist ein Netzwerkfehler aufgetreten. Dein letzter erfolgreicher Login ist zu lange her. Bitte melde dich erneut an.',
                        'Login fehlgeschlagen',
                        '',
                        false,
                        2000
                    );
                    this.stopLogin(true);
                }
            } else {
                this.logger.error('[AUTH] unrecoverable login error', error);
                this.utils.sentryCaptureException(error, 'error');
                this.notify.error(
                    'Beim Login ist ein unvorhergesehener Fehler aufgetreten. Probiere es erneut oder kontaktiere den DokuPit Support',
                    'Login fehlgeschlagen',
                    '',
                    false,
                    2000
                );
                this.stopLogin(true);
            }
        }
    }

    /**
     * Stops the login process due to errors
     * @param reloadApp If true the app will reload after stopping the login. Used for cleaning up the current session and starting with a fresh view
     * @param skipNavigation does not navigate to auth endpoint after logout
     * @private
     */
    private async stopLogin(reloadApp = false, skipNavigation = false) {
        this.logger.log('[AUTH] stopping login process now');
        AWS.config.credentials = null; // remove credentials for aws services

        // remove interval which refreshes session
        if (this.sessionRefreshIntervalId != null) {
            this.logger.warn('[AUTH] removing interval for recurring session refresh');
            clearInterval(this.sessionRefreshIntervalId);
            this.sessionRefreshIntervalId = null;
        }

        this.setLoggedInFalse(reloadApp, skipNavigation);
    }

    /**
     * Deletes local data and logs the user out
     * @param reloadApp reloads the window after logout, this is needed almost in any case (except for support users)
     * @param skipNavigation does not navigate to auth endpoint after logout
     */
    public logout(reloadApp = true, skipNavigation = false) {
        this.utils.keepAwake('during logout');
        this.utils.displayPageBlockingLoader(true);

        this.db
            .deleteDatabase()
            .then((dbClearRes) => {
                this.logger.log('[AUTH] logout: cleared local database', dbClearRes);

                Promise.all([
                    this.storage.clear(), // clear local storage
                    this.attSrv.clearFilesystem(), // clear device disk,
                ])
                    .then((clearRes) => {
                        this.logger.log('[AUTH] logout: cleared device data', clearRes);
                        Sentry.configureScope((scope) => scope.setUser(null));
                        try {
                            // remove credentials for aws services
                            (AWS.config.credentials as any).clearCachedId();
                            AWS.config.credentials = null;
                        } catch (awsErr) {
                            this.logger.log('[AUTH] logout: could not remove aws credentials', awsErr);
                        }

                        try {
                            sessionStorage.clear();
                            this.logger.log('[AUTH] logout: cleared window session storage');
                        } catch (sessionStorageErr) {
                            this.logger.error('[AUTH] logout: could not clear window session storage', sessionStorageErr);
                        }

                        this.utils.allowSleep('during logout');
                        this.stopLogin(reloadApp, skipNavigation);
                    })
                    .catch((clearErr) => {
                        this.logger.error('[AUTH] logout: could not complete logout', clearErr);
                        this.utils.sentryCaptureException(clearErr);

                        this.utils.allowSleep('after logout failure');
                    });
            })
            .catch((dbClearErr) => {
                this.logger.error('[AUTH] logout: could not complete logout because db could not be deleted', dbClearErr);
                this.utils.sentryCaptureException(dbClearErr);

                this.utils.allowSleep('after logout failure (db deletion fail)');
            });
    }

    public requireAuth(reason: string) {
        this.logger.warn('[AUTH] App requesting for a required auth', reason);
        this.stopLogin(false);
    }

    /**
     * Changes the loggedIn status to false and navigates to auth page
     * @param reloadApp If true reloads the app after redirecting to auth page (to reset the app in a very clean state)
     * @param skipNavigation does not navigate to auth endpoint after logout
     * @private
     */
    private setLoggedInFalse(reloadApp = false, skipNavigation = false) {
        this.isLoggedIn = false;
        this.loginStatusChanged$.next(this.isLoggedIn);
        if (skipNavigation) {
            // just reload current url, without navigation to auth page (used during support login)
            window.location.reload();
        } else {
            this.router.navigate([AUTH_ENDPOINT_URL_PATH]).then(() => {
                if (reloadApp) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            });
        }
    }

    /**
     * Returns info about the device (os versions, etc.)
     */
    public getDeviceInfo() {
        return new Promise<DeviceInfo>((resolve) => {
            Device.getInfo()
                .then((info) => {
                    resolve(info);
                })
                .catch((err) => {
                    this.logger.error('[AUTH] could not get device info', err);
                    resolve(null);
                });
        });
    }

    /**
     * Returns the UUID of the device
     */
    public getDeviceId() {
        return new Promise<DeviceId>((resolve) => {
            Device.getId()
                .then((idInfo) => {
                    resolve(idInfo);
                })
                .catch((err) => {
                    this.logger.error('[AUTH] could not get device ID', err);
                    resolve(null);
                });
        });
    }

    /**
     * Connects to the dokupit websocket api which is used for various live communications (e.g. to receive an info when something on our active project has changed, so that we know when to sync, instead of polling every few seconds)
     * @private
     */
    private async initWebsocketApiConnection() {
        if (this.blockSessionRefresh) {
            this.logger.log('[AUTH SERVICE WS] will not connect to websocket - session refresh blocked');
            return;
        }
        if (this.currentWebSocketConnectionStatus) {
            this.logger.log('[AUTH SERVICE WS] will not connect to websocket - already connected');
            return;
        }
        if (!this.utils.isOnline) {
            // device is offline so try again later
            this.logger.log('[AUTH SERVICE WS] cannot connect to websocket - device offline. trying again in 3 minutes');

            setTimeout(() => {
                this.initWebsocketApiConnection();
            }, 180000);
            return;
        }

        if (this.userIdleStatus != UserIdleStatus.ACTIVE) {
            this.logger.log('[AUTH SERVICE WS] will not connect to websocket api, user is idle');
            this.webSocketConnectionRetryTime = DEFAULT_WEBSOCKET_CONNECTION_RETRY_TIME; // reset backoff time
            return;
        }

        this.logger.warn('[AUTH SERVICE WS] trying to connect to websocket api');

        let deviceInfo = await this.getDeviceId();
        let deviceId = deviceInfo.identifier || 'unknown';

        // connect to websocket
        this.webSocketConnection = webSocket({
            url: `${environment.AWS_DOWNSTREAM_SYNC_WS_API_URL}/?deviceId=${deviceId}&authToken=${this.lastUsedIdToken}`,
            openObserver: {
                next: () => {
                    this.logger.warn('[AUTH SERVICE WS] websocket is now connected');
                    this.currentWebSocketConnectionStatus = true;
                    this.webSocketConnectionStatusChanged$.next(this.currentWebSocketConnectionStatus);
                },
            },
            closeObserver: {
                next: () => {
                    // if the websocket is closed we try to connect again, we always want an active connection
                    this.webSocketConnectionRetryTime = this.webSocketConnectionRetryTime * 2; // backoff
                    this.logger.warn(
                        `[AUTH SERVICE WS] websocket was closed. trying to connect again in ${
                            this.webSocketConnectionRetryTime / 1000
                        } seconds`
                    );
                    this.currentWebSocketConnectionStatus = false;
                    this.webSocketConnectionStatusChanged$.next(this.currentWebSocketConnectionStatus);
                    setTimeout(() => {
                        this.initWebsocketApiConnection();
                    }, this.webSocketConnectionRetryTime);
                },
            },
        });

        // subscribe to messages we receive
        this.webSocketConnection.subscribe(
            (wsRes) => {
                this.logger.warn('[AUTH SERVICE WS] websocket response', wsRes);
                this.webSocketMessageReceived$.emit(wsRes);
                this.webSocketConnectionRetryTime = DEFAULT_WEBSOCKET_CONNECTION_RETRY_TIME;

                // handle devicelock
                if (wsRes.action === 'auth-lock-device') {
                    this.setDeviceLock(true);
                }
            },
            (wsErr) => {
                this.logger.error('[AUTH SERVICE WS] websocket error. closeObserver will handle the reconnect', wsErr);
            },
            () => {
                this.logger.warn('[AUTH SERVICE WS] websocket was closed. closeObserver will handle the reconnect');
            }
        );
    }

    /**
     * Function to send a message to our websocket api via the currently active websocket
     * The payload has to have an "action" which is defined as route in the aws api gateway - this determines the lambda function which is triggerd afterwards
     * @param payload
     */
    public sendToWebsocketApi(payload: { action: string; [x: string]: any }) {
        if (this.webSocketConnection) {
            this.webSocketConnection.next(payload);
        } else {
            this.logger.warn('[AUTH SERVICE WS] could not send message to websocket api', payload);
        }
    }

    /**
     * Initializes the idle status checks
     * @private
     */
    private initIdleCheck() {
        // set interval to check idle
        if (this.idleCheckIntervalId == null) {
            this.logger.log('[AUTH] setting interval for idle check');
            this.idleCheckIntervalId = setInterval(() => {
                this.checkIdleStatus();
            }, 30000);

            this.setLastActionTime();

            window.addEventListener('click', (event) => {
                this.setLastActionTime();
            });
            window.addEventListener('keydown', (event) => {
                this.setLastActionTime();
            });
        }
    }

    /**
     * Checks if the user is currently idle - emitting corresponding idle events
     * @param forceEventOutput if set to true we always emit the userIdleStatus event for other components (if false we only emit if the idle status has changed)
     * @private
     */
    private checkIdleStatus(forceEventOutput = false) {
        let currentTimestamp = Date.now();
        if (this.lastActionTimestamp < currentTimestamp - 600000) {
            // user has not done anything for the last 10 minutes, emit long idle event if we were not already in long idle
            if (this.userIdleStatus != UserIdleStatus.LONG_IDLE || forceEventOutput) {
                this.logger.log('[AUTH] user is now long-idle');
                this.userIdleStatus = UserIdleStatus.LONG_IDLE;
                this.userIdleStatusChanged.next(this.userIdleStatus);
            }
        } else if (this.lastActionTimestamp < currentTimestamp - 300000) {
            // user has not done anything for the last 5 minutes, emit short idle event if we were not already in short idle
            if (this.userIdleStatus != UserIdleStatus.SHORT_IDLE || forceEventOutput) {
                this.logger.log('[AUTH] user is now short-idle');
                this.userIdleStatus = UserIdleStatus.SHORT_IDLE;
                this.userIdleStatusChanged.next(this.userIdleStatus);
            }
        } else {
            // user is active, emit event if he was idle before
            if (this.userIdleStatus != UserIdleStatus.ACTIVE || forceEventOutput) {
                this.logger.log('[AUTH] user is now active');
                this.userIdleStatus = UserIdleStatus.ACTIVE;
                this.userIdleStatusChanged.next(this.userIdleStatus);
                this.initWebsocketApiConnection(); // connect to websocket again
            }
        }
    }

    /**
     * Sets the current timestamp as the last action timestamp. Used to determine if the user has gone idle
     * @param forceIdleStatusCheck if true a idle status check is forced afterwards (if false only if the idle status is not active)
     * @private
     */
    private setLastActionTime(forceIdleStatusCheck = false) {
        this.lastActionTimestamp = Date.now();

        if (this.userIdleStatus != UserIdleStatus.ACTIVE || forceIdleStatusCheck) {
            this.checkIdleStatus(forceIdleStatusCheck); // used to immediately change idle status if we are just becoming active again, after idling
        }
    }

    /**
     * Handles the app resume, when the app comes back from background/standby etc.
     * @private
     */
    private async handleAppResume() {
        this.logger.log('[AUTH SERVICE] app resume handler started');
        await this.startLoginWithTokens();
        this.setLastActionTime(true);
        this.initWebsocketApiConnection();
        this.logger.log('[AUTH SERVICE] app resume handler ended');
    }

    /**
     * Shows or hides the device lock because of same-time sessions
     * Needed if the user is currently active on another device
     * @param lock
     */
    setDeviceLock(lock: boolean) {
        if (lock) {
            document.getElementsByClassName('auth-device-lock')[0].classList.add('device-lock-active');
        } else {
            document.getElementsByClassName('auth-device-lock')[0].classList.remove('device-lock-active');
        }
        this.deviceLocked = lock;
    }
}
