<ng-container *ngIf="showImageWithoutStyles && !loading && imageSrc !== ''">
    <ion-img class="image-without-styles" [src]="imageSrc"></ion-img>
</ng-container>

<div
    class="attachment-view-wrap"
    *ngIf="!showImageWithoutStyles"
    [ngClass]="{
        'no-actions': showActions === false,
    }"
>
    <!-- LOADING START -->
    <app-loading-indicator *ngIf="loading"> Datei wird geladen </app-loading-indicator>
    <!-- LOADING END -->

    <!-- ERROR START -->
    <div class="dp-notice small error attachment-not-available" *ngIf="error && !loading">
        <div class="notice-icon">
            <fa-icon icon="wifi-slash" class="color-danger"></fa-icon>
        </div>
        <div class="notice-heading">Datei nicht verfügbar</div>
        <div class="notice-action">
            Sie benötigen eine Internetverbindung um die gewünschte Datei abzurufen.
            <ion-button fill="clear" (click)="loadAttachment()">
                <fa-icon icon="undo"></fa-icon>
                Erneut versuchen
            </ion-button>
        </div>
        <div class="error-action-tools dp-action-buttons" *ngIf="canDelete">
            <ion-button fill="clear" class="attachment-tools-trigger icon-only ion-no-margin" (click)="onDeleteAttachment()">
                <fa-icon icon="trash" class="color-danger"></fa-icon>
            </ion-button>
        </div>
    </div>
    <!-- ERROR END -->

    <!-- VIEW CONTAINER START -->
    <div class="attachment-view-container" *ngIf="!error && !loading">
        <!-- IMAGE START -->
        <div
            class="attachment-view-image ion-activatable"
            [ngClass]="{
                'image-limited-height-small': limitedHeight === 'small',
                'image-limited-height-medium': limitedHeight === 'medium',
            }"
            *ngIf="viewMode === 'image' && imageSrc && !loading"
        >
            <ion-img
                [src]="imageSrc"
                [alt]="$any(attachment).displayName || $any(attachment).fileName"
                (click)="onOpenImgPreview()"
            ></ion-img>
            <ion-ripple-effect></ion-ripple-effect>
            <ng-container *ngIf="allowNotices">
                <div
                    class="attachment-notice-trigger"
                    *ngIf="!$any(attachment).notice && !noticeVisible && canDelete"
                    (click)="noticeVisible = true"
                >
                    <ion-button size="small" fill="outline" class="round">
                        <fa-icon icon="comment"></fa-icon>
                    </ion-button>
                </div>
            </ng-container>
        </div>
        <div class="image-details">
            <ng-container *ngIf="showDateTime"
                >{{ $any(attachment).createdAt | date: 'dd.MM.yyyy' }}
                <span *ngIf="showAdditionalText"> | </span>
            </ng-container>
            <span *ngIf="showAdditionalText"> {{ additionalText }}</span>
            <ng-container *ngIf="allowNotices && ($any(attachment).notice || noticeVisible)">
                <div class="attachment-notice-spacer"></div>
                <div class="attachment-notice-wrap">
                    <ion-item lines="none">
                        <ion-textarea
                            [(ngModel)]="$any(attachment).notice"
                            autosize
                            aria-label="Notiz"
                            inputmode="text"
                            rows="1"
                            placeholder="Notiz eingeben"
                            (ionInput)="onNoticeChanged($event)"
                            [readonly]="!canDelete"
                        ></ion-textarea>
                    </ion-item>
                </div>
            </ng-container>
        </div>
        <!-- IMAGE END -->

        <!-- DOCUMENT START -->
        <div class="attachment-view-document ion-text-center" *ngIf="viewMode === 'document' && !loading" (click)="openAttachment()">
            <div class="attachment-icon">
                <fa-icon icon="file-pdf" size="2x"></fa-icon>
            </div>
            <div class="attachment-name">
                {{ $any(attachment).displayName || $any(attachment).fileName }}
            </div>
        </div>
        <!-- DOCUMENT END -->

        <!-- Actions for mobile when showing documents START -->
        <ion-fab vertical="top" horizontal="end" class="dp-attachment-actions" *ngIf="showActions && viewMode === 'document'">
            <ion-fab-button size="small" (click)="handleAttachmentActionButtons()">
                <fa-icon [icon]="['fal', 'ellipsis-h']"></fa-icon>
            </ion-fab-button>
        </ion-fab>
        <!-- Actions for mobile when showing documents END -->
    </div>
    <!-- VIEW CONTAINER END -->

    <ion-modal class="img-preview" (didPresent)="onDialogStateChanged(true)" (didDismiss)="onDialogStateChanged(false)" #fullImgPreview>
        <ng-template>
            <ion-header>
                <ion-toolbar>
                    <h2 class="dp-pl-m">
                        {{ $any(attachment)?.displayName ? $any(attachment)?.displayName : 'Foto' }}
                        <ion-chip *ngIf="!utils.mobileViewActive" slot="end" color="none">{{
                            $any(attachment).createdAt | date: 'dd.MM.yyyy'
                        }}</ion-chip>
                    </h2>
                    <ion-chip *ngIf="utils.mobileViewActive" slot="end" color="none">{{
                        $any(attachment).createdAt | date: 'dd.MM.yyyy'
                    }}</ion-chip>
                    <ion-buttons slot="end">
                        <ng-container *ngIf="showActions && !(utils.isPhone || utils.isTablet) && !editing">
                            <ion-button *ngIf="canDelete" color="danger" (click)="onDeleteAttachment()">
                                <fa-icon icon="trash" slot="start"></fa-icon>
                                <ion-label>Löschen</ion-label>
                            </ion-button>
                            <ion-button (click)="downloadAttachment()" *ngIf="!utils.isPhone && !utils.isTablet">
                                <fa-icon [icon]="['fal', 'cloud-download']" slot="start"></fa-icon>
                                <ion-label>Herunterladen</ion-label>
                            </ion-button>
                            <ion-button *ngIf="canEdit" (click)="startEditor()">
                                <fa-icon icon="pencil" slot="start"></fa-icon>
                                <ion-label>Bearbeiten</ion-label>
                            </ion-button>
                        </ng-container>
                        <ion-button fill="clear" color="primary" (click)="closeImgPreview()">
                            <fa-icon icon="times" slot="icon-only"></fa-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ng-container *ngIf="editing">
                    <dp-image-editor
                        [src]="imageSrc"
                        (loaded)="handleLoad($event)"
                        (cancelled)="handleEditorCancel()"
                        (process)="handleProcess($event)"
                    ></dp-image-editor>
                </ng-container>
                <ng-container *ngIf="imageSrc && !loading">
                    <pinch-zoom class="image-wrapper" [overflow]="'visible'" [zoomControlScale]="1" [limit-zoom]="10" *ngIf="!editing">
                        <ion-img [src]="imageSrc" [alt]="$any(attachment).displayName || $any(attachment).fileName"></ion-img>
                    </pinch-zoom>
                </ng-container>

                <ion-fab
                    vertical="bottom"
                    horizontal="end"
                    class="dp-image-editor-fabs"
                    *ngIf="showActions && (utils.isPhone || utils.isTablet) && !editing"
                >
                    <ion-fab-button
                        *ngIf="canDelete"
                        size="small"
                        color="danger"
                        dpTooltip
                        tooltipContent="Datei löschen"
                        tooltipTrigger="mouseenter focus"
                        tooltipPosition="left"
                        (click)="onDeleteAttachment()"
                    >
                        <fa-icon icon="trash"></fa-icon>
                    </ion-fab-button>
                    <ion-fab-button
                        size="small"
                        color="primary"
                        dpTooltip
                        tooltipContent="Datei herunterladen"
                        tooltipTrigger="mouseenter focus"
                        tooltipPosition="left"
                        (click)="downloadAttachment()"
                        *ngIf="!utils.isPhone && !utils.isTablet"
                    >
                        <fa-icon [icon]="['fal', 'cloud-download']"></fa-icon>
                    </ion-fab-button>
                    <ion-fab-button
                        *ngIf="canEdit"
                        color="primary"
                        dpTooltip
                        tooltipContent="Foto bearbeiten"
                        tooltipTrigger="mouseenter focus"
                        tooltipPosition="left"
                        (click)="startEditor()"
                    >
                        <fa-icon icon="pencil"></fa-icon>
                    </ion-fab-button>
                </ion-fab>
            </ion-content>
        </ng-template>
    </ion-modal>
</div>
