<div class="signature-start-wrap ion-text-center">
    <ion-button (click)="onShowSignatureDialog()" size="small">
        <fa-icon icon="signature" slot="start"></fa-icon>
        Jetzt unterschreiben
    </ion-button>
</div>

<ion-modal class="stack-modal" [isOpen]="signatureModalVisible" (ionModalDidDismiss)="onCancelSignature()" style="--height: auto">
    <ng-template>
        <ion-header>
            <ion-toolbar color="none">
                <ion-title>Unterschrift</ion-title>
                <ion-buttons slot="end">
                    <ion-button fill="clear" shape="round" color="primary" (click)="onCancelSignature()">
                        <fa-icon slot="icon-only" icon="times"></fa-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <div class="signature-modal-content">
            <ion-input
                type="text"
                label="Name"
                labelPlacement="floating"
                fill="outline"
                mode="md"
                placeholder="Name eingeben"
                class="dp-mb-s bg-light"
                [(ngModel)]="signatoryName"
                [disabled]="preFilledSignatoryName != null"
            ></ion-input>

            <div class="signature-canvas-wrap" #signatureCanvasWrap>
                <canvas class="signature-canvas" #signatureCanvas></canvas>
            </div>
        </div>

        <ion-footer>
            <ion-toolbar class="signature-canvas-actions" color="none">
                <ion-buttons slot="start">
                    <ion-button size="small" color="danger" fill="clear" class="dp-mr-m" (click)="onClearSignature()">
                        <fa-icon icon="signature" slot="start"></fa-icon>
                        Feld leeren
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button size="small" fill="clear" color="warning" (click)="onCancelSignature()"> Abbrechen </ion-button>
                    <ion-button
                        fill="solid"
                        color="success"
                        (click)="onConfirmSignature()"
                        [dpIsLoading]="uploadLoader"
                        [disabled]="signatoryName === ''"
                    >
                        <fa-icon icon="check" slot="start"></fa-icon>
                        Bestätigen
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>
    </ng-template>
</ion-modal>
