import { v4 as uuidv4 } from 'uuid';
import { Address } from './address.model';
import { ContactInfo } from './contact-info.model';
import { Attachment } from '../attachment/attachment.model';
import { ProjectAdditionalFieldCategory } from '../../projects/project.model';
import { LicenseType } from './license-type.model';
import { ReportFilter } from '../../projects/project-details/project-reports/report.model';
import { CameraOrientation } from '../camera/camera-orientation.enum';

/**
 * The model for companies (customers)
 */
export class Company {
    _id: string; // user id
    type: string; // item type
    name: string; // company name (used for dokupit internal management, billing, etc, not changeable by customer)
    nameSuffix: string; // company name suffix
    displayName: string; // company name (used in app, for reports, etc. changeable by customer, not used internally)
    address: Address; // company default address (used for info, reports, etc)
    contactInfo: ContactInfo;
    logo: Attachment;
    billingAddress: Array<Address>; // company billing address (for dokupit invoices)
    billingEmail: string; // company billing email
    billingType: string; // billing type
    shippingAddress: Array<Address>; // company shipping address (for dokupit shipping)
    vat: string; // company vat
    priceModel: CompanyPriceModel; // company pricing info
    companySettings: CompanySettings;
    companyFeatures: CompanyFeatures;
    ssoActive?: boolean; // set via management panel, true if sso is active
    availableLicenseTypes: Array<LicenseType>; // all available user license types in this company
    isDokuPitIntern: boolean; // if the company is dokupit internal
    createdAt: number; // creation timestamp
    updatedAt: number; // last update timestamp
    reseller?: {
        commissionPerLicense: number;
        commissionPerSign: number;
        id: string;
    };

    pk: string; // primary key in dynamoDb
    sk?: string; // sort key in dynamoDb

    constructor(data?: any) {
        this._id = uuidv4();
        this.type = 'company';
        this.name = '';
        this.nameSuffix = '';
        this.displayName = '';
        this.address = new Address();
        this.contactInfo = new ContactInfo();
        this.logo = null;
        this.billingAddress = [new Address()];
        this.billingEmail = '';
        this.billingType = '';
        this.shippingAddress = [];
        this.vat = '';
        this.priceModel = new CompanyPriceModel();
        this.companySettings = new CompanySettings();
        this.companyFeatures = new CompanyFeatures();
        this.availableLicenseTypes = [];
        this.isDokuPitIntern = false;
        this.createdAt = Date.now();
        this.updatedAt = Date.now();

        // default pk = _id
        this.pk = this._id; // default pk = _id

        if (data != null) {
            if (data._id != null) {
                this._id = data._id;
            }
            if (data.id != null) {
                this._id = data.id; // fallback
            }
            if (data.type != null) {
                this.type = data.type;
            }
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.nameSuffix != null) {
                this.nameSuffix = data.nameSuffix;
            }
            if (data.displayName != null) {
                this.displayName = data.displayName;
            }
            if (data.address != null) {
                this.address = new Address(data.address);
            }
            if (data.contactInfo != null) {
                this.contactInfo = new ContactInfo(data.contactInfo);
            }
            if (data.logo != null) {
                this.logo = new Attachment(data.logo);
            }
            if (data.billingAddress != null) {
                if (Array.isArray(data.billingAddress)) {
                    this.billingAddress = data.billingAddress.map((x) => new Address(x));
                } else {
                    this.billingAddress = [new Address(data.billingAddress)];
                }
            }
            if (data.billingEmail != null) {
                this.billingEmail = data.billingEmail;
            }
            if (data.billingType != null) {
                this.billingType = data.billingType;
            }
            if (data.shippingAddress != null && Array.isArray(data.shippingAddress)) {
                this.shippingAddress = data.shippingAddress.map((x) => new Address(x));
            }
            if (data.vat != null) {
                this.vat = data.vat;
            }
            if (data.priceModel != null) {
                this.priceModel = new CompanyPriceModel(data.priceModel);
            }
            if (data.companySettings != null) {
                this.companySettings = new CompanySettings(data.companySettings);
            }
            if (data.companyFeatures != null) {
                this.companyFeatures = new CompanyFeatures(data.companyFeatures);
            }
            if (data.ssoActive != null) {
                this.ssoActive = data.ssoActive;
            }
            if (data.availableLicenseTypes != null) {
                this.availableLicenseTypes = data.availableLicenseTypes.map((x) => new LicenseType(x));
            }
            if (data.isDokuPitIntern != null) {
                this.isDokuPitIntern = data.isDokuPitIntern;
            }
            if (data.createdAt != null) {
                this.createdAt = data.createdAt;
            }
            if (data.updatedAt != null) {
                this.updatedAt = data.updatedAt;
            }
            if (data.pk != null) {
                this.pk = data.pk;
            }
            if (data.sk != null) {
                this.sk = data.sk;
            }
            if (data.reseller != null) {
                this.reseller = data.reseller;
            }
        }

        // check if user license types have been initialized, otherwise init them
        if (this.availableLicenseTypes == null || this.availableLicenseTypes.length == 0) {
            this.initAvailableLicenseTypes();
        }
    }

    /**
     * Initializes the default dokupit user license types
     */
    initAvailableLicenseTypes() {
        this.availableLicenseTypes = [];

        // push admin role
        let adminRole = new LicenseType({
            name: 'admin',
            displayName: 'Admin-Benutzer',
        });
        this.availableLicenseTypes.push(adminRole);

        // push mobile role
        let mobileRole = new LicenseType({
            name: 'mobile',
            displayName: 'Standard-Benutzer',
        });
        this.availableLicenseTypes.push(mobileRole);

        // push viewer role
        let viewerRole = new LicenseType({
            name: 'viewer',
            displayName: 'Betrachter-Benutzer',
            viewer: true,
        });
        this.availableLicenseTypes.push(viewerRole);
    }
}

export class CompanySettings {
    allowNonUniqueCodeUse: boolean;
    syncJobNumberAndCode: boolean;
    adjustJobNameBasedOnArticles: boolean;
    startPhotoDocumentationWithCamera: boolean;
    enableJobLinkings: boolean;
    productSelectionViewModeGrid: boolean;
    disableAutoJobExtensionAccordionToggle: boolean;
    combineCustomIconFields: boolean;
    photoScalingQuality: 'FHD' | 'QHD' | '4K' | 'raw';
    emailNotifications: Array<{
        email: string;
    }>;
    newItemEmailNotificationSendType: 'single' | 'combined';
    projectAdditionalFields: Array<ProjectAdditionalFieldCategory>;
    reportDownloadFileName: 'default' | 'exclude-project-name';
    currency: string;
    accountingShowWarnings: boolean;
    accountingShowDetailedVariation: boolean;
    accountingVariationIncludeFieldname: boolean;
    accountingShowUniqueRowIds: boolean;
    accountingColumnNames: {
        articleNameColumn: string;
        articleNumberColumn: string;
        articleShortTextColumn: string;
        variationColumn: string;
        jobNameColumn: string;
        jobNumberColumn: string;
        jobNumberMultipleColumn: string;
        jobCodeColumn: string;
        jobCodeMultipleColumn: string;
        jobDateColumn: string;
        jobWorkerColumn: string;
        amountColumn: string;
        unitPriceColumn: string;
        totalPriceColumn: string;
    };
    savedAccountingSettings: Array<AccountingSetting>;
    timeTrackingDefaultTargetHours: number;
    timeTrackingDefaultBreakRemovalMinRequirement: number;
    timeTrackingDefaultBreakRemovalTime: number;
    timeTrackingExpenseAllowances: Array<TimeTrackingExpenseAllowance>;
    timeTrackingEnableAutomaticBooking: boolean;
    timeTrackingAutomaticBookingOnProject: string;
    timeTrackingDefaultAutomaticStartTime: string;
    timeTrackingDefaultAutomaticEndTime: string;
    timeTrackingDefaultTargetDays: Array<TimeTrackingTargetDay>;
    timeTrackingDefaultHourFormat: TimeTrackingHourFormat;

    timeTrackingOptionalTargetDays: Array<TimeTrackingTargetDay>;
    timeTrackingStartTime: string;
    timeTrackingEndTime: string;
    timeTrackingPreventBookingStartBeforeStartTime: boolean;

    publicCodeScan: PublicCodeScanOptions;
    allowProjectPhoto: boolean;

    constructor(data?: any) {
        this.allowNonUniqueCodeUse = false;
        this.syncJobNumberAndCode = false;
        this.adjustJobNameBasedOnArticles = true;
        this.startPhotoDocumentationWithCamera = true;
        this.enableJobLinkings = false;
        this.productSelectionViewModeGrid = false;
        this.disableAutoJobExtensionAccordionToggle = false;
        this.combineCustomIconFields = false;
        this.photoScalingQuality = 'FHD';
        this.emailNotifications = [];
        this.newItemEmailNotificationSendType = 'combined';
        this.projectAdditionalFields = [];
        this.reportDownloadFileName = 'default';
        this.currency = '€';
        this.accountingShowWarnings = true;
        this.accountingShowDetailedVariation = false;
        this.accountingVariationIncludeFieldname = true;
        this.accountingShowUniqueRowIds = false;
        this.accountingColumnNames = {
            articleNameColumn: 'Leistungsname',
            articleNumberColumn: 'Artikelnummer',
            articleShortTextColumn: 'Artikel-Kurztext',
            variationColumn: 'Variation',
            jobNameColumn: 'Dokumentationsname',
            jobNumberColumn: 'Dokumentationsnummer',
            jobNumberMultipleColumn: 'Dokumentationsnummern',
            jobCodeColumn: 'Identifizierungscode',
            jobCodeMultipleColumn: 'Identifizierungscodes',
            jobDateColumn: 'Erstellungsdatum',
            jobWorkerColumn: 'Ersteller',
            amountColumn: 'Anzahl',
            unitPriceColumn: 'Preis pro Stück',
            totalPriceColumn: 'Gesamtpreis',
        };
        this.savedAccountingSettings = [];
        this.timeTrackingDefaultTargetHours = 8;
        this.timeTrackingDefaultBreakRemovalMinRequirement = 6;
        this.timeTrackingDefaultBreakRemovalTime = 0.5;
        this.timeTrackingExpenseAllowances = [new TimeTrackingExpenseAllowance()];
        this.timeTrackingEnableAutomaticBooking = false;
        this.timeTrackingAutomaticBookingOnProject = null;
        this.timeTrackingDefaultAutomaticStartTime = null;
        this.timeTrackingDefaultAutomaticEndTime = null;
        this.timeTrackingDefaultTargetDays = [
            TimeTrackingTargetDay.MONDAY,
            TimeTrackingTargetDay.TUESDAY,
            TimeTrackingTargetDay.WEDNESDAY,
            TimeTrackingTargetDay.THURSDAY,
            TimeTrackingTargetDay.FRIDAY,
        ];
        this.timeTrackingOptionalTargetDays = [];
        this.timeTrackingStartTime = null;
        this.timeTrackingEndTime = null;
        this.timeTrackingPreventBookingStartBeforeStartTime = false;
        this.timeTrackingDefaultHourFormat = TimeTrackingHourFormat.DECIMAL;
        this.publicCodeScan = PublicCodeScanOptions.ALLOWED;
        this.allowProjectPhoto = true;

        if (data != null) {
            if (data.allowNonUniqueCodeUse != null) {
                this.allowNonUniqueCodeUse = data.allowNonUniqueCodeUse;
            }
            if (data.syncJobNumberAndCode != null) {
                this.syncJobNumberAndCode = data.syncJobNumberAndCode;
            }
            if (data.adjustJobNameBasedOnArticles != null) {
                this.adjustJobNameBasedOnArticles = data.adjustJobNameBasedOnArticles;
            }
            if (data.startPhotoDocumentationWithCamera != null) {
                this.startPhotoDocumentationWithCamera = data.startPhotoDocumentationWithCamera;
            }
            if (data.enableJobLinkings != null) {
                this.enableJobLinkings = data.enableJobLinkings;
            }
            if (data.productSelectionViewModeGrid != null) {
                this.productSelectionViewModeGrid = data.productSelectionViewModeGrid;
            }
            if (data.disableAutoJobExtensionAccordionToggle != null) {
                this.disableAutoJobExtensionAccordionToggle = data.disableAutoJobExtensionAccordionToggle;
            }
            if (data.combineCustomIconFields != null) {
                this.combineCustomIconFields = data.combineCustomIconFields;
            }
            if (data.photoScalingQuality != null) {
                this.photoScalingQuality = data.photoScalingQuality;
            }
            if (data.currency != null) {
                this.currency = data.currency;
            }
            if (data.emailNotifications) {
                this.emailNotifications = data.emailNotifications;
            }
            if (data.newItemEmailNotificationSendType) {
                this.newItemEmailNotificationSendType = data.newItemEmailNotificationSendType;
            }
            if (data.projectAdditionalFields != null) {
                this.projectAdditionalFields = data.projectAdditionalFields.map((x) => new ProjectAdditionalFieldCategory(x));
            }
            if (data.reportDownloadFileName != null) {
                this.reportDownloadFileName = data.reportDownloadFileName;
            }
            if (data.accountingShowWarnings != null) {
                this.accountingShowWarnings = data.accountingShowWarnings;
            }
            if (data.accountingShowDetailedVariation != null) {
                this.accountingShowDetailedVariation = data.accountingShowDetailedVariation;
            }
            if (data.accountingVariationIncludeFieldname != null) {
                this.accountingVariationIncludeFieldname = data.accountingVariationIncludeFieldname;
            }
            if (data.accountingShowUniqueRowIds != null) {
                this.accountingShowUniqueRowIds = data.accountingShowUniqueRowIds;
            }
            if (data.accountingColumnNames != null) {
                this.accountingColumnNames = data.accountingColumnNames;

                // extra handling because this column was added later
                if (data.accountingColumnNames.articleShortTextColumn == null) {
                    this.accountingColumnNames.articleShortTextColumn = 'Artikel-Kurztext';
                }
            }
            if (data.savedAccountingSettings != null) {
                this.savedAccountingSettings = data.savedAccountingSettings.map((x) => new AccountingSetting(x));
            }
            if (data.timeTrackingDefaultTargetHours != null) {
                this.timeTrackingDefaultTargetHours = data.timeTrackingDefaultTargetHours;
            }
            if (data.timeTrackingDefaultBreakRemovalMinRequirement != null) {
                this.timeTrackingDefaultBreakRemovalMinRequirement = data.timeTrackingDefaultBreakRemovalMinRequirement;
            }
            if (data.timeTrackingDefaultBreakRemovalTime != null) {
                this.timeTrackingDefaultBreakRemovalTime = data.timeTrackingDefaultBreakRemovalTime;
            }
            if (data.timeTrackingExpenseAllowances != null) {
                this.timeTrackingExpenseAllowances = data.timeTrackingExpenseAllowances.map((x) => new TimeTrackingExpenseAllowance(x));
            }
            if (data.timeTrackingEnableAutomaticBooking != null) {
                this.timeTrackingEnableAutomaticBooking = data.timeTrackingEnableAutomaticBooking;
            }
            if (data.timeTrackingAutomaticBookingOnProject != null) {
                this.timeTrackingAutomaticBookingOnProject = data.timeTrackingAutomaticBookingOnProject;
            }
            if (data.timeTrackingDefaultAutomaticStartTime != null) {
                this.timeTrackingDefaultAutomaticStartTime = data.timeTrackingDefaultAutomaticStartTime;
            }
            if (data.timeTrackingDefaultAutomaticEndTime != null) {
                this.timeTrackingDefaultAutomaticEndTime = data.timeTrackingDefaultAutomaticEndTime;
            }
            if (data.timeTrackingDefaultTargetDays != null) {
                this.timeTrackingDefaultTargetDays = data.timeTrackingDefaultTargetDays;
            }
            if (data.timeTrackingOptionalTargetDays != null) {
                this.timeTrackingOptionalTargetDays = data.timeTrackingOptionalTargetDays;
            }
            if (data.timeTrackingStartTime != null) {
                this.timeTrackingStartTime = data.timeTrackingStartTime;
            }
            if (data.timeTrackingEndTime != null) {
                this.timeTrackingEndTime = data.timeTrackingEndTime;
            }
            if (data.timeTrackingPreventBookingStartBeforeStartTime != null) {
                this.timeTrackingPreventBookingStartBeforeStartTime = data.timeTrackingPreventBookingStartBeforeStartTime;
            }
            if (data.timeTrackingDefaultHourFormat != null) {
                this.timeTrackingDefaultHourFormat = data.timeTrackingDefaultHourFormat;
            }
            if (data.publicCodeScan != null) {
                this.publicCodeScan = data.publicCodeScan;
            }
            if (data.allowProjectPhoto != null) {
                this.allowProjectPhoto = data.allowProjectPhoto;
            }
        }
    }
}

export class CompanyFeatures {
    timeTracking: boolean; // true if timeTracking is activated
    reportLayoutBuilder: boolean; // true if report layout builder is activated
    lvMatching: boolean; // true if lv-matching is activated
    iTWOAccountingExport: boolean; // true if iTWO accounting export is activated
    externalJobId: boolean; // true if external job id is activated
    easyArchive: boolean; // true if easy archive is activated => reports will be uploaded to the selected destination
    projectInfoSearch: boolean; // true if projectInfoSearch is activated => specified lambda function will be executed to prefill project data
    backups: boolean; //true if backups is activated => backups to a server

    constructor(data?: any) {
        this.timeTracking = false;
        this.reportLayoutBuilder = false;
        this.lvMatching = false;
        this.iTWOAccountingExport = false;
        this.externalJobId = false;
        this.easyArchive = false;
        this.projectInfoSearch = false;
        this.backups = false;

        if (data != null) {
            if (data.timeTracking != null) {
                this.timeTracking = data.timeTracking;
            }
            if (data.reportLayoutBuilder != null) {
                this.reportLayoutBuilder = data.reportLayoutBuilder;
            }
            if (data.lvMatching != null) {
                this.lvMatching = data.lvMatching;
            }
            if (data.iTWOAccountingExport != null) {
                this.iTWOAccountingExport = data.iTWOAccountingExport;
            }
            if (data.externalJobId != null) {
                this.externalJobId = data.externalJobId;
            }
            if (data.easyArchive != null) {
                this.easyArchive = data.easyArchive;
            }
            if (data.projectInfoSearch != null) {
                this.projectInfoSearch = data.projectInfoSearch;
            }
            if (data.backups != null) {
                this.backups = data.backups;
            }
        }
    }
}

export class CompanyPriceModel {
    overrideDefault: boolean;
    standard: {
        pricePerLicense: number;
    };
    fotodocumentation: {
        pricePerLicense: number;
    };
    lite: {
        totalPrice: number;
        pricePerJob: number;
    };
    enterprise: {
        totalPrice: number;
    };
    flexibleLicenseRightsManagement: boolean;
    concurrentAdmins: number;

    constructor(data?: any) {
        this.overrideDefault = true;
        this.standard = {
            pricePerLicense: null,
        };
        this.fotodocumentation = {
            pricePerLicense: null,
        };
        this.lite = {
            totalPrice: null,
            pricePerJob: null,
        };
        this.enterprise = {
            totalPrice: null,
        };
        this.concurrentAdmins = 0;
        this.flexibleLicenseRightsManagement = true;

        if (data != null) {
            if (data.standard != null) {
                this.standard.pricePerLicense = data.standard.pricePerLicense;
            }
            if (data.fotodocumentation != null) {
                this.fotodocumentation.pricePerLicense = data.fotodocumentation.pricePerLicense;
            }
            if (data.lite != null) {
                this.lite.totalPrice = data.lite.totalPrice;
                this.lite.pricePerJob = data.lite.pricePerJob;
            }
            if (data.enterprise != null) {
                this.enterprise.totalPrice = data.enterprise.totalPrice;
            }
            if (data.concurrentAdmins != null) {
                this.concurrentAdmins = data.concurrentAdmins;
            }
            if (data.flexibleLicenseRightsManagement != null) {
                this.flexibleLicenseRightsManagement = data.flexibleLicenseRightsManagement;
            }
        }
    }
}

export class AccountingSetting {
    name: string;
    viewMode: 'detailed' | 'grouped';
    columnOrder: Array<string>;
    hiddenColumns: Array<string>;
    columnsForGrouping: Array<string>;
    savedFilters: Array<ReportFilter>;

    constructor(data?: any) {
        this.name = 'Standard';
        this.viewMode = 'detailed';
        this.columnOrder = [];
        this.hiddenColumns = [];
        this.columnsForGrouping = ['Leistungsname', 'Variation'];
        this.savedFilters = [];

        if (data != null) {
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.viewMode != null) {
                this.viewMode = data.viewMode;
            }
            if (data.columnOrder != null) {
                this.columnOrder = data.columnOrder;
            }
            if (data.hiddenColumns != null) {
                this.hiddenColumns = data.hiddenColumns;
            }
            if (data.columnsForGrouping != null) {
                this.columnsForGrouping = data.columnsForGrouping;
            }
            if (data.savedFilters != null) {
                this.savedFilters = data.savedFilters.map((x) => new ReportFilter(x));
            }
        }
    }
}

export class TimeTrackingExpenseAllowance {
    _id: string;
    zoneName: string;
    value: number;

    constructor(data?: any) {
        this._id = uuidv4();
        this.zoneName = 'Zone 1';
        this.value = 0;

        if (data != null) {
            if (data._id != null) {
                this._id = data._id;
            }
            if (data.zoneName != null) {
                this.zoneName = data.zoneName;
            }
            if (data.value != null) {
                this.value = data.value;
            }
        }
    }
}

export enum TimeTrackingTargetDay {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday',
}

export enum TimeTrackingHourFormat {
    DECIMAL = 'decimal',
    REAL = 'real',
}

export enum PublicCodeScanOptions {
    ALLOWED = 'allowed',
    NOT_ALLOWED = 'not-allowed',
}
