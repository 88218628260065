<ion-menu
    side="end"
    menuId="mobile-main-menu"
    contentId="main"
    type="push"
    class="mobile-menu"
    swipeGesture="false"
    (ionDidClose)="handleClose()"
    (ionDidOpen)="handleOpen()"
    #mobileAppMenu
>
    <ng-container *ngIf="!disable">
        <ion-header class="mobile-menu-header">
            <ion-toolbar>
                <ion-buttons slot="start" class="dp-info-btns">
                    <ion-item class="user-info" (click)="onToggleMobileMenu()">
                        <ion-avatar slot="start">
                            <ion-icon name="person-circle-outline" size="large"></ion-icon>
                        </ion-avatar>
                        <ion-label>
                            <h3>{{ authSrv.authInfo?.userData?.firstName }} {{ authSrv.authInfo?.userData?.lastName }}</h3>
                            <p>{{ this.authSrv.authInfo?.userData?.userName }}</p>
                        </ion-label>
                    </ion-item>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button class="menu-header-button" (click)="onToggleMobileMenu()">
                        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list (click)="onToggleMobileMenu()">
                <ion-item button lines="none" detail="true" [routerLink]="['/']" [routerDirection]="'root'">Projektübersicht</ion-item>
                <ion-item
                    button
                    [routerLink]="['/service-groups']"
                    routerDirection="root"
                    lines="none"
                    detail="true"
                    *ngIf="settingsSrv.currentUserRights.canEditGlobalServiceGroups"
                    >Vorlagen</ion-item
                >
                <ion-item
                    button
                    [routerLink]="['/time-tracking']"
                    routerDirection="root"
                    lines="none"
                    detail="true"
                    *ngIf="settingsSrv.currentCompanyFeatures.timeTracking"
                    >Zeiterfassung</ion-item
                >
                <ion-item
                    button
                    [routerLink]="['/calendar']"
                    routerDirection="root"
                    lines="none"
                    detail="true"
                    *ngIf="settingsSrv.currentUserRights.canSeeCalendar"
                    >Kalender</ion-item
                >

                <ion-item-divider></ion-item-divider>

                <ion-item button lines="none" [routerLink]="['/updates']" routerDirection="forward">
                    <fa-icon slot="start" icon="ballot"></fa-icon>
                    <ion-label>Updates</ion-label>
                </ion-item>
                <ion-item
                    button
                    lines="none"
                    [routerLink]="['/shop']"
                    routerDirection="forward"
                    *ngIf="settingsSrv.currentUserRights.canSeeShop"
                >
                    <fa-icon slot="start" icon="shopping-cart"></fa-icon>
                    <ion-label>Shop</ion-label>
                </ion-item>
                <ion-item button [routerLink]="['/settings']" routerDirection="forward" lines="none">
                    <fa-icon slot="start" icon="cogs"></fa-icon>
                    <ion-label>Einstellungen</ion-label>
                </ion-item>

                <ion-item-divider></ion-item-divider>

                <ion-item button lines="none" (click)="openSupportHelpCenter()">
                    <fa-icon slot="start" icon="user-headset"></fa-icon>
                    <ion-label>Hilfe-Center</ion-label>
                </ion-item>

                <ng-container *ngIf="showLogoutBtn">
                    <ion-item-divider></ion-item-divider>

                    <ion-item button lines="none" (click)="onLogout()">
                        <fa-icon slot="start" icon="sign-out"></fa-icon>
                        <ion-label> Abmelden </ion-label>
                    </ion-item>
                </ng-container>
            </ion-list>
        </ion-content>
        <ion-footer class="mobile-menu-footer">
            <ion-toolbar class="version-toolbar">
                <ion-label slot="start">Version</ion-label>
                <ion-label slot="end">
                    {{ versionInfo }} <ng-container *ngIf="utils.nativeVersionInfo">({{ utils.nativeVersionInfo }}) </ng-container>
                    <ion-badge mode="ios" color="warning" *ngIf="isBetaVersion">Beta</ion-badge>
                    <ion-badge mode="ios" color="danger" *ngIf="isDevVersion && !isBetaVersion">Dev</ion-badge>
                </ion-label>
            </ion-toolbar>
        </ion-footer>
    </ng-container>
</ion-menu>
