import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { UtilsService } from './../../utils/utils.service';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Attachment } from '../attachment.model';
import { AttachmentService } from '../attachment.service';
import { LoggerService } from '../../logger/logger.service';
import * as AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../notification/notification.service';
import { Share } from '@capacitor/share';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ActionSheetController, IonModal } from '@ionic/angular';
import { DpImageEditorProcessData } from '../image-editor/image-editor.component';
import write_blob from 'capacitor-blob-writer';
import { Directory } from '@capacitor/filesystem';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-attachment-view',
    templateUrl: './attachment-view.component.html',
    styleUrls: ['./attachment-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentViewComponent implements OnInit, OnChanges, OnDestroy {
    @Input() showImageWithoutStyles = false;
    @Input() attachment: Attachment | Record<string, unknown>; // the input attachment data (an anctual attachment instance or an object which can be instanciated as attachment here)
    @Input() limitedHeight: 'small' | 'medium' | 'large' = 'large';
    @Input() canDelete = false;
    @Input() canEdit = false;
    @Input() showAdditionalText = false;
    @Input() additionalText = '';
    @Input() showDateTime = true;
    @Input() allowNotices = false;
    @Input() showActions = true;
    @Input() overrideDownloadFileName = null; //used to override the default filename is attachment is downloaded from s3
    @Output() attachmentDeleted = new EventEmitter<Attachment>();
    @Output() attachmentNoticeChanged = new EventEmitter<Attachment>();
    @Output() attachmentUpdated = new EventEmitter<Attachment>();

    @ViewChild('fullImgPreview') fullImgPreview: IonModal;

    private s3: S3;
    private attachmentNoticeChangedDebouncer = new Subject<boolean>(); // used to debounce change events before submitting them to the attachmentNoticeChanged Output, so it doesnt emit as often
    private attachmentNoticeChangedDebouncerSubscription: Subscription; // used to debounce change events before submitting them to the attachmentNoticeChanged Output, so it doesnt emit as often
    private _attachment: Attachment; // the actual attachment object we use for further processing

    viewMode: 'image' | 'document';
    imageSrc = '';

    loading = true;
    error = false;
    noticeVisible = false;
    uniqueId;

    editing = false;

    constructor(
        public utils: UtilsService,
        private attachmentSrv: AttachmentService,
        private http: HttpClient,
        private logger: LoggerService,
        private notify: NotificationService,
        private cdRef: ChangeDetectorRef,
        private actionSheetCtrl: ActionSheetController
    ) {
        this.s3 = new AWS.S3({
            apiVersion: '2006-03-01',
            correctClockSkew: true,
        });

        this.uniqueId = this.utils.generateUuid();

        // used to debounce the attachmentNoticeChanged
        this.attachmentNoticeChangedDebouncerSubscription = this.attachmentNoticeChangedDebouncer
            .pipe(debounceTime(200))
            .subscribe((data) => this.attachmentNoticeChanged.emit(this.attachment as Attachment));
    }

    ngOnInit() {
        if (!this.attachment) {
            return;
        }
        this.loadAttachment();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.attachment &&
            !changes.attachment.isFirstChange() &&
            changes.attachment.currentValue._id != changes.attachment.previousValue._id
        ) {
            // only reload attachment if the id has changed, we dont need to reload attachments on every change detection
            this.loadAttachment();
        }
    }

    ngOnDestroy() {
        if (this.attachmentNoticeChangedDebouncerSubscription) {
            this.attachmentNoticeChangedDebouncerSubscription.unsubscribe();
        }
    }

    /**
     * Loads the attachment
     */
    async loadAttachment() {
        this.loading = true;
        this.error = false;
        this.imageSrc = '';

        if (!(this.attachment instanceof Attachment)) {
            // if attachment input is an attachment instance maybe we were given only an value (which can be mapped to an attachment)
            // it probably was not instanciated as an attachment model before to save change detection processing time and we can map it here
            this._attachment = new Attachment(this.attachment);
        } else {
            this._attachment = this.attachment;
        }

        if (this._attachment.isImage()) {
            this.viewMode = 'image';
            let loadedFrom = 'disk';

            // we want to display an image, try to load from disk, fall back to loading from s3
            try {
                this.imageSrc = await this.getImageUri();
            } catch (localFileReadError) {
                // file could not be read from local disk, try to get it from online storage by asking our api for a signed s3 url
                loadedFrom = 's3';
                try {
                    this.imageSrc = await this.getS3AttachmentUrl();
                } catch (onlineFileReadError) {
                    // could not get attachment from api, this is unrecoverable
                    this.logger.error('[ATTACHMENT VIEW] could not read attachment from ' + loadedFrom, onlineFileReadError);
                    this.error = true;
                    this.loading = false;
                }
            }

            this.logger.log('[ATTACHMENT VIEW] loaded image ' + this._attachment.filePath + ' from ' + loadedFrom);

            // if we loaded the file from s3, we will now save it to disk, so we dont need to download it, next time
            if (loadedFrom === 's3' && !this.error) {
                this.logger.log('[ATTACHMENT VIEW] trying to download file from s3 and save to local storage');
                try {
                    let fileDownload = await this.http.get(this.imageSrc, { responseType: 'blob' }).toPromise();
                    // cache the image locally but use the Ionic Filesystem in the browser as the blob_writer has issues
                    // when using base64 encoded data over a certain size and storing it in the browser 10+MB as of now
                    if (this.utils.isNative) {
                        // FIXME: replace the attachment function with write_blob instead of this temporary workaround
                        await write_blob({
                            path: environment.DISK_FOLDER_ATTACHMENTS + this._attachment.filePath,
                            directory: Directory.Data,
                            blob: fileDownload,
                            recursive: true,
                        });
                    } else {
                        // TODO: use fast_mode in web which in turn requires the loading process to create data URLs as this writes binary blobs to IndexedDB but require more handling on unload etc
                        // for the time being using our old methods works just as well

                        // await write_blob({
                        //     path: environment.DISK_FOLDER_ATTACHMENTS + this._attachment.filePath,
                        //     directory: Directory.Data,
                        //     blob: fileDownload,
                        //     fast_mode: true,
                        //     recursive: true,
                        // });

                        let fileData = await this.attachmentSrv.convertBlobToBase64Promise(fileDownload);
                        await this.attachmentSrv.saveAttachmentToDiskPromise(this._attachment.filePath, fileData as string);
                    }

                    this.logger.log('[ATTACHMENT VIEW] saved attachment ' + this._attachment.filePath + ' to local disk');

                    try {
                        this.logger.log('[ATTACHMENT VIEW] retry getting a path before falling back to web URLs');
                        // now try to get a native uri again
                        this.imageSrc = await this.getImageUri();
                        this.logger.log('[ATTACHMENT VIEW] loaded from disk again');
                    } catch (uriEx) {
                        this.logger.error('[ATTACHMENT VIEW] after downloading could not get uri path', uriEx);
                        this.utils.sentryCaptureException(uriEx);
                    }
                } catch (saveDiskError) {
                    this.logger.error('[ATTACHMENT VIEW] could not save attachment to disk, after downloading it from s3', saveDiskError);
                    this.utils.sentryCaptureException(saveDiskError);
                }
            }
            this.loading = false;
            this.cdRef.detectChanges();
        } else {
            this.viewMode = 'document';
            this.error = false;
            this.loading = false;
            this.cdRef.detectChanges();
        }
    }

    async getImageUri(): Promise<string> {
        let src = '';

        if (this.utils.isNative && (await this.checkFileExists())) {
            src = await this.getNativeUri();
        } else {
            // TODO: try to find a way to not load the images in base64
            let readLocalFile = await this.loadAttachmentFromDisk();
            src = readLocalFile.fileData as string;
        }

        return src;
    }

    /**
     * Opens the attachment in a new tab (used for documents only, images are displayed inline)
     */
    async openAttachment() {
        // open a window immediately when being in a browser to prevent the popup blocks of some browsers
        let windowReference = !this.utils.isNative ? window.open('about:blank') : undefined;

        try {
            if (this.utils.isNative) {
                if (await this.checkFileExists()) {
                    try {
                        let localUri = await this.getNativeUri(Capacitor.getPlatform() == 'android');

                        await Share.share({
                            url: localUri,
                            title: this._attachment.displayName,
                            dialogTitle: this._attachment.displayName,
                        });
                    } catch (uriErr) {
                        if (
                            (typeof uriErr.errorMessage == 'string' && uriErr.errorMessage?.indexOf('Share canceled') != -1) ||
                            (typeof uriErr.message == 'string' && uriErr.message?.indexOf('Share canceled') != -1) ||
                            (typeof uriErr == 'string' && uriErr?.indexOf('Share canceled') != -1)
                        ) {
                            this.logger.log('[ATTACHMENT VIEW] Share cancelled', uriErr);
                        } else {
                            this.logger.error('[ATTACHMENT VIEW] Failed getting URI or share handle for attachment', uriErr);
                            this.notify.error('Die Datei konnte nicht vom Dateisystem geladen werden. Bitte prüfe den Gerätespeicher.');
                            this.utils.sentryCaptureException(uriErr);
                        }
                    }
                } else {
                    let s3Src = await this.getS3AttachmentUrl();
                    await Browser.open({ url: s3Src });
                }
            } else {
                let documentSrc = await this.getS3AttachmentUrl();
                windowReference.location.href = documentSrc;
            }

            this.logger.log('[ATTACHMENT VIEW] loaded attachment');
        } catch (err) {
            // could not get attachment from api, this is unrecoverable
            this.logger.error('[ATTACHMENT VIEW] could not read attachment from s3', err);
            this.notify.error('Datei konnte nicht abgerufen werden. Internetverbindung prüfen.');
            this.utils.sentryCaptureException(err);

            windowReference?.close();
        }
    }

    async downloadAttachment() {
        // Careful - this function should only run in a web context
        try {
            let docUrl = await this.getS3AttachmentUrl(true);
            await Browser.open({ url: docUrl });

            this.logger.log('[ATTACHMENT VIEW] downloaded attachment');
        } catch (err) {
            // could not get attachment from api, this is unrecoverable
            this.logger.error('[ATTACHMENT VIEW] could not download attachment from s3', err);
            this.notify.error('Dateidownload konnte nicht abgerufen werden. Internetverbindung prüfen.');
            this.utils.sentryCaptureException(err);
        }
    }

    /**
     * Tries to load the attachment content from disk
     * Returns a promise
     */
    private loadAttachmentFromDisk() {
        return this.attachmentSrv.readAttachmentFromDisk(this._attachment, true).toPromise();
    }

    private checkFileExists() {
        return this.attachmentSrv.checkIfAttachmentFileExists(this._attachment);
    }

    /**
     * Retrieve URI for local file
     * @param uriRemap defaults to true which leads to a Capacitor URI remap to its hybrid URLs when running native. No effect for web contexts. Setting it false prevents such remapping and gets plain file:// URIs
     */
    private getNativeUri(uriRemap = true) {
        return this.attachmentSrv.getUriForAttachment(this._attachment, uriRemap);
    }

    /**
     * Tries to load the attachment from s3 storage. Returns a signed url to the attachment
     * Returns a promise
     */
    private getS3AttachmentUrl(download = false) {
        if (download && this.overrideDownloadFileName != null) {
            download = this.overrideDownloadFileName;
        }
        return this.attachmentSrv.getSignedS3UrlForAttachmentFilePath(this._attachment.filePath, download);
    }

    /**
     * Triggered when the user presses the attachment delete button
     */
    async onDeleteAttachment() {
        try {
            await this.attachmentSrv.deleteFileFromDisk(this._attachment.filePath, 'all');
            await this.closeImgPreview();
        } catch (deleteErr) {
            this.logger.error('[ATTACHMENT VIEW] could not delete file from disk', deleteErr, this._attachment);
            this.utils.sentryCaptureException(deleteErr);
        }
        this.attachmentDeleted.emit(this._attachment);
    }

    async onOpenImgPreview() {
        await this.fullImgPreview.present();
        this.cdRef.markForCheck();
    }

    async closeImgPreview() {
        await this.fullImgPreview.dismiss();
    }

    onDialogStateChanged(isOpen: boolean) {
        if (isOpen) {
            // Trigger change detection early for pre rendering items on faster devices...
            // setTimeout((_) => {
            //     this.cdRef.detectChanges();
            // }, 200);
        } else {
            this.editing = false;
        }
    }

    /**
     * Triggered when a notice changes, emits an event
     * @param event
     */
    onNoticeChanged(event) {
        this.attachmentNoticeChangedDebouncer.next(true); // emit debouncer event so we dont spam outputs
    }

    /**
     * Handle attachment action buttons
     */
    async handleAttachmentActionButtons() {
        let actionSheetOptions = {
            header: 'Datei-Optionen',
            buttons: [
                {
                    text: 'Öffnen',
                    handler: () => {
                        if (this.viewMode === 'document') {
                            this.openAttachment();
                        } else {
                            this.onOpenImgPreview();
                        }
                    },
                },
                {
                    text: 'Löschen',
                    role: 'destructive',
                    handler: () => {
                        this.onDeleteAttachment();
                    },
                },
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                },
            ],
        };

        if (!this.canDelete) {
            actionSheetOptions.buttons.splice(1, 1);
        }

        const actionSheet = await this.actionSheetCtrl.create(actionSheetOptions);
        await actionSheet.present();
    }

    async startEditor() {
        this.editing = !this.editing;
    }

    handleLoad($event: any): void {
        this.logger.log('[ATTACHMENT VIEW] editor loaded');
    }

    handleEditorCancel() {
        this.logger.log('[ATTACHMENT VIEW] editor cancelled');
        this.editing = false;
        this.cdRef.detectChanges();
    }

    async handleProcess($event: DpImageEditorProcessData): Promise<void> {
        if ($event.changed) {
            this.logger.log('[ATTACHMENT VIEW] image is being processed and updated');
            // TODO: replace the blob methods with write_blob calls
            let newImageData = await this.attachmentSrv.convertBlobToBase64Promise($event.editorData.dest);
            this._attachment = this.updateFilePaths(this._attachment);
            await this.attachmentSrv.saveAttachmentToDiskPromise(this._attachment.filePath, newImageData as string, true);

            // create a copy of the obj to prevent overrides from the bindings, the emitter tells the rest of the app that the image changed
            // but the ui does not reflect the change immediately, so we do that ourselves
            this.attachment = new Attachment(this._attachment);
            await this.loadAttachment();

            // FIXME: solve the Close the ion-modal together with updating the containing job element.
            // If we keep the dialog open the refresh of the job form and the resulting component updates from angular
            // end up killing our ion-modal instances and we can't close them afterwards
            // If we can resolve this issue, we could stay in teh dialog which would result in a bit smoother experience
            await this.closeImgPreview();
            this.attachmentUpdated.emit(this._attachment);
        }
        this.editing = false;
        this.cdRef.detectChanges();
    }

    private updateFilePaths(data: Attachment): Attachment {
        let path = '';
        let filename = Date.now() + '-edit';

        // Add extension based on mimetype
        switch (data.fileMimeType) {
            case 'image/png':
                filename = filename + '.png';
                break;
            case 'image/jpg':
            default:
                filename = filename + '.jpg';
                break;
        }

        if (data.filePath.indexOf(data.fileName) != -1) {
            // this should be the default case where the filename property is in sync with the path
            path = data.filePath.slice(0, data.filePath.length - data.fileName.length);
        } else {
            // fileName property does not match path so we correct that
            path = data.filePath.slice(0, data.filePath.lastIndexOf('/'));
        }

        // update the attachment data and return it
        data.fileName = filename;
        data.filePath = this.utils.addTrailingSlash(path) + filename;
        data.updatedAt = Date.now();

        return data;
    }
}
