<div class="icon-display-wrap">
    <ng-container *ngIf="iconForm != null">
        <div class="icon-select-action" (click)="triggerIconPopover($event, iconPopover)">
            <div class="icon-name" *ngIf="iconForm.get('iconName').value != null; else namePlaceholder">Symbol:</div>
            <ng-template #namePlaceholder>
                <div class="icon-name-placeholder">Symbol</div>
            </ng-template>
            <div class="icon-display" *ngIf="iconForm.value != null">
                <app-field-icon-view [icon]="iconForm.value" [limitSize]="'25px'" [lazyLoad]="false" class="dp-mr-s"></app-field-icon-view>
            </div>
        </div>
        <ion-button class="icon-delete" fill="clear" color="dark" size="small" (click)="onSelectIcon(null, iconPopover)">
            <fa-icon icon="times" slot="icon-only"></fa-icon>
        </ion-button>
        <ion-popover
            reference="event"
            side="bottom"
            alignment="center"
            size="auto"
            #iconPopover
            (ionPopoverDidDismiss)="onIconPopoverDismissed()"
        >
            <ng-template>
                <ion-header>
                    <ion-toolbar color="none">
                        <ion-searchbar
                            placeholder="Suchen"
                            debounce="100"
                            (ionInput)="filterIcons($event.detail.value)"
                            #iconSearchbar
                        ></ion-searchbar>
                    </ion-toolbar>
                </ion-header>
                <ion-content>
                    <div class="icon-list">
                        <div class="single-icon" *ngFor="let icon of currentDisplayedIcons" (click)="onSelectIcon(icon, iconPopover)">
                            <div class="single-icon-display">
                                <app-field-icon-view [icon]="icon" [lazyLoad]="false"></app-field-icon-view>
                            </div>
                            <div class="single-icon-name">
                                {{ icon.displayName }}
                            </div>
                        </div>
                    </div>
                </ion-content>
            </ng-template>
        </ion-popover>
    </ng-container>
</div>
