import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { IonPopover, IonSearchbar } from '@ionic/angular';
import { DokuPitIcon } from '../../../../assets/icons';

@Component({
    selector: 'app-field-icon-select',
    templateUrl: './field-icon-select.component.html',
    styleUrls: ['./field-icon-select.component.scss'],
})
export class FieldIconSelectComponent {
    @Input() iconForm: UntypedFormGroup;
    @ViewChild('iconSearchbar') iconSearchbar: IonSearchbar;

    //@Input() possibleIcons = [];
    _possibleIcons = [];
    @Input()
    set possibleIcons(val: any[]) {
        this._possibleIcons = val;
        this.currentDisplayedIcons = val; // we set it twice so we can filter our local variable
    }
    get possibleIcons() {
        return this._possibleIcons;
    }
    currentDisplayedIcons = this.possibleIcons;

    @Output() selectedIcon = new EventEmitter();

    // icon filter

    selectionPopoverOpen = false;

    constructor() {}
    /**
     * Called when an user selects an icon
     * @param icon the selected icon
     */
    onSelectIcon(icon: DokuPitIcon, popover: IonPopover) {
        if (this.iconForm != null) {
            if (icon == null) {
                this.iconForm.get('iconName').setValue(null);
                this.iconForm.get('iconType').setValue(null);
            } else {
                this.iconForm.get('iconName').setValue(icon.iconName);
                if ((<string>icon.iconName).startsWith('assets/icon/colorable')) {
                    this.iconForm.get('iconType').setValue('dp');
                } else {
                    this.iconForm.get('iconType').setValue((<string>icon.iconName).startsWith('assets/') ? 'dpimg' : 'fa');
                }
            }
        }
        this.selectedIcon.emit(icon.iconName);
        popover.dismiss();
        this.selectionPopoverOpen = false;
    }

    /**
     * Used to trigger the icon selection popover
     * @param clickEvent
     * @param popover
     */
    triggerIconPopover(clickEvent, popover: IonPopover) {
        this.selectionPopoverOpen = !this.selectionPopoverOpen;
        if (this.selectionPopoverOpen) {
            popover.present(clickEvent).then(() => this.iconSearchbar.setFocus());
        } else {
            popover.dismiss();
        }
    }

    /**
     * Triggered when the icon popover is dismissed
     */
    onIconPopoverDismissed() {
        this.currentDisplayedIcons = this.possibleIcons; // reset icon list
        this.selectionPopoverOpen = false;
    }

    /**
     * Called from searchbar to filter current displayed icons
     * @param searchString
     */
    filterIcons(searchString: string) {
        if (searchString == null || searchString === '') {
            this.currentDisplayedIcons = this.possibleIcons; // reset
        } else {
            this.currentDisplayedIcons = this._possibleIcons.filter((x) =>
                x.displayName?.toLowerCase().includes(searchString.toLowerCase())
            );
        }
    }
}
