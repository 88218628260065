import { NotificationService } from './../notification/notification.service';
import { UtilsService } from './../utils/utils.service';
import { NavigationEnd, Router } from '@angular/router';
import { ProjectService } from './../../projects/project.service';
import { UserIdleStatus, AuthService } from './../../auth/auth.service';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { SyncService } from '../sync/sync.service';
import { LoggerService } from '../logger/logger.service';
import { MenuController } from '@ionic/angular';
import { AuthInfo } from '../../auth/auth-info.interface';
import { Project } from 'src/app/projects/project.model';
import { debounceTime, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../settings/settings.service';
import { environment } from 'src/environments/environment';

const RESELLER_LOGOS = [
    {
        resellerId: 'a68cb925-cb3b-4120-9600-c57bb03c1456',
        resellerLogoSrc: '../../../assets/img/reseller/logo-wego.png',
        dpLogoSrc: '../../../assets/img/dokupit-logo-black.svg',
    },
];

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    loggedIn = false;
    currentSyncStatus: false | 'upstream' | 'downstream' | 'cleanup' = false;
    userIdleStatus: UserIdleStatus = UserIdleStatus.ACTIVE;

    authInfo: AuthInfo;

    greeting = '';

    isAdmin = false;
    isViewer = false;
    currentProject: Project = undefined;
    mobileTitle = 'Projektübersicht';
    projectRouteActive = false;

    isBetaVersion = this.utils.isBetaVersion;
    isDevVersion = !environment.production;
    versionInfo = this.utils.versionInfo;

    connected = false;
    disable = false;

    currentResellerLogoInfo = null;
    private resellerLogoSwapIntervalId = null; // the interval id to refresh the session every few minutes
    private currentVisibleLogo: 'dp' | 'reseller' = 'dp';

    private connectionSub: Subscription;

    constructor(
        private menuCtrl: MenuController,
        private authSrv: AuthService,
        private syncSrv: SyncService,
        private logger: LoggerService,
        private projectSrv: ProjectService,
        private router: Router,
        public utils: UtilsService,
        private zone: NgZone,
        private notifySvc: NotificationService,
        public settingsSrv: SettingsService,
        private cdRef: ChangeDetectorRef
    ) {
        this.projectSrv.currentProject$.subscribe((p) => {
            this.currentProject = p;
        });

        this.connected = this.utils.isOnline;
        this.connectionSub = this.utils.onlineChanged.pipe(debounceTime(500)).subscribe((currentlyConnected) => {
            this.zone.run((_) => {
                this.connected = currentlyConnected;
            });
        });
    }

    ngOnInit(): void {
        // subscribe to login status
        this.authSrv.loginStatusChanged$.subscribe((loginStatus) => {
            this.loggedIn = loginStatus === true;

            // handle reseller logo
            if (this.loggedIn) {
                if (this.authSrv.resellerId != null) {
                    this.currentResellerLogoInfo = RESELLER_LOGOS.find((x) => x.resellerId === this.authSrv.resellerId);
                    if (this.currentResellerLogoInfo != null) {
                        this.cdRef.detectChanges(); // used to make logo visible in html before changing its src here
                        this.resellerLogoSwap(); // initial swap
                        // set interval to swap logo with reseller logo
                        if (this.resellerLogoSwapIntervalId == null) {
                            this.resellerLogoSwapIntervalId = setInterval(() => {
                                this.resellerLogoSwap();
                            }, 30000);
                        }
                    } else {
                        this.clearLogoSwapInterval();
                    }
                }
            }
        });

        // get sync status changes
        this.syncSrv.syncRunning$.pipe(debounceTime(200)).subscribe((syncStatus) => {
            this.logger.log('[HEADER] sync status changed', syncStatus);
            this.currentSyncStatus = syncStatus;
        });

        // get auth info changes
        this.authSrv.authInfoChanged$.subscribe((authInfo) => {
            this.authInfo = authInfo;
            if (this.authInfo != null) {
                this.isAdmin = this.authSrv.isAdminLicense;
                this.isViewer = this.authSrv.isViewerLicense;
            }
        });

        // get idle status
        this.authSrv.userIdleStatusChanged.subscribe((idleStatus) => {
            this.userIdleStatus = idleStatus;
        });

        // react to locking tabs for blocking navigation
        this.utils.tabLock.subscribe((lockState) => {
            this.disable = !!lockState;
        });

        // set greeting
        let date = new Date();
        let hour = date.getHours();
        if (hour >= 4 && hour <= 9) {
            this.greeting = 'Guten Morgen';
        } else if (hour >= 18 && hour <= 23) {
            this.greeting = 'Guten Abend';
        } else {
            this.greeting = 'Hallo';
        }

        // Update our header for the mobile view
        this.router.events.pipe(filter((ev: any) => ev instanceof NavigationEnd)).subscribe((ev: NavigationEnd) => {
            if (ev.url.startsWith('/project')) {
                this.projectRouteActive = true;
            } else {
                this.projectRouteActive = false;

                if (ev.url === '' || ev.url === '/') {
                    this.mobileTitle = 'Projektübersicht';
                } else if (ev.url.indexOf('/service-groups') !== -1) {
                    this.mobileTitle = 'Vorlagen';
                } else if (ev.url === '/settings') {
                    this.mobileTitle = 'Einstellungen';
                } else {
                    this.mobileTitle = '';
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.connectionSub) {
            this.connectionSub.unsubscribe();
        }
        this.clearLogoSwapInterval();
    }

    /**
     * Swaps out our current dp logo with our current reseller logo on a timer
     */
    async resellerLogoSwap() {
        let logo = document.getElementById('app-logo');
        logo.classList.remove('dp-logo-fade-in');
        logo.classList.add('dp-logo-fade-out');
        setTimeout(() => {
            // swap out our img src after fading out our image (1 sec)
            if (this.currentVisibleLogo === 'dp') {
                (<any>logo).src = this.currentResellerLogoInfo.resellerLogoSrc;
                this.currentVisibleLogo = 'reseller';
            } else if (this.currentVisibleLogo === 'reseller') {
                (<any>logo).src = this.currentResellerLogoInfo.dpLogoSrc;
                this.currentVisibleLogo = 'dp';
            }

            // fade in our image again after 1 second
            logo.classList.remove('dp-logo-fade-out');
            logo.classList.add('dp-logo-fade-in');
        }, 800);
    }

    /**
     * Clears logo swap timer
     */
    clearLogoSwapInterval() {
        if (this.resellerLogoSwapIntervalId != null) {
            clearInterval(this.resellerLogoSwapIntervalId);
            this.resellerLogoSwapIntervalId = null;
        }
    }

    /**
     * Triggered when the user opens or closes the HEADER
     */
    onToggleMobileMenu(): void {
        this.menuCtrl.enable(true, 'mobile-main-menu');
        this.menuCtrl.toggle('mobile-main-menu');
    }

    /**
     * Triggered when the user clicks the logout button
     */
    onLogout(): void {
        this.syncSrv.hasUnsyncedLocalData().subscribe(
            async (hasLocalData) => {
                if (hasLocalData) {
                    this.logger.log('[HEADER] Local data remains. Asking user whether to proceed.');
                    try {
                        await this.notifySvc.confirm(
                            'Es befinden sich Daten auf dem Gerät, die noch nicht hochgeladen wurden. Wenn du fortsetzt werden diese unwiderruflich gelöscht!',
                            'Datenabgleich unvollständig',
                            'Abmelden und löschen',
                            'Abbrechen',
                            true
                        );

                        this.authSrv.logout();
                    } catch (promptCancel) {
                        this.logger.log('[HEADER] User cancelled on unsynced local data warning');
                    }
                } else {
                    this.logger.log('[HEADER] No local data remains. Starting logout...');
                    this.authSrv.logout();
                }
            },
            (err) => {
                this.logger.error('[HEADER] Error while checking remaining local data');
                this.notifySvc.error(
                    'Beim Prüfen des Abmeldevorgangs ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
                    'Fehler',
                    undefined,
                    false
                );
                this.utils.sentryCaptureException(err);
            }
        );
    }
}
