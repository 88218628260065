import { UtilsService } from './../utils/utils.service';
import { LoggerService } from './../logger/logger.service';
import { BaseSelectItem } from './base-select-item.interface';
import { IonInput, IonModal, IonSearchbar, Platform } from '@ionic/angular';
import {
    EventEmitter,
    AfterViewInit,
    OnInit,
    OnDestroy,
    Component,
    ElementRef,
    ViewChild,
    Output,
    Input,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from '@angular/core';
import * as uuid from 'uuid';

@Component({
    selector: 'app-select-drawer',
    templateUrl: './select-drawer.component.html',
    styleUrls: ['./select-drawer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDrawerComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('selectDrawerModal') selectDrawerModal: IonModal;
    @ViewChild('drawerSearch') drawerSearch?: IonSearchbar;
    @ViewChild('customItemInput', { static: false }) customItemInput: IonInput;
    // eslint-disable-next-line @angular-eslint/no-output-rename
    @Output('openStateChanged') openState: EventEmitter<boolean> = new EventEmitter();
    // Emits single value per default or array of selected values when multiple = true
    @Output() onChange: EventEmitter<BaseSelectItem | Array<BaseSelectItem>> = new EventEmitter();

    @Input() appendTo: 'parent' | 'ion-app' | 'body' = 'parent';
    @Input() multiple = false;
    @Input() allowEmpty = false;
    @Input() canAddItems = false;
    @Input() value: BaseSelectItem | Array<BaseSelectItem> = undefined;
    @Input() set items(value: Array<BaseSelectItem>) {
        this._items = value;
        this.resetFilter();
        this.cdRef.detectChanges();
    }
    get items() {
        return this._items;
    }
    _items: Array<BaseSelectItem> = [];

    displayedData: Array<BaseSelectItem> = [];

    platformClass = '';
    isOpen = false;
    openHeight = 0;
    addingCustomItem = false;

    // Holds reference to this components DOM element
    private element: any;
    private keyboardOpen = false;
    private currentTransitionStyle = '';

    private keyboardDidShowFn = (e) => {
        this.keyboardOpen = true;
        // Do something with the keyboard height such as translating an input above the keyboard.
        // if (this.drawer && this.isOpen) {
        //     this.drawer.nativeElement.classList.add('keyboard-open');
        //     if (this.isOpen) {
        //         this.setOpenPosition();
        //     }
        // }
        this.selectDrawerModal.setCurrentBreakpoint(1);
    };
    private keyboardDidHideFn = () => {
        this.keyboardOpen = false;
        // Move input back to original location
        // if (this.drawer && this.isOpen) {
        //     this.drawer.nativeElement.classList.remove('keyboard-open');
        //     if (this.isOpen) {
        //         this.setOpenPosition();
        //     }
        // }
    };

    constructor(
        private plt: Platform,
        private el: ElementRef,
        private cdRef: ChangeDetectorRef,
        private logger: LoggerService,
        public utils: UtilsService
    ) {
        this.element = this.el.nativeElement;

        // The ionic platform events somehow trigger immediately one after another
        // seems to be a bug
        window.addEventListener('keyboardDidShow', this.keyboardDidShowFn);
        window.addEventListener('keyboardDidHide', this.keyboardDidHideFn);
    }

    async ngOnInit() {
        this.platformClass = this.plt.is('ios') ? 'ios' : 'md';

        this.resetFilter();

        //if (this.appendTo != 'parent') {
        // move element to different container
        //this.selectDrawerModal.presentingElement = document.getElementsByTagName(this.appendTo)[0];
        //.appendChild(this.element);
        //}

        this.selectDrawerModal.didPresent.subscribe(() => {
            this.isOpen = true;
            this.openState.emit(this.isOpen);

            // automatically set keyboard focus to the searchbar when running on desktop
            if (!this.utils.isPhone && !this.utils.isTablet) {
                setTimeout((_) => {
                    this.drawerSearch?.setFocus();
                }, 100);
            }
        });
        this.selectDrawerModal.didDismiss.subscribe(() => {
            this.isOpen = false;
            this.openState.emit(this.isOpen);
        });
    }

    async ngAfterViewInit() {
        // this.logger.log("-- plth -- " + this.utils.fullWindowHeight);
        this.openHeight = (this.utils.fullWindowHeight / 100) * 50;

        this.cdRef.detectChanges();
    }

    async ngOnDestroy() {
        this.element?.remove();
        window.removeEventListener('keyboardDidShow', this.keyboardDidShowFn);
        window.removeEventListener('keyboardDidHide', this.keyboardDidHideFn);
    }

    async toggleDrawer() {
        // const drawer = this.drawer.nativeElement;
        // this.openState.emit(!this.isOpen);

        if (this.isOpen) {
            // drawer.classList.remove('keyboard-open');
            // this.currentTransitionStyle = 'transform .2s ease-out';
            // drawer.style.transition = this.currentTransitionStyle;
            // drawer.style.transform = '';
            // this.isOpen = false;
            if (this.drawerSearch) {
                this.drawerSearch.value = '';
            }
            this.resetFilter();
            this.addingCustomItem = false;
            await this.selectDrawerModal.dismiss();
        } else {
            // this.currentTransitionStyle = 'transform .2s ease-out';
            // drawer.style.transition = this.currentTransitionStyle;
            this.setOpenPosition();
            // this.isOpen = true;
            await this.selectDrawerModal.present();
        }

        this.cdRef.detectChanges();
    }

    filterData(filterValue: string) {
        if (filterValue == '' || filterValue == undefined) {
            this.resetFilter();
            return;
        }

        // this.logger.log("FILTER", filterValue);
        this.displayedData = this.items.filter((i) => i.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1);

        this.cdRef.detectChanges();
    }

    resetFilter() {
        // Prevent accidental edits by making a copy
        if (this.items) {
            this.displayedData = [...this.items];
        }

        this.cdRef.detectChanges();
    }

    // Emits only in single mode
    onItemSelected(item) {
        if (this.multiple) {
            // To be sure....
            this.logger.warn('[DRAWER] triggering itemSelected in multimode is not supported!');
            return;
        }

        this.onChange.emit(item);
        setTimeout((_) => {
            this.toggleDrawer();
        }, 200);
    }

    // Helper function to determine a checkbox is active
    isItemChecked(item: BaseSelectItem) {
        if (!this.value) return false;

        if (Array.isArray(this.value)) {
            return this.value.findIndex((v) => v._id == item._id) !== -1;
        } else {
            return this.value._id == item._id;
        }
    }

    // Handling the checkbox events for selecting or deselecting boxes
    onItemChecked(isChecked: boolean, item: BaseSelectItem) {
        if (isChecked) {
            if (Array.isArray(this.value)) {
                if (this.value.findIndex((v) => v._id == item._id) == -1) {
                    this.value.push(item);
                } else {
                    // nothing to do
                }
            } else {
                if (this.value?._id == item._id) {
                    // convert single value to 'correct' array
                    this.value = [this.value];
                } else {
                    this.value = [item];
                }
            }
        } else {
            if (Array.isArray(this.value)) {
                this.value = this.value.filter((v) => v._id !== item._id);
            } else {
                this.value = [];
            }
        }
        this.logger.log('[DRAWER] Item checked', isChecked, item);
    }

    onConfirmSelection() {
        this.onChange.emit(this.value);
        this.toggleDrawer();
        this.logger.log('[DRAWER] Multi selection confirmed', this.value);
    }

    onClearSelection() {
        this.onChange.emit(undefined);
        this.toggleDrawer();
        this.logger.log('[DRAWER] Clear selection confirmed');
    }

    async onAddCustomItem() {
        this.addingCustomItem = true;
        setTimeout((_) => {
            this.customItemInput?.setFocus();
        }, 200);
    }

    onConfirmCustomItem(ev) {
        ev.stopPropagation();
        this.addingCustomItem = false;

        // Keep out unecessary whitespace
        if (this.customItemInput.value) {
            this.customItemInput.value = this.customItemInput.value.toString().trim();
        }

        if (this.customItemInput.value != '') {
            if (this.multiple) {
                // Check for existing value like this
                let existingIndex = this.items.findIndex((i) => i.name == this.customItemInput.value);
                if (existingIndex == -1) {
                    let newItem = {
                        _id: uuid.v4(),
                        name: this.customItemInput.value as string,
                        type: 'manual-job-field',
                    };
                    this.items = this.items.concat(newItem);
                    this.onItemChecked(true, newItem);
                } else {
                    this.onItemChecked(true, this.items[existingIndex]);
                }
            } else {
                this.value = {
                    _id: uuid.v4(),
                    name: this.customItemInput.value as string,
                    type: 'manual-job-field',
                };
                this.onItemSelected(this.value);
            }
        }

        this.cdRef.detectChanges();
    }

    searchbarClicked() {
        this.selectDrawerModal.setCurrentBreakpoint(1);
    }

    private setOpenPosition(overrideHeight?: number) {
        // const drawer = this.drawer.nativeElement;
        // if (this.keyboardOpen) {
        //     drawer.style.transition = '';
        //     drawer.style.transform = `translateY(${-(overrideHeight || 0)}px)`;
        // } else {
        //     drawer.style.transition = this.currentTransitionStyle;
        //     drawer.style.transform = `translateY(${-(overrideHeight || this.openHeight)}px)`;
        // }
    }
}
