import { UtilsService } from './../utils/utils.service';
import { ApplicationRef, booleanAttribute, Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import tippy, { Props, roundArrow } from 'tippy.js';

@Directive({
    selector: '[dpTooltip]',
})
export class TooltipDirective implements OnInit {
    @Input() tooltipContent: string | TemplateRef<any>;
    @Input() tooltipTrigger: 'click' | 'mouseenter focus' | 'click mouseenter focus' | 'manual' = 'mouseenter focus'; //mouseenter and focus is the default hover but also handles keyboard navigation
    @Input() tooltipTheme: 'light' | 'dark' = 'dark';
    @Input() tooltipPosition: 'top' | 'left' | 'right' | 'bottom' = 'top';
    @Input({ transform: booleanAttribute }) tooltipInteractive = false;
    @Input({ transform: booleanAttribute }) hideTooltipAfterInteraction = false;

    constructor(
        private el: ElementRef,
        private appRef: ApplicationRef,
        private utils: UtilsService
    ) {}

    ngOnInit() {
        if (!this.tooltipContent) {
            return;
        }

        // handle content of tooltip
        let content;
        if (typeof this.tooltipContent === 'string') {
            content = this.tooltipContent;
        } else {
            // create a view from the given template ref
            let viewRef = this.tooltipContent.createEmbeddedView(null);
            this.appRef.attachView(viewRef);
            // loop over all html elements in created view and add them to a new popup element
            let popupElement = document.createElement('div');
            for (let node of viewRef.rootNodes) {
                popupElement.append(node);
            }
            content = popupElement;
        }

        if ((this.utils.isPhone || this.utils.isTablet) && !this.tooltipTrigger.includes('click')) {
            this.tooltipTrigger = 'manual'; // this is to never show hover-tooltips on phones/tablets
        }

        // TODO DETACH AND DESTROY CREATED VIEW AND popupElement WHEN THE TOOLTIP IS DESTROYED - POSSIBLE MEMORY LEAK

        let tippyProps: Partial<Props> = {
            allowHTML: true,
            appendTo: document.getElementById('dp-tooltip-holder'),
            animation: 'fade',
            delay: 50,
            theme: this.tooltipTheme,
            trigger: this.tooltipTrigger,
            interactive: this.tooltipInteractive,
            arrow: roundArrow,
            content: content,
            placement: this.tooltipPosition,
            hideOnClick: true,
        };

        if (this.hideTooltipAfterInteraction) {
            tippyProps.onShown = (instance) => {
                document.querySelector('[data-tippy-root]').addEventListener('click', (event) => {
                    instance.hide();
                });
            };
        }

        // init tippy
        tippy(this.el.nativeElement, tippyProps);
    }
}
