import { BuildingPlan } from './../../projects/project-details/project-building-plans/building-plan.model';
import { PlanAttachment } from './../../projects/project-details/project-building-plans/plan-attachment.model';
import { Injectable } from '@angular/core';
import { Attachment } from './attachment.model';
import { LoggerService } from '../logger/logger.service';
import { environment } from '../../../environments/environment';
import { from, Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UtilsService } from '../utils/utils.service';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';

import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { UnzipResult, ZipPlugin } from 'capacitor-zip';

@Injectable({
    providedIn: 'root',
})
export class AttachmentService {
    constructor(
        private http: HttpClient,
        private logger: LoggerService,
        private utils: UtilsService,
        private storage: Storage
    ) {
        this.initStorage();
    }

    async initStorage() {
        // If using a custom driver:
        // await this.storage.defineDriver(MyCustomDriver)
        await this.storage.create();
    }

    /**
     * Initializes the directories which are needed for file storage.
     * Returns a promise which resolves when all directories were created or already exist
     */
    initDirectories() {
        return Promise.all([
            Filesystem.mkdir({
                path: environment.DISK_FOLDER_ATTACHMENTS,
                directory: Directory.Data,
                recursive: true,
            }).catch(() => {
                this.logger.log('[ATTACHMENT SERVICE] dont need to create attachments cache directory. already exists');
            }),
            Filesystem.mkdir({
                path: environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD,
                directory: Directory.Data,
                recursive: true,
            }).catch(() => {
                this.logger.log('[ATTACHMENT SERVICE] dont need to create attachments upload directory. already exists');
            }),
        ]);
    }

    /**
     * Saves the given attachment to device disk (used for caching attachments to prevent repeated downloading)
     * Returns the response of the ionic fileSystem request as promise
     * @param filePath name of the file (can be a path)
     * @param fileContent base64 content of file
     * @param sync if true, the attachment will be uploaded to s3 storage on next upstream sync
     */
    saveAttachmentToDiskPromise(filePath: string, fileContent: string, sync = false) {
        return new Promise(async (resolve, reject) => {
            try {
                let targetPath = environment.DISK_FOLDER_ATTACHMENTS + filePath;
                let targetDir = targetPath.substring(0, targetPath.lastIndexOf('/') + 1);
                await this.createDirectoriesIfNotExist(targetDir, Directory.Data);

                let writeRes = await Filesystem.writeFile({
                    path: environment.DISK_FOLDER_ATTACHMENTS + filePath,
                    data: fileContent,
                    directory: Directory.Data,
                    recursive: false,
                });
                this.logger.log('[ATTACHMENT SERVICE] saved to cache', filePath);
                if (sync) {
                    await this.copyAttachmentToSyncFolder(filePath);
                    this.logger.log('[ATTACHMENT SERVICE] saved to upload dir', filePath);
                }
                resolve(writeRes);
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Copies attachment at given path to attachments-upload folder which will be synced on next upstream-sync
     * @param attachmentPath
     */
    async copyAttachmentToSyncFolder(attachmentPath: string) {
        let copyFilePath = environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD + attachmentPath;
        let copyBasePath = copyFilePath.substring(0, copyFilePath.lastIndexOf('/') + 1);

        await this.createDirectoriesIfNotExist(copyBasePath, Directory.Data);
        return await Filesystem.copy({
            from: environment.DISK_FOLDER_ATTACHMENTS + attachmentPath,
            to: copyFilePath,
            directory: Directory.Data,
        });
    }

    /**
     * Reads given attachment from device disk. Use the readFileFromDisk function instead if you want to read by whole fileUri
     * Returns an observable which resolves to an object with properties fileName and data
     * @param file attachment instance or filename
     * @param asDataUrl if true the file content will be returned with the typical data:base64 prefix
     */
    readAttachmentFromDisk(file: Attachment | string, asDataUrl = false, fromUploadCache = false) {
        let filePath = typeof file === 'string' ? file : file.filePath;
        let folderPath = fromUploadCache ? environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD : environment.DISK_FOLDER_ATTACHMENTS;

        return from(
            // read file from local cache folder
            Filesystem.readFile({
                path: folderPath + filePath,
                directory: Directory.Data,
            })
        ).pipe(
            map((fileReadResult) => {
                // if we want the file content with the typical base64 prefix we will add it
                if (asDataUrl) {
                    // TODO: https://www.npmjs.com/package/capacitor-blob-writer -> using fast_mode requires extra handling, but this needs thorough testing if everything works as expected, as well as unloading the urls
                    // if (Capacitor.getPlatform() === 'web') {
                    //     fileReadResult.data = URL.createObjectURL(fileReadResult.data as Blob);
                    // } else {
                    //     let contentType = this.utils.getMimeTypeForFileEnding(this.utils.getFileEnding(filePath));
                    //     fileReadResult.data = 'data:' + contentType + ';base64,' + fileReadResult.data;
                    // }

                    let contentType = this.utils.getMimeTypeForFileEnding(this.utils.getFileEnding(filePath));
                    fileReadResult.data = 'data:' + contentType + ';base64,' + fileReadResult.data;
                }
                return {
                    filePath: filePath,
                    fileData: fileReadResult.data,
                };
            })
        );
    }

    /**
     * @description Native only. Takes attachment or path relative to Capacitor filesystem directory and converts it to a blob using fetch API. Due to how Capacitor's filesystem works in a browser, this only works on native URIs
     * @param file attachment object or file path relative to Capacitor's filesystem Directory structs
     * @returns Observable which emits the Blob to the file
     */
    getBlobForAttachmentToUpload(file: Attachment | string, fromUploadCache = true): Observable<Blob> {
        let filePath = typeof file === 'string' ? file : file.filePath;
        let folderPath = fromUploadCache ? environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD : environment.DISK_FOLDER_ATTACHMENTS;

        return from(this.getFileUri(folderPath + filePath, Directory.Data)).pipe(
            switchMap((fileUri) => {
                return from(fetch(fileUri));
            }),
            switchMap((fetchResponse) => {
                if (!fetchResponse.ok) {
                    return throwError(fetchResponse.statusText);
                }

                return from(fetchResponse.blob());
            })
        );
    }

    /**
     * @description Should only be used on native instances due to our filesystem structure. Returns a ReadableStream for the file to the given attachment or path using fetch API
     * @param file attachment object or path to file, gets appended with our upload data dir and then passed to fetch
     * @returns Promise of ReadableStream
     */
    getReadStreamForAttachmentToUpload(file: Attachment | string) {
        let filePath = typeof file === 'string' ? file : file.filePath;

        return from(this.getFileUri(environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD + filePath, Directory.Data)).pipe(
            switchMap((fileUri) => {
                return from(fetch(fileUri));
            }),
            switchMap((fetchResponse) => {
                if (!fetchResponse.ok) {
                    return throwError(fetchResponse.statusText);
                }

                return of(fetchResponse.body);
            })
        );
    }

    /**
     * Reads file with given fileUri from device disk. Use the readAttachmentFromDisk function instead if you want to read attachment by filePath
     * Returns an observable which resolves to an object with properties fileName and data
     * @param fileUri fileUri
     * @param asDataUrl if true the file content will be returned with the typical data:base64 prefix
     */
    readFileFromDisk(fileUri: string, asDataUrl = false) {
        return from(
            // read file from local cache folder
            Filesystem.readFile({
                path: fileUri,
            })
        ).pipe(
            map((fileReadResult) => {
                // if we want the file content with the typical base64 prefix we will add it
                if (asDataUrl) {
                    let contentType = this.utils.getMimeTypeForFileEnding(this.utils.getFileEnding(fileUri));
                    fileReadResult.data = 'data:' + contentType + ';base64,' + fileReadResult.data;
                }
                return {
                    fileUri: fileUri,
                    fileData: fileReadResult.data,
                };
            })
        );
    }

    /**
     * Returns a signed s3 url for given attachment filePath
     * Used to directly read files from s3 storage
     * @param attachmentFilePath
     * @param download if set to true the content disposition header is set to true so the file will be downloaded instead of opened, when accessing the url. if set to an string the filename of the downloaded file will be set accordinlgy
     * @param s3directory
     */
    getSignedS3UrlForAttachmentFilePath(attachmentFilePath: string, download: boolean | 'string' = false, s3directory: string = null) {
        let urlString = environment.AWS_API_URL + '/attachments?filePath=' + encodeURI(attachmentFilePath) + '&download=' + download;
        if (s3directory != null && s3directory.trim() !== '') {
            urlString += '&s3directory=' + s3directory;
        }
        return this.http.get<string>(urlString).toPromise();
    }

    /**
     * Deletes the given file from device disk. Returns true if it was deleted and false if nothing was deleted. Throws an exception if an error occurs
     * Returns the response of the ionic fileSystem request as promise
     * @param filePath name of the file (can be a path)
     * @param deletion limits which occurences of the attachment can be deleted. defaults to "cache-only" which removes attachments only from the local project data cache but keeps possible upload files remaining
     */
    async deleteFileFromDisk(filePath: string, deletion: 'cache-only' | 'upload-only' | 'all' = 'cache-only') {
        return new Promise(async (resolve, reject) => {
            try {
                let deleted = false;

                if (deletion !== 'upload-only') {
                    if (await this.fileEntryExists(environment.DISK_FOLDER_ATTACHMENTS + filePath, Directory.Data)) {
                        // check if attachment exists in cache, delete it
                        await Filesystem.deleteFile({
                            path: environment.DISK_FOLDER_ATTACHMENTS + filePath,
                            directory: Directory.Data,
                        });
                        deleted = true;
                    }
                }

                if (deletion !== 'cache-only') {
                    if (await this.fileEntryExists(environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD + filePath, Directory.Data)) {
                        // check if attachment exists in upload folder, delete it
                        await Filesystem.deleteFile({
                            path: environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD + filePath,
                            directory: Directory.Data,
                        });
                        deleted = true;
                    }
                }

                resolve(deleted);
            } catch (delErr) {
                reject(delErr);
            }
        });
    }

    /**
     * Clears the files which are stored on the device disk
     * Returns a promise
     */
    clearFilesystem() {
        return Promise.all([
            Filesystem.rmdir({
                path: environment.DISK_FOLDER_ATTACHMENTS.substring(0, environment.DISK_FOLDER_ATTACHMENTS.length - 1),
                directory: Directory.Data,
                recursive: true,
            }),
            Filesystem.rmdir({
                path: environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD.substring(0, environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD.length - 1),
                directory: Directory.Data,
                recursive: true,
            }),
        ]);
    }

    /**
     * Moves a file from a path to target.
     * @param fromPath path to source file. Can be full path like file://....
     * @param fromDirectory source directory. When omitted fromPath is treated as full file path
     * @param toPath target path relative to toDirectory
     * @param toDirectory target directoy
     * @returns Promise which resolves empty upon success
     */
    async moveFile(fromPath: string, toPath: string, fromDirectory: Directory = undefined, toDirectory: Directory = Directory.Data) {
        let targetPath = environment.DISK_FOLDER_ATTACHMENTS + toPath;
        let targetBasePath = targetPath.substring(0, targetPath.lastIndexOf('/') + 1);

        await this.createDirectoriesIfNotExist(targetBasePath, Directory.Data);

        if (!fromDirectory) {
            // full path version
            return Filesystem.rename({
                from: fromPath,
                to: targetPath,
                toDirectory: toDirectory,
            });
        } else {
            // relative version
            return Filesystem.rename({
                from: fromPath,
                directory: fromDirectory,
                to: targetPath,
                toDirectory: toDirectory,
            });
        }
    }
    /**
     * Copies a file from a path to target.
     * @param fromPath path to source file. Can be full path like file://....
     * @param fromDirectory source directory. When omitted fromPath is treated as full file path
     * @param toPath target path relative to toDirectory
     * @param toDirectory target directoy
     * @returns Promise which resolves empty upon success
     */
    async copyFile(fromPath: string, toPath: string, fromDirectory: Directory = undefined, toDirectory: Directory = Directory.Data) {
        let targetPath = environment.DISK_FOLDER_ATTACHMENTS + toPath;
        let targetBasePath = targetPath.substring(0, targetPath.lastIndexOf('/') + 1);

        await this.createDirectoriesIfNotExist(targetBasePath, Directory.Data);

        if (!fromDirectory) {
            // full path version
            return Filesystem.copy({
                from: fromPath,
                to: targetPath,
                toDirectory: toDirectory,
            });
        } else {
            // relative version
            return Filesystem.copy({
                from: fromPath,
                directory: fromDirectory,
                to: targetPath,
                toDirectory: toDirectory,
            });
        }
    }

    public async preparePlanData(
        plan: BuildingPlan,
        downloadOnly = false,
        downloadInfoCb?: () => void,
        extractInfoCb?: () => void,
        throwOnError?: boolean
    ) {
        if (
            !(await this.checkIfPlanAttachmentDataExists(plan.attachment)) &&
            !(await this.checkIfPlanAttachmentArchiveFileExists(plan.attachment))
        ) {
            this.logger.log('[SYNC] plan needs download', plan._id);
            try {
                if (downloadInfoCb) {
                    downloadInfoCb();
                }
                await this.downloadPlanAttachmentArchive(plan.attachment);
                this.logger.log('[SYNC] downloaded plan archive', plan._id);
            } catch (planEx) {
                this.logger.warn('[SYNC] could not download plan archive', plan._id, planEx);

                if (throwOnError) {
                    throw planEx;
                }
            }
        }

        if (!downloadOnly) {
            if (!(await this.checkIfPlanAttachmentDataExists(plan.attachment))) {
                this.logger.log('[SYNC] will unpack plan archive', plan._id);
                try {
                    if (extractInfoCb) {
                        extractInfoCb();
                    }
                    let archivePath = this.getLocalPlanAttachmentArchivePath(plan.attachment);

                    let fileUri = await Filesystem.getUri({
                        directory: Directory.Data,
                        path: environment.DISK_FOLDER_ATTACHMENTS + archivePath,
                    });

                    // let archiveUri = await this.getUriForAttachmentPath(fileUri.uri);
                    let result = await this.unpackArchive(fileUri.uri, this.utils.stripFileExtension(fileUri.uri));
                    this.logger.log('[SYNC] unpacked plan archive', result);
                } catch (packErr) {
                    this.logger.error('[SYNC] could not unpack plan archive', packErr);

                    if (packErr) {
                        throw packErr;
                    } else {
                        // Sentry should only capture this here when not requesting to rethrow errors
                        this.utils.sentryCaptureException(packErr);
                    }
                }
            } else {
                this.logger.log('[SYNC] Archive already unpacked', plan._id);
            }
        }
    }

    /**
     * Helper function which takes given web path and returns base64 content
     * Returns an observable which returns the base64 content of the given webPath
     * @param webPath
     */
    readWebPathAsBase64(webPath: string) {
        // Fetch the photo, read as a blob, then convert to base64 format
        return from(fetch(webPath!)).pipe(
            switchMap((fetchResponse) => {
                return from(fetchResponse.blob());
            }),
            switchMap((blobResponse) => {
                return from(this.convertBlobToBase64Promise(blobResponse));
            })
        );
    }

    /**
     * Helper function which takes given blob and returns the base64 content
     * Returns an observable which resolves to the base64 content string of the given blob
     * @param blob
     */
    convertBlobToBase64Promise(blob: Blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = (error) => {
                reject(error);
            };
            reader.onload = () => {
                resolve(reader.result as string);
            };
            reader.readAsDataURL(blob);
        });
    }

    async checkIfAttachmentFileExists(attachment: Attachment): Promise<boolean> {
        return this.fileEntryExists(environment.DISK_FOLDER_ATTACHMENTS + attachment.filePath, Directory.Data);
    }

    async checkIfPlanAttachmentArchiveFileExists(attachment: PlanAttachment): Promise<boolean> {
        let path = this.getLocalPlanAttachmentArchivePath(attachment);
        return this.fileEntryExists(`${environment.DISK_FOLDER_ATTACHMENTS}${path}`, Directory.Data);
    }

    getLocalPlanAttachmentArchivePath(attachment: PlanAttachment) {
        let path = undefined;
        if (this.utils.supportsWebP() && attachment.webp) {
            path = `${this.utils.stripTrailingSlash(attachment.filePath)}/${attachment.webp.archiveName}`;
        } else {
            path = `${this.utils.stripTrailingSlash(attachment.filePath)}/${attachment.jpeg.archiveName}`;
        }

        return path;
    }

    async checkIfPlanAttachmentDataExists(attachment: PlanAttachment): Promise<boolean> {
        let path = '';
        if (this.utils.supportsWebP() && attachment.webp) {
            path = `${environment.DISK_FOLDER_ATTACHMENTS}${this.utils.stripTrailingSlash(
                attachment.filePath
            )}/${this.utils.stripFileExtension(attachment.webp.archiveName)}`;
        } else {
            path = `${environment.DISK_FOLDER_ATTACHMENTS}${this.utils.stripTrailingSlash(
                attachment.filePath
            )}/${this.utils.stripFileExtension(attachment.jpeg.archiveName)}`;
        }

        return this.fileEntryExists(path, Directory.Data);
    }

    async getUriForAttachment(attachment: Attachment, nativeRemap = true): Promise<string> {
        return this.getFileUri(environment.DISK_FOLDER_ATTACHMENTS + attachment.filePath, Directory.Data, nativeRemap);
    }

    async getUriForAttachmentPath(attachmentPath: string): Promise<string> {
        return this.getFileUri(environment.DISK_FOLDER_ATTACHMENTS + attachmentPath, Directory.Data);
    }

    /**
     * Extracts a zip archive from source to target URI. Capacitor FileSystem paths dont work! They have to be URIs!
     * @returns a promise which resolves with the unzip result
     */
    async unpackArchive(sourceUri, targetUri): Promise<UnzipResult> {
        return await ZipPlugin.unZip(
            {
                source: sourceUri,
                destination: targetUri,
                overwrite: true, // Optional default true
            },
            (progress) => {
                console.log('[ATTACHMENT SERVICE] archive unpack progress', progress);
            }
        );
    }

    async getFileUri(path: string, directory: Directory, remap = true) {
        let uriRes = await Filesystem.getUri({
            path: path,
            directory: directory,
        });

        // While running in a native context we need to "remap" the file source for directly setting it to a src
        if (this.utils.isNative && remap) {
            return Capacitor.convertFileSrc(uriRes.uri);
        }

        return uriRes.uri;
    }

    async getUploadFileStats(path: string) {
        return Filesystem.stat({
            path: environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD + path,
            directory: Directory.Data,
        });
    }

    async fileEntryExists(path: string, directory: Directory): Promise<boolean> {
        try {
            await Filesystem.stat({
                path: path,
                directory: directory,
            });

            return true;
        } catch (e) {
            return false;
        }
    }

    public async downloadPlanAttachmentArchive(attachment: PlanAttachment) {
        let archivePath = `${this.utils.stripTrailingSlash(attachment.filePath)}/${attachment.jpeg.archiveName}`;
        if (this.utils.supportsWebP() && attachment.webp) {
            archivePath = `${this.utils.stripTrailingSlash(attachment.filePath)}/${attachment.webp.archiveName}`;
        }

        let signedUrl = await this.getSignedS3UrlForAttachmentFilePath(archivePath);

        let fileDownload = await this.http.get(signedUrl, { responseType: 'blob' }).toPromise();
        let fileBase64 = await this.convertBlobToBase64Promise(fileDownload);
        await this.saveAttachmentToDiskPromise(archivePath, fileBase64 as string);
        this.logger.log('[ATTACHMENT SERVICE] saved attachment to local disk', archivePath);
    }

    private async createDirectoriesIfNotExist(path: string, directory: Directory): Promise<void> {
        if (await this.fileEntryExists(path, directory)) {
            // by getting here we know the directory exists so we are done
            return;
        } else {
            try {
                await Filesystem.mkdir({
                    path: path,
                    directory: directory,
                    recursive: true,
                });
            } catch (createDirEx) {
                // Ignore this error cause we want the folder to exist
                if ((createDirEx as Error).message != 'Current directory does already exist.') {
                    throw createDirEx;
                }

                this.logger.log('[ATTACHMENT SERVICE] ignored error', createDirEx);
            }
            return;
        }
    }

    /**
     * Helper function which returns an array of fileUris with all found files inside of a folder. Scans all found subfolders
     */
    public async getAllFilesInFolderAndSubfolders(
        folderPathOrUri: string,
        directory: Directory = null,
        max: number | null = null
    ): Promise<string[]> {
        let foundFiles = [];

        if (max == 0) {
            return foundFiles;
        }

        let readDirOptions = {
            path: folderPathOrUri,
        };
        // if a directory is given, append it to our readDirOptions, if it is not given we leave it out and use a file uri
        if (directory != null) {
            readDirOptions = { ...readDirOptions, ...{ directory: directory } };
        }

        let folderReadRes = await Filesystem.readdir(readDirOptions);

        for (let currentEntry of folderReadRes.files) {
            let readFileOptions = {
                path: folderPathOrUri + currentEntry.name,
            };
            // if a directory is given, append it to our readFileOptions, if it is not given we leave it out and use a file uri
            if (directory != null) {
                readFileOptions = { ...readFileOptions, ...{ directory: directory } };
            }

            if (this.utils.isFileTypeDirectory(currentEntry.type)) {
                let subfolderFiles = await this.getAllFilesInFolderAndSubfolders(
                    this.utils.addTrailingSlash(currentEntry.uri),
                    null,
                    Math.max(0, max - foundFiles.length) // reduce max items in recursive call as we may have some matches already
                );
                foundFiles = foundFiles.concat(subfolderFiles);
            } else if (this.utils.isFileTypeFile(currentEntry.type)) {
                foundFiles.push(currentEntry.uri);
            }

            // if we hit our max target we stop execution
            if (foundFiles.length >= max) {
                this.logger.log('[ATTACHMENT SERVICE] Reached current chunk limit', max);
                return foundFiles;
            }
        }

        return foundFiles;
    }

    /**
     * Helper function to remove the contents of a directory without keeping any contents. Careful when using
     * @param folderPath relative path to clear in given direcotry
     * @param directory Capacitor Direcotry
     * @returns a resolved promise if there are no errors
     */
    public async cleanFolder(folderPath: string, directory: Directory = Directory.Library): Promise<void | boolean> {
        this.logger.log('[ATTACHMENT SERVICE] cleaning directory', [folderPath, directory]);

        // handle Capacitor bug ourselves
        if ((Capacitor.getPlatform() == 'web' && directory == Directory.Data) || directory == Directory.Cache) {
            return await this.webCleanFolder(folderPath, directory);
        }

        await Filesystem.rmdir({
            path: folderPath,
            directory: directory,
            recursive: true,
        });
        return await Filesystem.mkdir({
            path: folderPath,
            directory: directory,
            recursive: true,
        });
    }

    /**
     * Helper function which cleans given folder by checking all subfolders and deleting them if they are empty
     * @param folderPath
     * @param directory
     * @param baseStartFolder used for recursive calls to not delete the original folder we started in
     */
    public async cleanupEmptyFolders(folderPath: string, directory: Directory, baseStartFolder: string = null) {
        this.logger.log(`[ATTACHMENT SERVICE] CLEANUP: now checking folder ${folderPath} - will remove all empty sub-folders`);

        if (baseStartFolder == null) {
            baseStartFolder = folderPath;
        }

        let folderReadRes = await Filesystem.readdir({
            path: folderPath,
            directory: directory,
        });

        if (folderReadRes.files.length == 0) {
            if (folderPath == baseStartFolder) {
                // if the initial folder we check is empty we stop here - we will never delete the initial directory we check, only sub directories on recursive calls
                this.logger.log('[ATTACHMENT SERVICE] CLEANUP: folder is empty, no subfolders found');
            } else {
                try {
                    // folder is actually empty, we can delete it and call again for parent directory
                    await Filesystem.rmdir({
                        path: this.utils.stripTrailingSlash(folderPath),
                        directory: directory,
                        recursive: true,
                    });

                    // determine parent folder and call cleanup for parent folder for another empty check as we have just deleted a child folder
                    let currentFolderPathSplit = folderPath.split('/');
                    currentFolderPathSplit.splice(currentFolderPathSplit.length - 2, 1);
                    let parentFolderPath = currentFolderPathSplit.join('/');
                    this.logger.log(
                        `[ATTACHMENT SERVICE] CLEANUP: current folder is empty - deleting and moving to parent folder for another check`,
                        parentFolderPath
                    );
                    if (parentFolderPath.length > baseStartFolder.length) {
                        await this.cleanupEmptyFolders(parentFolderPath, directory, baseStartFolder); // remove everything from last slash onward to get the parent folder
                    } else {
                        this.logger.log('[ATTACHMENT SERVICE] CLEANUP: base folder reached');
                    }
                } catch (err) {
                    this.logger.error('[ATTACHMENT SERVICE] CLEANUP: could not delete empty folder during cleanup', folderPath, err);
                }
            }
        } else {
            // folder is not empty, check if there are subdirectories we can check recursively
            this.logger.log('[ATTACHMENT SERVICE] CLEANUP: folder is not empty, checking for sub-folders');
            for (let currentFolderEntry of folderReadRes.files) {
                try {
                    let currentFileUriSplit = currentFolderEntry.uri.split(baseStartFolder);
                    let currentFilePath = baseStartFolder + currentFileUriSplit[currentFileUriSplit.length - 1]; // remove directory prefix from uri, so we only have the filePath starting at our baseStartFolder

                    if (this.utils.isFileTypeDirectory(currentFolderEntry.type)) {
                        // check this sub folder recursively
                        await this.cleanupEmptyFolders(this.utils.addTrailingSlash(currentFilePath), directory, baseStartFolder);
                    }
                } catch (err) {
                    this.logger.error('[ATTACHMENT SERVICE] CLEANUP: could not delete folder during cleanup', folderPath, err);
                }
            }
        }
    }

    /**
     * HACK: Capacitor FileStorage has problems on the web platform with recursive operations for directories as seen below.
     * So we do the deletion directly on the IDB instead.
     * @see https://github.com/ionic-team/capacitor-plugins/issues/2204
     * @see https://github.com/ionic-team/capacitor-plugins/issues/2227
     */
    private webCleanFolder(filePath: string, directory: Directory): Promise<boolean> {
        let _capFsDb = 'Disc';
        let _capFsDbStore = 'FileStorage';
        let _dir = '';
        switch (directory) {
            case Directory.Cache:
                _dir = '/CACHE/';
                break;
            default:
                _dir = '/DATA/';
                break;
        }
        let _target = _dir + filePath;

        return new Promise((resolve, reject) => {
            try {
                // Let us open version 4 of our database
                let DBOpenRequest = window.indexedDB.open(_capFsDb);

                // these two event handlers act on the database being opened
                // successfully, or not
                DBOpenRequest.onerror = (event) => {
                    console.error('error loading Disc');
                    reject(event);
                };

                DBOpenRequest.onsuccess = async (event) => {
                    // store the result of opening the database in the db
                    // variable. This is used a lot later on, for opening
                    // transactions and suchlike.
                    let _tdb = DBOpenRequest.result;

                    console.log('got db');

                    let transaction = _tdb.transaction(_capFsDbStore, 'readwrite');
                    let stor = transaction.objectStore(_capFsDbStore);

                    console.log('got stor');

                    await stor.delete(
                        IDBKeyRange.bound(
                            _target,
                            _target + '\uffff' // this is the highest unicode character, so it matches everythin between
                        )
                    );
                    console.log('did delete');
                    _tdb.close();
                    resolve(true);
                };
            } catch (idbEx) {
                reject(idbEx);
            }
        });
    }
}
