import { Platform } from '@ionic/angular';
import { BuildingPlan } from './../../projects/project-details/project-building-plans/building-plan.model';
import { Injectable, OnDestroy } from '@angular/core';
import * as AWS from 'aws-sdk';
import { DbService } from '../db/db.service';
import { LoggerService } from '../logger/logger.service';
import { BaseItem } from '../db/base-item.model';
import { UtilsService } from '../utils/utils.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, defer, forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';
import { Storage } from '@ionic/storage-angular';
import { HttpClient } from '@angular/common/http';
import { AttachmentService } from '../attachment/attachment.service';
import S3 from 'aws-sdk/clients/s3';
import { SyncResponse } from './sync-response.interface';
import { AuthService, UserIdleStatus } from '../../auth/auth.service';
import { SyncInfoInterface } from './sync-info.interface';
import { AuthInfo } from '../../auth/auth-info.interface';
import { ProjectSharing } from '../../projects/project-sharing.model';
import { Project } from '../../projects/project.model';
import { DocumentClient } from 'aws-sdk/lib/dynamodb/document_client';
import moment from 'moment';
import { NotificationService } from '../notification/notification.service';

import { Directory } from '@capacitor/filesystem';
import { SettingsService } from '../../settings/settings.service';
import QueryInput = DocumentClient.QueryInput;

@Injectable({
    providedIn: 'root',
})
export class SyncService implements OnDestroy {
    private s3: S3;

    private authInfo: AuthInfo;
    private userIdleStatus: UserIdleStatus;

    private syncRunning: false | 'upstream' | 'downstream' | 'cleanup' = false; // current sync status (false when not running, 'upstream' or 'downstream' when running)
    public syncRunning$ = new BehaviorSubject<false | 'upstream' | 'downstream' | 'cleanup'>(this.syncRunning); // subscribeable subject for sync status

    private syncBlock$ = new BehaviorSubject<boolean>(false);
    private syncMode: 'default' | 'limited' = 'default'; // current sync mode (limited if not enough storage space available)
    public syncMode$ = new BehaviorSubject<'default' | 'limited'>(this.syncMode); // subscribable subject for sync mode

    private forceCleanupOnNextCheck = false; // true if the sync mode has just changed to limited and we need to force a db cleanup
    private lastStorageSpaceCheckTimestamp = 0; // timestamp when the last storage space check occured

    private currentlyDownloadingProjectList = false;
    private currentlyDownloadingProjectData = false;
    private currentlyDownloadingDataByRelation = false;

    // sync info which contains sync log and count
    private syncInfo: SyncInfoInterface = {
        currentRunUpstreamCount: 0,
        currentRunDownstreamCount: 0,
        totalUpstreamCount: 0,
        totalDownstreamCount: 0,
        lastUpstreamStartTimestamp: 0,
        lastDownstreamStartTimestamp: 0,
        currentSyncWarning: null,
    };
    public syncInfo$ = new BehaviorSubject(this.syncInfo); // subscribeable subject for sync info
    public lastSyncInfoUpdateTimestamp = 0; // timestamp when the syncinfo was updated

    private loginStatusSubscription: Subscription; // subscription to the auth login status change
    private authInfoSubscription: Subscription; // subscription to the auth info changes
    private awsCredentialsChangedSubscription: Subscription; // subscription to the aws credentials changed event
    private idleStatusSubscription: Subscription; // subscription to idle status
    private dbUpSyncRequiredSubscription: Subscription; // items in db changed which leads to upstream syncs
    private webSocketMessagesSubscription: Subscription; // subscription to the websocket messages in auth service

    private networkSub: Subscription; // react to changes in the network connection

    private autoSyncIntervalId = null;
    private startUpstreamSyncAgain = false; // used to start upstream sync again if a upstream request comes in while upstream is already running

    constructor(
        private plt: Platform,
        private db: DbService,
        private authSrv: AuthService,
        private settingsSrv: SettingsService,
        private attachmentSrv: AttachmentService,
        private logger: LoggerService,
        private notify: NotificationService,
        private utils: UtilsService,
        private storage: Storage,
        private http: HttpClient
    ) {
        // init sync service as soon as user is logged in
        this.loginStatusSubscription = this.authSrv.loginStatusChanged$.subscribe((loginStatus) => {
            if (loginStatus === true) {
                this.init();
            }
        });
        this.authInfoSubscription = this.authSrv.authInfoChanged$.subscribe((authInfo) => {
            this.authInfo = authInfo;

            if (this.authInfo != null) {
                this.setSyncModeBasedOnAvailableStorageOrUserType();
            }
        });
        this.awsCredentialsChangedSubscription = this.authSrv.awsCredentialsChanged.subscribe(() => {
            this.initS3Sdk();
        });
        this.idleStatusSubscription = this.authSrv.userIdleStatusChanged.subscribe((newIdleStatus) => {
            if (this.userIdleStatus != null && this.userIdleStatus != UserIdleStatus.ACTIVE && newIdleStatus == UserIdleStatus.ACTIVE) {
                // user changed from idle to active, start downstream sync because there maybe were changes in the meantime where the websocket was not active
                this.logger.log('[SYNC] starting downstream sync in 1.5 seconds, because user has just come back from idle');
                setTimeout(() => {
                    this.syncDownstream();
                }, 1500);
            }
            this.userIdleStatus = newIdleStatus;
            this.initAutoSync();
        });

        this.webSocketMessagesSubscription = this.authSrv.webSocketMessageReceived$.subscribe((message) => {
            if (this.syncBlock$.value !== true) {
                this.handleWebsocketMessage(message);
            }
        });

        this.networkSub = this.utils.onlineChanged.pipe(debounceTime(4000)).subscribe((nowConnected) => {
            if (this.authSrv.isLoggedIn) {
                if (nowConnected) {
                    // start syncing
                    this.logger.log('[SYNC] Network reconnected. Will start sync in 5 seconds');
                    setTimeout(() => {
                        this.syncDownstream();
                    }, 5000);
                }
            } else {
                this.logger.log('[SYNC] Network changed but not logged in! Skipping sync start');
            }
        });
    }

    ngOnDestroy(): void {
        if (this.loginStatusSubscription) {
            this.loginStatusSubscription.unsubscribe();
        }
        if (this.authInfoSubscription) {
            this.authInfoSubscription.unsubscribe();
        }
        if (this.awsCredentialsChangedSubscription) {
            this.awsCredentialsChangedSubscription.unsubscribe();
        }
        if (this.idleStatusSubscription) {
            this.idleStatusSubscription.unsubscribe();
        }
        if (this.dbUpSyncRequiredSubscription) {
            this.dbUpSyncRequiredSubscription.unsubscribe();
        }
        if (this.webSocketMessagesSubscription) {
            this.webSocketMessagesSubscription.unsubscribe();
        }
        if (this.autoSyncIntervalId != null) {
            clearInterval(this.autoSyncIntervalId);
        }
        if (this.networkSub != null) {
            this.networkSub.unsubscribe();
        }
    }

    /**
     * Initializes the sync service
     */
    async init() {
        // If using a custom driver:
        // await this.storage.defineDriver(MyCustomDriver)
        await this.storage.create();

        this.initS3Sdk();

        await this.setSyncModeBasedOnAvailableStorageOrUserType();
        // and everytime we bring our app back from standby we check again
        this.plt.resume.subscribe(async (_) => {
            await this.setSyncModeBasedOnAvailableStorageOrUserType();
        });

        // check if we are doing a first time sync
        let timestamp = await this.storage.get('dp_last_downstream_sync_completed_timestamp');
        if (timestamp == null) {
            this.syncInfo.currentSyncWarning = 'first-time-sync';
            this.syncInfo$.next(this.syncInfo); // immediately emit update, so that other components know about the first time sync
        } else if (moment(timestamp).isBefore(moment().subtract(81, 'days'))) {
            // if our last completed downstream sync is more than 81 days ago we will logout the user, his offline data is too old so we need a new install
            this.logger.warn('[SYNC] reset is needed - last sync timestamp is older than 81 days', timestamp);
            this.notify.info(
                'Da DokuPit auf diesem Gerät sehr lange nicht verwendet wurde, musst du deinen Datenstand neu synchronisieren. Du wirst deswegen in Kürze ausgeloggt und musst dich dann erneut einloggen.',
                'Neu-Synchronisation erforderlich',
                false,
                5000
            );
            setTimeout(() => {
                this.authSrv.logout();
            }, 5000);
        } else if (moment(timestamp).isBefore(moment().subtract(3, 'days'))) {
            // display warning if our last completed downstream sync is more than 3 days ago
            this.syncInfo.currentSyncWarning = 'downstream-too-long-ago';
            this.syncInfo$.next(this.syncInfo); // immediately emit update, so that other components know about the warning
        }

        setTimeout(() => {
            // call the first sync in 3 seconds - this gives the rest of the app a little bit more time to initialize
            this.syncDownstream();

            // start upstream sync when db has changed items
            this.dbUpSyncRequiredSubscription = this.db.upstreamSyncRequired.pipe(debounceTime(1000)).subscribe(() => {
                this.syncUpstream();
            });
        }, 3000);
    }

    /**
     * Initializes the s3 sdk
     */
    initS3Sdk() {
        this.s3 = new AWS.S3({
            apiVersion: '2006-03-01',
            httpOptions: {
                timeout: 3600000, // 1 hour timeout
            },
            correctClockSkew: true,
        });
    }

    /**
     * Checks the available free storage and sets the sync mode based on free space
     */
    async setSyncModeBasedOnAvailableStorageOrUserType() {
        try {
            let lastSavedSyncMode = null;
            try {
                lastSavedSyncMode = await this.storage.get('dp_last_sync_mode');
                this.logger.warn('[SYNC] last saved sync mode is', lastSavedSyncMode);
            } catch (syncModeReadErr) {
                this.logger.error('[SYNC] could not read last saved sync mode', syncModeReadErr);
                this.utils.sentryCaptureException(syncModeReadErr);
            }

            // Basically we need to think as we have infinite storage available (as the browser developers somehow push this idea)
            let storageQuota = {
                quota: Infinity,
                usage: Infinity,
            } as StorageEstimate;
            this.logger.warn('[SYNC] checking storage quota to adjust sync mode if needed');
            try {
                storageQuota = await this.checkAvailableStorage();
            } catch (quotaCheckErr) {
                if (
                    quotaCheckErr &&
                    (quotaCheckErr.message?.indexOf('undefined is not an object') != -1 ||
                        quotaCheckErr.message?.indexOf('No storage API available') != -1) &&
                    this.plt.is('ios')
                ) {
                    this.logger.warn('[SYNC] ignoring storage estimate on mobile iOS');
                } else {
                    throw quotaCheckErr;
                }
            }
            let percentUsed = Math.round((storageQuota.usage / storageQuota.quota) * 100);
            let usedStorage = Math.round(storageQuota.usage / (1000 * 1000));
            let quotaStorage = Math.round(storageQuota.quota / (1000 * 1000));
            this.logger.warn(`[SYNC] storage quota checked ${usedStorage}MB of ${quotaStorage}MB used (${percentUsed}%)`);

            // Either set sync mode limited if the storage gets low or the user explicitly wants it
            if (this.authInfo.userData.userSettings.preferLimitedSync || percentUsed > 80 || quotaStorage - usedStorage < 200) {
                if (this.syncMode != 'limited') {
                    this.logger.warn(`[SYNC] sync mode changed to limited`);
                    if (lastSavedSyncMode !== 'limited' && lastSavedSyncMode != null) {
                        this.forceCleanupOnNextCheck = true; // force cleanup because our last saved sync mode was not limited but only if not syncing for first time
                    }
                    this.syncMode = 'limited';
                    this.syncMode$.next(this.syncMode);

                    if (percentUsed > 94) {
                        this.notify.error(
                            'Der am Gerät verfügbare Speicherplatz ist fast voll. Bitte schaffe mehr Platz am Gerät und archiviere Projekte um die Funktion weiterhin sicherzustellen.',
                            'Speicher fast voll',
                            undefined,
                            false,
                            14000
                        );
                    } else if (percentUsed > 80) {
                        this.notify.warn(
                            'Der verfügbare Speicherplatz auf deinem Gerät wird langsam knapp. Bitte überlege welchen freizugeben und nicht mehr benötigte Projekte zu archivieren um den Betrieb weiterhin zu ermöglichen',
                            'Speicher wird knapp',
                            true,
                            14000
                        );
                    }
                }
            } else {
                if (this.syncMode != 'default') {
                    this.logger.warn(`[SYNC] sync mode changed to default`);
                    this.syncMode = 'default';
                    this.syncMode$.next(this.syncMode);
                }
            }

            if (lastSavedSyncMode !== this.syncMode) {
                // if our sync mode changed save the new status
                try {
                    await this.storage.set('dp_last_sync_mode', this.syncMode);
                } catch (syncModeSaveErr) {
                    this.logger.error('[SYNC] could not save last sync mode', syncModeSaveErr);

                    if (!this.maybeHandleStorageSpaceError(syncModeSaveErr)) {
                        this.utils.sentryCaptureException(syncModeSaveErr);
                    }
                }
            }

            this.lastStorageSpaceCheckTimestamp = Date.now();

            return this.syncMode;
        } catch (err) {
            this.logger.warn('[SYNC] could not set sync mode based on available storage');
            if (
                err &&
                (err.message.indexOf('undefined is not an object') != -1 || err.message.indexOf('No storage API available') != -1) &&
                this.plt.is('ios')
            ) {
                this.logger.warn('[SYNC] ignoring storage estimate on mobile iOS');
            } else {
                this.utils.sentryCaptureException(err);
            }
            return false;
        }
    }

    /**
     * Checks and returns the available free storage
     * TODO: Find a way for mobile safari in capacitor
     */
    checkAvailableStorage() {
        // check with new storageManager api if available
        if ('storage' in navigator && 'estimate' in navigator.storage) {
            // We've got the real thing! Return its response.
            return navigator.storage.estimate();
        }

        // check with old method if storageManager is not available
        if ('webkitTemporaryStorage' in navigator && 'queryUsageAndQuota' in (<any>navigator).webkitTemporaryStorage) {
            // Return a promise-based wrapper that will follow the expected interface.
            return new Promise<{ usage: number; quota: number }>((resolve, reject) => {
                (<any>navigator).webkitTemporaryStorage.queryUsageAndQuota((usage, quota) => {
                    resolve({ usage: usage, quota: quota });
                }, reject);
            });
        }

        // If we don't have an API for estimating we fail via exception - always the case with iOS at the moment due to Capacitor not being a secure context
        this.logger.warn('[SYNC] No storage API available');
        return {
            usage: 0,
            quota: Infinity,
        };
    }

    /**
     * Initializes the automatic sync (starts a complete downstream sync every few minutes)
     */
    initAutoSync() {
        if (this.autoSyncIntervalId != null) {
            clearInterval(this.autoSyncIntervalId);
        }

        let interval = 240000; // default: every 4 minutes if user is active or short idle
        if (this.userIdleStatus == UserIdleStatus.LONG_IDLE) {
            interval = 1800000; // every 30 minutes if user is in long idle
        }

        this.logger.log('[SYNC] setting auto sync interval to ' + interval + ' - user idle status: ' + this.userIdleStatus);

        this.autoSyncIntervalId = setInterval(() => {
            // this.logger.log('[SYNC] akshdfkjsahkjd');
            if (this.authSrv.isLoggedIn) {
                this.syncDownstream();
            }
        }, interval);
    }

    /**
     * Handles received websocket messages and triggers further sync actions
     */
    async handleWebsocketMessage(message) {
        // trigger sync actions based on received websocket messages
        switch (message.action) {
            case 'db-updated-item-for-relation': {
                // items for the relation we are listening to have changed (so items in our current project have changed, we should sync this project)
                let relationSplit = message.relation.split('#');
                let docId = relationSplit[1];
                if (relationSplit[0] === 'company') {
                    // a document related to the company changed, so sync company-wide documents (service-groups, etc.)
                    this.logger.log(
                        `[SYNC WS] got ws notification that an updated-item is available for the current company ${message.relation} - starting sync for company`
                    );
                    await this.downloadItemsByRelation('company#' + this.authSrv.authInfo.companyData._id).then(() => {
                        this.logger.log(`[SYNC WS] sync for company relation  ${message.relation} done`);
                    });
                } else {
                    this.db.getItemById(docId).subscribe(
                        (data) => {
                            this.logger.log(
                                `[SYNC WS] got ws notification that an updated-item is available for relation ${message.relation} - starting sync for relation`
                            );
                            this.downloadItemsByRelation(message.relation).then(() => {
                                this.logger.log(`[SYNC WS] sync for relation  ${message.relation} done`);
                            });
                        },
                        (err) => {
                            // this should not happen, we got the websocket notification for a document which is not available on our device
                            this.logger.log(
                                '[SYNC] got websocket notification for a document which is not available on the local device',
                                message,
                                err
                            );
                        }
                    );
                }

                break;
            }
            case 'db-updated-project-sharing': {
                // project sharings have changed, we should update our project list and download new project data afterwards
                this.syncDownstream();
                break;
            }
            default: {
                break;
            }
        }
    }

    /**
     * Handles the upstream sync process
     */
    async syncUpstream() {
        if (this.syncRunning === false && this.syncBlock$.value === true) {
            this.logger.log('[SYNC UPSTREAM] not syncing due to active block');
            return;
        }
        if (this.syncRunning !== false) {
            this.logger.log('[SYNC UPSTREAM] could not start sync, already running ' + this.syncRunning);
            if (this.syncRunning === 'upstream') {
                this.startUpstreamSyncAgain = true;
                this.logger.log('[SYNC UPSTREAM] will start upstream again if the current upstream sync has ended');
            }
            return;
        }
        if (!this.utils.isOnline) {
            this.logger.log('[SYNC UPSTREAM] could not start sync, device is offline');
            return;
        }
        // FIXME: Set sync status as soon as running checks are done to prevent race conditions of e.g. websocket messages
        // same as downstream
        this.setSyncStatus('upstream');

        await this.utils.keepAwake('upstream sync');
        this.syncInfo.currentRunUpstreamCount = 0; // reset current run sync count
        this.syncInfo.lastUpstreamStartTimestamp = Date.now();

        this.logger.log('[SYNC UPSTREAM] upstream sync started');

        let attachmentUploadSuccessful = false;
        try {
            // upload attachments
            let attachmentSyncRes = await this.uploadAttachments();
            this.logger.log('[SYNC UPSTREAM] attachment upload finished', attachmentSyncRes);
            attachmentUploadSuccessful = true;
        } catch (attachmentSyncError) {
            this.logger.error('[SYNC UPSTREAM] sync failed - attachments could not be uploaded', attachmentSyncError);

            this.maybeHandleStorageSpaceError(attachmentSyncError);
            this.utils.sentryCaptureException(attachmentSyncError);
            this.setSyncStatus(false);
        }

        let itemUploadSucessful = false;
        if (attachmentUploadSuccessful) {
            // upload items
            try {
                let itemSyncRes = await this.uploadItems().toPromise();
                this.logger.log('[SYNC UPSTREAM] item upload finished', itemSyncRes);
                itemUploadSucessful = true;
            } catch (itemsSyncErr) {
                this.logger.error('[SYNC UPSTREAM] sync failed - items could not be uploaded', itemsSyncErr);
                this.maybeHandleStorageSpaceError(itemsSyncErr);
                this.utils.sentryCaptureException(itemsSyncErr);
                this.setSyncStatus(false);
            }
        }

        if (attachmentUploadSuccessful && itemUploadSucessful) {
            // update last upstream sync completed timestamp
            try {
                await this.storage.set('dp_last_upstream_sync_completed_timestamp', Date.now());
                this.logger.log('[SYNC] updated last upstream sync completed timestamp');

                // remove upstream-too-long-ago sync warning if it was active
                if (this.syncInfo.currentSyncWarning == 'upstream-too-long-ago') {
                    this.syncInfo.currentSyncWarning = null;
                    this.syncInfo$.next(this.syncInfo);
                }

                // update sync status
                this.setSyncStatus(false);

                this.logger.log('[SYNC UPSTREAM] upstream sync completed');

                // start upstream sync again if needed
                if (this.startUpstreamSyncAgain) {
                    this.startUpstreamSyncAgain = false;
                    this.logger.log(
                        '[SYNC UPSTREAM] starting another upstream sync call, because something changed locally while upload was already running'
                    );
                    this.syncUpstream();
                } else {
                    // do db cleanup if needed
                    // TEMPORARY ONLY IF SYNC MODE IS LIMITED - TODO NEED TO DO CLEANUP IN BACKGROUND BECAUSE IT TAKES TOO LONG
                    if (this.syncMode === 'limited') {
                        try {
                            this.cleanupDatabase();
                        } catch (cleanupErr) {
                            this.utils.sentryCaptureException(cleanupErr);
                        }
                    }
                }

                // allow sleep again
                await this.utils.allowSleep('upstream finished');
            } catch (timeStampUpdateErr) {
                this.maybeHandleStorageSpaceError(timeStampUpdateErr);
                this.logger.error('[SYNC] could not update last upstream sync completed timestamp', timeStampUpdateErr);
                this.utils.sentryCaptureException(timeStampUpdateErr);
                this.setSyncStatus(false); // reset as the upstream sync failed, otherwise it would hang indefinitely
            }
        } else {
            await this.utils.allowSleep('upstream finished after some error');
        }
    }

    /**
     * Returns an observable which resolves to a boolean if there is unsynced data on the device
     */
    hasUnsyncedLocalData(): Observable<boolean> {
        return forkJoin([this.db.getItemsBySyncStatus(false), from(this.storage.get('dp_docs_to_delete'))]).pipe(
            switchMap((itemsToSync: Array<any>) => {
                if ((itemsToSync[0] as Array<BaseItem>).length != 0 || (itemsToSync[1] as Array<string>).length != 0) {
                    return of(true);
                }

                return of(false);
            }),
            catchError((err) => {
                this.utils.sentryCaptureException(err);
                // TODO: what if we reach this point but still have data lingering on device?
                return of(false);
            })
        );
    }

    /**
     * Uploads created attachments to online storage and moves them to the attachments-cache folder after uploading.
     * Returns an observable which completes after the attachments were uploaded and moved locally
     */
    private async uploadAttachments() {
        this.logger.log('[SYNC UPSTREAM] starting attachment upload');
        let attachmentsToUpload: string[] = [];
        try {
            attachmentsToUpload = await this.attachmentSrv.getAllFilesInFolderAndSubfolders(
                environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD,
                Directory.Data,
                20
            );
        } catch (readDirErr) {
            if (readDirErr.message === 'Folder does not exist.') {
                this.logger.log('[SYNC UPSTREAM] No local data directories');
                return 'no attachments to upload';
            }

            throw readDirErr;
        }

        // the attachmentstoUpload are all fileUris, we only need the filePath beginning with the projectId, so we remove everything before it
        attachmentsToUpload = attachmentsToUpload.map((att) => {
            let uriSplit = att.split(environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD);
            return uriSplit[uriSplit.length - 1]; // only return the last part after the upload folder
        });

        this.logger.log('[SYNC UPSTREAM] got attachments to upload', attachmentsToUpload);

        if (attachmentsToUpload.length === 0) {
            // this is our exit for the recursion
            return 'no attachments to upload';
        }

        // let uploadResults = [];
        for (let attachmentPath of attachmentsToUpload) {
            try {
                let s3RequestParams: S3.PutObjectRequest = {
                    Bucket: environment.AWS_S3_BUCKET_NAME,
                    Key: environment.AWS_S3_CUSTOMER_DATA_BASE_PATH + attachmentPath,
                };
                this.logger.log('[SYNC UPSTREAM] now uploading attachment', attachmentPath);

                // Being native we can utilize Blobs from filesystem for faster loading
                if (this.utils.isNative) {
                    // Beware that the fetch API approach is not working on iOS while using Live Reload! - maybe platform mobile and mobileweb determines native app running in live reload
                    this.logger.log('[SYNC UPSTREAM] using Blob upload');
                    let attachmentBlob = await this.attachmentSrv.getBlobForAttachmentToUpload(attachmentPath).toPromise();

                    s3RequestParams.Body = attachmentBlob;
                    s3RequestParams.ContentType = attachmentBlob.type;
                    s3RequestParams.ContentLength = attachmentBlob.size;
                } else {
                    this.logger.log('[SYNC UPSTREAM] using default Buffer upload');
                    let attachmentData = await this.attachmentSrv.readAttachmentFromDisk(attachmentPath, false, true).toPromise();

                    s3RequestParams.Body = Buffer.from(attachmentData.fileData as string, 'base64');
                    s3RequestParams.ContentType = this.utils.getMimeTypeForFileEnding(this.utils.getFileEnding(attachmentData.filePath));
                }

                let attachmentPutRequest = await this.s3
                    .upload(s3RequestParams) // uploads in AWS.S3.ManagedUpload.minPartSize chunks - minimum 5MB chunks
                    .promise();

                // uploadResults.push({
                //     filePath: attachmentPath,
                //     response: attachmentPutRequest,
                // });
                this.logger.log('[SYNC UPSTREAM] successfully uploaded attachment', attachmentPath);

                this.logItemSync('up');

                // delete the attachment in the attachment uploads folder
                try {
                    await this.attachmentSrv.deleteFileFromDisk(attachmentPath, 'upload-only');
                } catch (delErr) {
                    this.logger.error(
                        '[SYNC UPSTREAM] could not delete attachment from uploads folder after successful upload. this will not break anything but the attachment will be uploaded again',
                        delErr
                    );
                }
            } catch (attachmentSyncErr) {
                this.logger.error('[SYNC UPSTREAM] could not upload attachment', attachmentSyncErr, attachmentPath);
                this.utils.sentryCaptureException(attachmentSyncErr);
            }
        }

        // cleanup attachment upload folder to remove empty directories
        try {
            await this.attachmentSrv.cleanupEmptyFolders(environment.DISK_FOLDER_ATTACHMENTS_TO_UPLOAD, Directory.Data);
        } catch (cleanupErr) {
            this.logger.error('[SYNC UPSTREAM] could not cleanup attachment uploads folder after successful sync', cleanupErr);
        }

        return this.uploadAttachments();

        // return true;
    }

    /**
     * Uploads items which need to be synced. Updates the item in local db with new version number after successful sync
     * Returns an observable which completes after the items were uploaded and updated locally
     */
    private uploadItems() {
        return forkJoin([this.db.getItemsBySyncStatus(false), from(this.storage.get('dp_docs_to_delete'))]).pipe(
            switchMap((itemsToSync: Array<any>) => {
                let itemsToUpload = itemsToSync[0] as Array<BaseItem>;
                let itemIdsToDelete = itemsToSync[1] as Array<string>;

                if (itemsToUpload.length == this.db.numberOfItemsToUpstreamSyncAtOnce) {
                    // if the max number of items is uploaded call upstream sync again, to check if there is more to sync
                    this.startUpstreamSyncAgain = true;
                }

                let itemIdsToDeleteAtOnce = 30;
                if (Array.isArray(itemIdsToDelete) && itemIdsToDelete.length > itemIdsToDeleteAtOnce) {
                    // we cannot delete more than X items at once, because the upstream sync api endpoint would timeout after 29 seconds and fail so we only take the first slice and call upstream sync again afterwards
                    itemIdsToDelete = itemIdsToDelete.slice(0, itemIdsToDeleteAtOnce);
                    this.startUpstreamSyncAgain = true;
                }

                if (itemsToUpload.length == 0 && (itemIdsToDelete == null || itemIdsToDelete.length == 0)) {
                    return of([]);
                } else {
                    // remove unnecessary info from items, build upload payload
                    let payload = {
                        itemsToUpload: itemsToUpload.map((x) => {
                            delete x.synced;
                            delete x._rev;
                            return x;
                        }),
                        itemIdsToDelete: itemIdsToDelete,
                        userName: this.authSrv.authInfo.userData.userName,
                        userId: this.authSrv.authInfo.userData.sk,
                    };

                    // send put request for sync items
                    return this.http.put<SyncResponse[]>(environment.AWS_API_URL + '/sync/upstream', payload);
                }
            }),
            switchMap((syncResponse) => {
                let updateObservables = [];
                let syncedDeletedItemIds = []; // contains item ids which were successfully deleted on server (used to update local storage that the items were successfully deleted)

                // build requests to update local items after sync
                let itemsToUpdateInLocalDb = [];
                for (let itemUpdate of syncResponse) {
                    if (itemUpdate.synced === true) {
                        let item = itemUpdate.item;

                        // update synced item in local db with new info
                        item.synced = true;
                        item.syncedAt = itemUpdate.syncedAt;
                        item.version = itemUpdate.version;
                        itemsToUpdateInLocalDb.push(item);
                    } else if (itemUpdate.deleted === true) {
                        // add item id to delete array so we remove them from the local storage afterwards
                        syncedDeletedItemIds.push(itemUpdate.id);
                    } else {
                        // error handling if file could not be synced because of conflict or file could not be deleted
                        this.logger.error('upstream sync for item failed', itemUpdate);
                        this.utils.sentryCaptureMessage('[SYNC] upstream failed for item');
                    }
                }

                if (itemsToUpdateInLocalDb.length > 0) {
                    updateObservables.push(this.db.put(itemsToUpdateInLocalDb, false, 'if-same-or-newer'));
                    // log item upload finished
                    this.logItemSync('up', itemsToUpdateInLocalDb.length);
                }

                if (syncedDeletedItemIds.length > 0) {
                    updateObservables.push(
                        defer(async () => {
                            // get docs to delete from local storage
                            let docsToDelete = await this.storage.get('dp_docs_to_delete');
                            if (docsToDelete == null) {
                                docsToDelete = [];
                            }

                            // find document ids which were deleted in array and remove them, as we have just deleted them online
                            for (let itemId of syncedDeletedItemIds) {
                                let foundItemIndex = docsToDelete.findIndex((x) => x == itemId);
                                if (foundItemIndex > -1) {
                                    docsToDelete.splice(foundItemIndex, 1);
                                }
                            }

                            return await this.storage.set('dp_docs_to_delete', docsToDelete);
                        })
                    );
                }

                if (updateObservables.length == 0) {
                    return of('done');
                } else {
                    return forkJoin(updateObservables);
                }
            })
        );
    }

    /**
     * Handles the downstream sync process
     */
    async syncDownstream() {
        if (this.syncRunning === false && this.syncBlock$.value === true) {
            this.logger.log('[SYNC DOWNSTREAM] not syncing due to active block');
            return;
        }
        if (this.syncRunning !== false) {
            this.logger.log('[SYNC DOWNSTREAM] could not start sync, already running ' + this.syncRunning);
            return;
        }
        if (!this.utils.isOnline) {
            this.logger.log('[SYNC DOWNSTREAM] could not start sync, device is offline');
            return;
        }
        // FIXME: Set the sync status as soon as the running checks are done to prevent race conditions by e.g. Websocket messages
        // in the long term this should be fixed by using some design pattern like a semaphore
        this.setSyncStatus('downstream');

        await this.utils.keepAwake('downstream sync');
        this.syncInfo.currentRunDownstreamCount = 0; // reset current run sync count
        this.syncInfo.lastDownstreamStartTimestamp = Date.now();

        this.logger.log('[SYNC DOWNSTREAM] downstream sync started');

        // sync projects
        await this.downloadProjectList();
        await this.downloadProjectData();

        // sync company-wide documents (service-groups, etc.)
        await this.downloadItemsByRelation('company#' + this.authSrv.authInfo.companyData._id);

        // update last downstream sync completed timestamp
        try {
            let storageUpdateRes = await this.storage.set('dp_last_downstream_sync_completed_timestamp', Date.now());
            this.logger.log('[SYNC] updated last downstream sync completed timestamp');
        } catch (updateErr) {
            this.logger.error('[SYNC] could not update last downstream sync completed timestamp', updateErr);
            this.utils.sentryCaptureException(updateErr);
        }

        // if this was a first time sync or the last sync was too long ago, remove the current sync warning
        if (this.syncInfo.currentSyncWarning == 'first-time-sync' || this.syncInfo.currentSyncWarning == 'downstream-too-long-ago') {
            this.syncInfo.currentSyncWarning = null;
            this.syncInfo$.next(this.syncInfo);
        }

        this.setSyncStatus(false);

        // start upstream sync after downstream sync
        this.syncUpstream();
    }

    /**
     * Handles the sync process for items by given relation
     * @param relation
     */
    public async downloadItemsByRelation(relation: string) {
        if (this.currentlyDownloadingDataByRelation) {
            this.logger.warn(`[SYNC] downloadItemsByRelation ${relation} called - it is already running, will not call again`);
            return;
        }
        this.currentlyDownloadingDataByRelation = true;
        let minSyncTimestamp = await this.getLastDownstreamSyncTime(relation);

        let queryComplete = false; // we will send queries until we have all items (pagination)
        let lastEvaluatedKey = null; // we get this from dynamodb if the result doesnt include all items (pagination)
        while (queryComplete === false) {
            try {
                let params: QueryInput = {
                    TableName: environment.AWS_DYNAMO_DB_TABLE_NAME,
                    IndexName: 'relation-syncedAt-index',
                    KeyConditionExpression: '#pKeyName = :pKeyValue and #sKeyName > :sKeyValue',
                    ExpressionAttributeNames: {
                        '#pKeyName': 'relation',
                        '#sKeyName': 'syncedAt',
                    },
                    ExpressionAttributeValues: {
                        ':pKeyValue': relation,
                        ':sKeyValue': minSyncTimestamp,
                    },
                };

                if (lastEvaluatedKey != null) {
                    params.ExclusiveStartKey = lastEvaluatedKey; // used to get the next results, after our previous query
                }

                let data = await this.db.dynamoDb.query(params).promise();

                if (data.LastEvaluatedKey != null) {
                    // we have not got all items, we need to query again
                    lastEvaluatedKey = data.LastEvaluatedKey;
                } else {
                    lastEvaluatedKey = null;
                    queryComplete = true;
                }

                this.logger.log(
                    `[SYNC DOWNSTREAM] downloaded items by relation ${relation} and syncDate ${minSyncTimestamp} - got ${data.Items.length} items`
                );

                let syncError = false;
                let highestSyncedAtTimestamp = 0;

                if (data.Items.length > 0) {
                    let itemsToDelete = [];
                    let itemsToPut = [];
                    let largeItemsToPut = [];

                    for (let item of data.Items as BaseItem[]) {
                        if (item.syncedAt != null && item.syncedAt > highestSyncedAtTimestamp) {
                            highestSyncedAtTimestamp = item.syncedAt; // save the highest synced at timestamp
                        }

                        if (item.deletedAt != null && item.deletedAt > 0) {
                            // delete item in local db if was deleted or archived
                            itemsToDelete.push(item);
                        } else {
                            if (item.liS3Path != null) {
                                largeItemsToPut.push(item);
                            } else {
                                itemsToPut.push(item);
                            }
                        }
                    }

                    // put all normal items
                    if (itemsToPut.length > 0) {
                        try {
                            // we chunk the items to put to not overload rxdb
                            const chunkSize = 50;
                            for (let i = 0; i < itemsToPut.length; i += chunkSize) {
                                const chunk = itemsToPut.slice(i, i + chunkSize);
                                await this.db.put(chunk, false, 'if-newer').toPromise();
                            }
                        } catch (putErr) {
                            if (putErr == 'RangeError: Maximum call stack size exceeded') {
                                try {
                                    // sometimes the rxdb crashes if too many items are put at the same time (not really documentated)
                                    // in this case we try to put each item single, slower but should work
                                    this.logger.log('[SYNC] could not put item chunk, trying put one-by-one');
                                    for (let item of itemsToPut) {
                                        await this.db.put(item, false, 'if-newer').toPromise();
                                    }
                                } catch (putSingleErr) {
                                    this.logger.error(
                                        '[SYNC] could not put items on downstream sync, tried to put one-by-one, still failed',
                                        putSingleErr
                                    );
                                    this.utils.sentryCaptureException(putErr);
                                    syncError = true;
                                }
                            } else {
                                this.logger.error('[SYNC] could not put items on downstream sync', putErr);
                                this.utils.sentryCaptureException(putErr);
                                syncError = true;
                            }
                        }
                    }

                    // put all large items
                    if (largeItemsToPut.length > 0) {
                        try {
                            await Promise.all(
                                largeItemsToPut.map((largeItem) => {
                                    return this.downloadLargeItem(largeItem);
                                })
                            );
                        } catch (largeItemPutErr) {
                            this.logger.error('[SYNC] could not put large-items on downstream sync', largeItemPutErr);
                            this.utils.sentryCaptureException(largeItemPutErr);
                            syncError = true;
                        }
                    }

                    // handle items to delete
                    if (itemsToDelete.length > 0) {
                        try {
                            await this.db
                                .delete(
                                    itemsToDelete.map((x) => x._id),
                                    false
                                )
                                .toPromise();
                        } catch (delErr) {
                            this.logger.error('[SYNC] could not delete items on downstream sync', delErr);
                            this.utils.sentryCaptureException(delErr);
                            syncError = true;
                        }
                    }

                    this.logItemSync('down', data.Items.length);
                }

                if (!syncError) {
                    if (highestSyncedAtTimestamp == 0) {
                        highestSyncedAtTimestamp = minSyncTimestamp; // fall back to last sync timestamp if we got zero items from db
                    }
                    await this.setLastDownstreamSyncTime(relation, highestSyncedAtTimestamp);
                } else {
                    queryComplete = true;
                }
            } catch (err) {
                this.maybeHandleStorageSpaceError(err);
                this.logger.error('[SYNC DOWNSTREAM] could not sync items by relation ' + relation, err);
                this.utils.sentryCaptureMessage('[SYNC DOWNSTREAM] Local item update failed');
                queryComplete = true;
            }
        }
        this.currentlyDownloadingDataByRelation = false;
    }

    /**
     * Used to download large items (items with liS3Path set) from s3 storage
     * @private
     */
    private async downloadLargeItem(item: BaseItem) {
        // get signed url to large item content on s3
        let liSignedUrl = await this.attachmentSrv.getSignedS3UrlForAttachmentFilePath(item.liS3Path);
        // download large item json content from signed s3 url
        let itemData = await this.http.get(liSignedUrl).toPromise();
        // put
        return await this.db.put(itemData as BaseItem, false, 'if-newer').toPromise();
    }

    public async syncPlanData(plan: BuildingPlan) {
        await this.attachmentSrv.preparePlanData(plan, true);
    }

    /**
     * Checks if the item has an attachment in its properties and downloads it, if it has
     */

    /*private async downloadAttachmentsForItem(item: BaseItem) {
          let foundAttachments = this.utils.findAttachmentInObject(item);
          if (foundAttachments.length > 0) {
              console.log("[SYNC SERVICE] found attachments for item " + item._id, foundAttachments);

              // TODO CHECK IF THE ATTACHMENT FILE ALREADY EXISTS LOCALLY, NO NEED TO DOWNLOAD AGAIN

              let fileDataPromises = [];
              for (let attachment of foundAttachments) {
                  fileDataPromises.push(
                      this.s3
                          .getObject({
                              Bucket: environment.AWS_S3_BUCKET_NAME,
                              Key: environment.AWS_S3_CUSTOMER_DATA_BASE_PATH + attachment.filePath,
                          })
                          .promise()
                          .catch((e) => e)
                  );
              }
              let files = await Promise.all(fileDataPromises);
              let i = 0;
              for (let fileData of files) {
                  if (!(fileData instanceof Error)) {
                      await this.attachmentSrv.saveAttachmentToDiskPromise(
                          foundAttachments[i].fileName,
                          fileData.Body.toString("base64")
                      );
                      console.log(
                          "[SYNC SERVICE] saved attachment " +
                              foundAttachments[i]._id +
                              " for item " +
                              item._id +
                              " to local disk"
                      );
                  } else {
                      console.error(
                          "[SYNC SERVICE] could not save attachment " +
                              foundAttachments[i]._id +
                              " for item " +
                              item._id +
                              " to local disk"
                      );
                  }
                  i++;
              }
          }
      }*/

    /**
     * Handles the sync process for projects and their corresponding data
     */
    private async downloadProjectList() {
        if (this.currentlyDownloadingProjectList) {
            this.logger.warn('[SYNC] downloadProjectList called - it is already running, will not call again');
            return;
        }
        this.currentlyDownloadingProjectList = true;

        let allProjectsAlwaysVisible = false; // TODO LOAD BASED ON LICENSE TYPE SETTING
        try {
            allProjectsAlwaysVisible = this.settingsSrv.getUserRights().hasAccessToAllProjects === true;
        } catch (projectsVisibleLoadErr) {
            console.error('could not check user rights for all projects visible setting', projectsVisibleLoadErr);
        }
        if (allProjectsAlwaysVisible !== true) {
            // default handling -> download project list for currently logged in user (based on project sharing items)
            this.logger.log('[SYNC] downloading project list');
            let minSyncTimestampCompany = await this.getLastDownstreamSyncTime('company-project-list');
            let minSyncTimestampUser = await this.getLastDownstreamSyncTime('user-project-list');
            let startTimestamp = Date.now();
            let userHighestSyncedAtTimestamp = 0;
            let companyHighestSyncedAtTimestamp = 0;

            let projectShareItemsForCompany: Array<ProjectSharing> = [];
            let projectShareItemsForUser: Array<ProjectSharing> = [];

            let queryFailed = false;

            // get project share items for company
            let companyQueryComplete = false; // we will send queries until we have all items (pagination)
            let companyLastEvaluatedKey = null; // we get this from dynamodb if the result doesnt include all items (pagination)
            while (companyQueryComplete == false) {
                try {
                    let params: QueryInput = {
                        TableName: environment.AWS_DYNAMO_DB_TABLE_NAME,
                        IndexName: 'projectSharedWith-syncedAt-index',
                        KeyConditionExpression: '#pKeyName = :pKeyValue and #sKeyName > :sKeyValue',
                        ExpressionAttributeNames: {
                            '#pKeyName': 'projectSharedWith',
                            '#sKeyName': 'syncedAt',
                        },
                        ExpressionAttributeValues: {
                            ':pKeyValue': 'company#' + this.authInfo.companyData._id,
                            ':sKeyValue': minSyncTimestampCompany,
                        },
                    };

                    if (companyLastEvaluatedKey != null) {
                        params.ExclusiveStartKey = companyLastEvaluatedKey; // used to get the next results, after our previous query
                    }

                    let projectShareDataCompany = await this.db.dynamoDb.query(params).promise();

                    if (projectShareDataCompany.LastEvaluatedKey != null) {
                        // we have not got all items, we need to query again
                        companyLastEvaluatedKey = projectShareDataCompany.LastEvaluatedKey;
                    } else {
                        companyLastEvaluatedKey = null;
                        companyQueryComplete = true;
                    }

                    for (let shareData of projectShareDataCompany.Items) {
                        let projectSharing = new ProjectSharing(shareData);
                        projectShareItemsForCompany.push(projectSharing);

                        if (projectSharing.syncedAt > companyHighestSyncedAtTimestamp) {
                            companyHighestSyncedAtTimestamp = projectSharing.syncedAt;
                        }
                    }
                } catch (err) {
                    this.logger.error('[SYNC DOWNSTREAM] could not download project list for company', err);
                    this.utils.sentryCaptureException(err);
                    companyQueryComplete = true;
                    queryFailed = true;
                }
            }

            // get project share items for user
            let userQueryComplete = false; // we will send queries until we have all items (pagination)
            let userLastEvaluatedKey = null; // we get this from dynamodb if the result doesnt include all items (pagination)
            while (userQueryComplete == false) {
                try {
                    let params: QueryInput = {
                        TableName: environment.AWS_DYNAMO_DB_TABLE_NAME,
                        IndexName: 'projectSharedWith-syncedAt-index',
                        KeyConditionExpression: '#pKeyName = :pKeyValue and #sKeyName > :sKeyValue',
                        ExpressionAttributeNames: {
                            '#pKeyName': 'projectSharedWith',
                            '#sKeyName': 'syncedAt',
                        },
                        ExpressionAttributeValues: {
                            ':pKeyValue': 'user#' + this.authInfo.userData.userName,
                            ':sKeyValue': minSyncTimestampUser,
                        },
                    };

                    if (userLastEvaluatedKey != null) {
                        params.ExclusiveStartKey = userLastEvaluatedKey; // used to get the next results, after our previous query
                    }

                    let projectShareDataUser = await this.db.dynamoDb.query(params).promise();

                    if (projectShareDataUser.LastEvaluatedKey != null) {
                        // we have not got all items, we need to query again
                        userLastEvaluatedKey = projectShareDataUser.LastEvaluatedKey;
                    } else {
                        userLastEvaluatedKey = null;
                        userQueryComplete = true;
                    }

                    for (let shareData of projectShareDataUser.Items) {
                        let projectSharing = new ProjectSharing(shareData);
                        projectShareItemsForUser.push(projectSharing);
                        if (projectSharing.syncedAt > userHighestSyncedAtTimestamp) {
                            userHighestSyncedAtTimestamp = projectSharing.syncedAt;
                        }
                    }
                } catch (err) {
                    this.logger.error('[SYNC DOWNSTREAM] could not download project list for user', err);
                    this.utils.sentryCaptureException(err);
                    userQueryComplete = true;
                    queryFailed = true;
                }
            }

            try {
                this.logger.log(
                    '[SYNC DOWNSTREAM] downloaded project share items for company ' +
                        this.authInfo.companyData._id +
                        ' with syncDate > ' +
                        minSyncTimestampCompany,
                    projectShareItemsForCompany
                );

                this.logger.log(
                    '[SYNC DOWNSTREAM] downloaded project share items for user ' +
                        this.authInfo.userData.userName +
                        ' with syncDate > ' +
                        minSyncTimestampUser,
                    projectShareItemsForUser
                );

                // loop over all company projectShareItems and handle them
                for (let companyProjectShare of projectShareItemsForCompany) {
                    if (companyProjectShare.deletedAt != null && companyProjectShare.deletedAt > 0) {
                        // the project share item has been deleted, we have to delete the project and all corresponding data
                        // but before we will check if there is a user project share item which would grant access to the project again (then we dont have to delete it now)
                        let existingUserShareItem = projectShareItemsForUser.find((x) => {
                            return (
                                x.projectId == companyProjectShare.projectId &&
                                x.projectSharedWith == 'user#' + this.authInfo.userData.userName &&
                                x.deletedAt == null
                            );
                        });
                        // we will also check if there is another company share item which would grant access to the project again (because of multiple saves/syncs while we were offline)
                        let existingCompanyShareItem = projectShareItemsForCompany.find((x) => {
                            return (
                                x.pk !== companyProjectShare.pk &&
                                x.projectId == companyProjectShare.projectId &&
                                x.projectSharedWith == 'company#' + this.authInfo.companyData._id &&
                                x.deletedAt == null
                            );
                        });
                        if (!existingUserShareItem && !existingCompanyShareItem) {
                            this.logger.log('[SYNC DOWNSTREAM] company project share item was deleted, will delete project locally');
                            await this.deleteProjectData(companyProjectShare.projectId);
                        } else {
                            this.logger.log(
                                '[SYNC DOWNSTREAM] company project share item was deleted, but will not delete the project as the user has access via another share item',
                                existingCompanyShareItem,
                                existingUserShareItem
                            );
                        }
                    } else {
                        // download the project item
                        await this.downloadProjectById(companyProjectShare.projectId);
                    }
                }

                // loop over all user projectShareItems and handle them
                for (let userProjectShare of projectShareItemsForUser) {
                    if (userProjectShare.deletedAt != null && userProjectShare.deletedAt > 0) {
                        // the project share item has been deleted, we have to delete the project and all corresponding data
                        // but before we will check if there is a company project share item which would grant access to the project again (then we dont have to delete it now)
                        let existingCompanyShareItem = projectShareItemsForCompany.find((x) => {
                            return (
                                x.projectId == userProjectShare.projectId &&
                                x.projectSharedWith == 'company#' + this.authInfo.companyData._id &&
                                x.deletedAt == null
                            );
                        });
                        // we will also check if there is another user share item which would grant access to the project again (because of multiple saves/syncs while we were offline)
                        let existingUserShareItem = projectShareItemsForUser.find((x) => {
                            return (
                                x.pk !== userProjectShare.pk &&
                                x.projectId == userProjectShare.projectId &&
                                x.projectSharedWith == 'user#' + this.authInfo.userData.userName &&
                                x.deletedAt == null
                            );
                        });
                        if (!existingCompanyShareItem && !existingUserShareItem) {
                            this.logger.log('[SYNC DOWNSTREAM] user project share item was deleted, will delete project locally');
                            await this.deleteProjectData(userProjectShare.projectId);
                        } else {
                            this.logger.log(
                                '[SYNC DOWNSTREAM] user project share item was deleted, but will not delete the project as the user has access via a another share item',
                                existingCompanyShareItem,
                                existingUserShareItem
                            );
                        }
                    } else {
                        // download the project item
                        await this.downloadProjectById(userProjectShare.projectId);
                    }
                }

                if (companyHighestSyncedAtTimestamp == 0) {
                    companyHighestSyncedAtTimestamp = minSyncTimestampCompany; // fall back to last sync timestamp if we got zero items from db
                }
                if (userHighestSyncedAtTimestamp == 0) {
                    userHighestSyncedAtTimestamp = minSyncTimestampUser; // fall back to last sync timestamp if we got zero items from db
                }

                if (!queryFailed) {
                    await this.setLastDownstreamSyncTime('company-project-list', companyHighestSyncedAtTimestamp);
                    await this.setLastDownstreamSyncTime('user-project-list', userHighestSyncedAtTimestamp);
                } else {
                    this.logger.log('[SYNC DOWNSTREAM] one or more project share db queries failed, will try again');
                }
            } catch (err) {
                this.logger.error('[SYNC DOWNSTREAM] could not save project share items after download', err);
                this.utils.sentryCaptureException(err);
            }
        } else {
            let currentOnlineProjects = [];
            // handling for license types where ALL PROJECTS ALWAYS VISIBLE is activated
            // get live project list from dynamodb
            let wholeProjectListQueryComplete = false; // we will send queries until we have all items (pagination)
            let wholeProjectListLastEvaluatedKey = null; // we get this from dynamodb if the result doesnt include all items (pagination)
            while (wholeProjectListQueryComplete == false) {
                try {
                    let params: QueryInput = {
                        TableName: environment.AWS_DYNAMO_DB_TABLE_NAME,
                        IndexName: 'creatorCompanyId-projectStatus-index',
                        KeyConditionExpression: '#pKeyName = :pKeyValue and #sKeyName = :sKeyValue',
                        ExpressionAttributeNames: {
                            '#pKeyName': 'creatorCompanyId',
                            '#sKeyName': 'projectStatus',
                        },
                        ExpressionAttributeValues: {
                            ':pKeyValue': this.authSrv.authInfo.companyData._id,
                            ':sKeyValue': 'in-progress',
                        },
                    };

                    if (wholeProjectListLastEvaluatedKey != null) {
                        params.ExclusiveStartKey = wholeProjectListLastEvaluatedKey; // used to get the next results, after our previous query
                    }

                    let wholeProjectListData = await this.db.dynamoDb.query(params).promise();

                    if (wholeProjectListData.LastEvaluatedKey != null) {
                        // we have not got all items, we need to query again
                        wholeProjectListLastEvaluatedKey = wholeProjectListData.LastEvaluatedKey;
                    } else {
                        wholeProjectListLastEvaluatedKey = null;
                        wholeProjectListQueryComplete = true;
                    }

                    for (let minimalProjectInfo of wholeProjectListData.Items) {
                        currentOnlineProjects.push(minimalProjectInfo);
                    }
                } catch (err) {
                    this.logger.error(
                        '[SYNC DOWNSTREAM] could not download WHOLE project list for user which always has access to all projects',
                        err
                    );
                    this.utils.sentryCaptureException(err);
                }
            }

            // get current offline projects available in local db
            let currentOfflineProjects = (await this.db.getItemsByType('project').toPromise()) as Project[];

            // loop through current online projects and check which of them are not available offline
            for (let onlineProject of currentOnlineProjects) {
                let foundIndex = currentOfflineProjects.findIndex((x) => x._id === onlineProject._id);
                if (foundIndex == -1) {
                    // project not found offline, download project to local device
                    await this.downloadProjectById(onlineProject._id);
                }
            }

            // loop through current offline projects and check which are not available online - delete them locally
            for (let offlineProject of currentOfflineProjects) {
                let foundIndex = currentOnlineProjects.findIndex((x) => x._id === offlineProject._id);
                if (foundIndex == -1) {
                    // project not found online, delete project from local device
                    await this.deleteProjectData(offlineProject._id);
                }
            }
        }

        this.currentlyDownloadingProjectList = false;
    }

    /**
     * Downloads the project item by given id and saves it to the local db
     * @private
     */
    public async downloadProjectById(projectId: string) {
        let projectData = await this.db.dynamoDb
            .get({
                TableName: environment.AWS_DYNAMO_DB_TABLE_NAME,
                Key: {
                    pk: 'project#' + projectId,
                    sk: 'project#' + projectId,
                },
            })
            .promise();

        if (projectData.Item != null) {
            // save project item to local db
            await this.db.put(new Project(projectData.Item), false).toPromise();
        }
    }

    /**
     * Downloads corresponding project data for each project that we have in our local db
     * @private
     */
    private async downloadProjectData() {
        if (this.currentlyDownloadingProjectData) {
            this.logger.warn('[SYNC] downloadProjectData called - it is already running, will not call again');
            return;
        }
        if (this.syncMode === 'limited') {
            this.logger.warn('[SYNC DOWNSTREAM] would now download project data for local projects, but sync mode is limited.');
            return;
        }

        this.currentlyDownloadingProjectData = true;
        this.logger.log('[SYNC DOWNSTREAM] downloading project data for local projects');

        // get project list from db
        let projectList = (await this.db.getItemsByType('project').toPromise()) as Project[];

        // loop over each project and download related data
        for (let project of projectList) {
            // download all items which belong to the project (jobs, building plans, reports, etc.)
            await this.downloadItemsByRelation('project#' + project._id);

            if (
                this.syncInfo.currentSyncWarning === 'first-time-sync' ||
                moment(this.lastStorageSpaceCheckTimestamp).isBefore(moment().subtract(8, 'minutes'))
            ) {
                // if we are on a first-time-sync we check available space after every project we download, otherwise we only check if 8 minutes have passed since the last check
                let newSyncMode = await this.setSyncModeBasedOnAvailableStorageOrUserType();
                if (newSyncMode === 'limited') {
                    this.logger.warn(
                        '[SYNC DOWNSTREAM] sync mode has just changed to limited -> stopping download of further project data'
                    );
                    break;
                }
            }
        }

        this.logger.log('[SYNC DOWNSTREAM] download of project data for local projects completed');
        this.currentlyDownloadingProjectData = false;
    }

    /**
     * Deletes corresponding project data for given projectId
     * @param projectId
     * @param deleteProjectItemItself if the project item itself shall be deleted (default true if the project shall be removed completely,
     * false if the project item shall stay on the device (only on db cleanup because of limited sync)
     * @private
     */
    private async deleteProjectData(projectId: string, deleteProjectItemItself = true) {
        this.logger.log('[SYNC DOWNSTREAM] deleting project data', projectId);
        try {
            // delete project item itself
            if (deleteProjectItemItself) {
                await this.db.delete(projectId, false).toPromise();
            }

            // get project sharing items and delete them
            let projectSharings = (await this.db
                .find({
                    selector: {
                        projectId: {
                            $eq: projectId,
                        },
                        type: {
                            $eq: 'project-sharing',
                        },
                    },
                })
                .toPromise()) as ProjectSharing[];

            if (Array.isArray(projectSharings)) {
                await this.db
                    .delete(
                        projectSharings.map((x) => x._id),
                        false
                    )
                    .toPromise();
            }

            // delete all project related data (like jobs, building plans, etc)
            let itemsLeft = true;
            let itemsToHandleAtOnce = 100;
            while (itemsLeft) {
                let projectRelatedItems = (await this.db
                    .getItemsByRelation('project#' + projectId, itemsToHandleAtOnce)
                    .toPromise()) as BaseItem[];
                if (Array.isArray(projectRelatedItems)) {
                    if (projectRelatedItems.length >= itemsToHandleAtOnce) {
                        await this.db
                            .delete(
                                projectRelatedItems
                                    .filter((x) => {
                                        return x.type !== 'project' || (x.type === 'project' && deleteProjectItemItself);
                                        0;
                                    })
                                    .map((x) => x._id),
                                false
                            )
                            .toPromise();
                    } else {
                        // less than chunk size received from db, so this was our last chunk
                        itemsLeft = false;
                    }
                } else {
                    itemsLeft = false;
                }
            }

            // remove downstream sync time for this project
            await this.storage.remove('dp_sync_project#' + projectId);

            // try to clean the local filesystem - if it fails proceed as usual
            try {
                await this.attachmentSrv.cleanFolder(environment.DISK_FOLDER_ATTACHMENTS + projectId, Directory.Data);
            } catch (cleanDirEx) {
                this.logger.warn('[SYNC DOWNSTREAM] could not clear files from system while deleting project', cleanDirEx);
            }
        } catch (err) {
            this.logger.error('[SYNC DOWNSTREAM] could not delete project data', err);
            this.utils.sentryCaptureException(err);
        }
    }

    /**
     * Checks if a cleanup is needed which cleans up unused project data if sync-mode is limited and executes db compaction
     * @private
     */
    private async cleanupDatabase() {
        if (this.syncRunning !== false) {
            this.logger.warn('[CLEANUP] could not start cleanup, already running ' + this.syncRunning);
            return;
        }

        // get timestamp of last cleanup
        let lastCleanupTimestamp = await this.storage.get('dp_last_db_cleanup_timestamp');
        if (lastCleanupTimestamp == null) {
            if (this.syncMode === 'limited') {
                // if we just logged in for the first time and already are in limited sync we do not have local data yet except the very minimum - "skip" this round of cleanup of no data
                lastCleanupTimestamp = Date.now();
                await this.storage.set('dp_last_db_cleanup_timestamp', lastCleanupTimestamp);
            } else {
                lastCleanupTimestamp = 0;
            }
        }

        // lastCleanupTimestamp = moment().subtract(12, "days").get("milliseconds");

        lastCleanupTimestamp = parseInt(lastCleanupTimestamp);
        let cleanupNeeded = false;

        if (
            this.syncMode === 'default' &&
            (lastCleanupTimestamp === 0 || moment(lastCleanupTimestamp).isBefore(moment().subtract(6, 'days')))
        ) {
            // if sync mode is default and our last cleanup is more than 6 days ago we do a cleanup
            cleanupNeeded = true;
            this.logger.warn('[SYNC] db cleanup needed because last cleanup is more than 6 days ago');
        } else if (
            this.syncMode === 'limited' &&
            (lastCleanupTimestamp === 0 || moment(lastCleanupTimestamp).isBefore(moment().subtract(3, 'days')))
        ) {
            // if sync mode is limited and our last cleanup is more than 3 days ago we do a cleanup
            cleanupNeeded = true;
            this.logger.warn('[SYNC] db cleanup needed because last cleanup is more than 3 days ago and sync mode is limited');
        } else if (this.forceCleanupOnNextCheck) {
            cleanupNeeded = true;
            this.forceCleanupOnNextCheck = false;
            this.logger.warn('[SYNC] db cleanup forced because sync mode has just changed to limited');
        }

        if (cleanupNeeded) {
            // check if there are un-synced items, then we won't do a cleanup
            let unsyncedItems = (await this.db.getItemsBySyncStatus(false).toPromise()) as Array<BaseItem>;
            if (unsyncedItems.length > 0) {
                this.logger.warn('[SYNC] db cleanup canceled because there are un-synced items', unsyncedItems);
                cleanupNeeded = false;
            }
        }

        if (cleanupNeeded) {
            this.logger.warn('[SYNC] starting db cleanup');
            this.setSyncStatus('cleanup');
            this.syncInfo.currentSyncWarning = 'cleanup-running';
            this.syncInfo$.next(this.syncInfo);

            await this.utils.keepAwake('sync cleanup');

            if (this.syncMode === 'limited') {
                try {
                    // loop through available projects and delete the local project data (except the project item itself) of unused projects
                    this.logger.warn('[SYNC] cleanup - deleting project data of unused projects');

                    // get project list from db
                    let projectList = (await this.db.getItemsByType('project').toPromise()) as Project[];

                    // check which of the projects was used in the last 5 days (we will keep them)
                    let lastUsedProjects = await this.storage.get('dp_last_used_projects');
                    if (lastUsedProjects == null || !Array.isArray(lastUsedProjects)) {
                        lastUsedProjects = [];
                    }

                    let projectIdsToDelete = []; // ids of projects which will be deleted
                    let newLastUsedProjects = []; // the new updated value of the last used projects array

                    // loop through local projects and check if they are in our last-used array
                    for (let project of projectList) {
                        let foundInLastUsedProjectsIndex = lastUsedProjects.findIndex((x) => x.projectId === project._id);
                        if (foundInLastUsedProjectsIndex > -1) {
                            // project was found, check if it was used in the last 5 days
                            if (moment(lastUsedProjects[foundInLastUsedProjectsIndex].timestamp).isBefore(moment().subtract(5, 'days'))) {
                                // project was not used, delete local data from device to free space
                                this.logger.warn(
                                    `[SYNC] cleanup - will delete project ${project.name} (${project._id}) because it was not used in the last 5 days (timestamp too old)`
                                );
                                projectIdsToDelete.push(project._id);
                            } else {
                                // project was used, keep info in lastUsedProjects array
                                newLastUsedProjects.push(lastUsedProjects[foundInLastUsedProjectsIndex]);
                            }
                        } else {
                            // project was not found in the last used projects array, delete local data from device to free space
                            this.logger.warn(
                                `[SYNC] cleanup - will delete project ${project.name} (${project._id}) because it was not used in the last 5 days (not found in last-used array)`
                            );
                            projectIdsToDelete.push(project._id);
                        }
                    }

                    // update last used projects
                    await this.storage.set('dp_last_used_projects', newLastUsedProjects);

                    // loop over each unused project and delete related data
                    for (let projectId of projectIdsToDelete) {
                        // delete all items which belong to the project (jobs, building plans, reports, etc.) except the project item itself
                        await this.deleteProjectData(projectId, false);
                    }
                    this.logger.warn('[SYNC] cleanup - deletion of unused project data finished');
                } catch (projectCleanupErr) {
                    this.logger.error('[SYNC] cleanup - deletion of unused projects failed');
                    this.utils.sentryCaptureException(projectCleanupErr);
                }
            }

            // update last cleanup timestamp
            try {
                await this.storage.set('dp_last_db_cleanup_timestamp', Date.now());
            } catch (timestampUpdateErr) {
                this.logger.error('[SYNC] cleanup - could not update last cleanup timestamp', timestampUpdateErr);
                this.maybeHandleStorageSpaceError(timestampUpdateErr);
                this.utils.sentryCaptureException(timestampUpdateErr);
            }

            // allow sleep again
            await this.utils.allowSleep('sync cleanup');
            this.setSyncStatus(false);
            this.syncInfo.currentSyncWarning = null;
            this.syncInfo$.next(this.syncInfo);
        }
    }

    /**
     * Changes the sync status and emits the syncRunning behaviour subject
     */
    private setSyncStatus(status: false | 'upstream' | 'downstream' | 'cleanup') {
        this.syncRunning = status;
        this.syncRunning$.next(this.syncRunning);
    }

    /**
     * Logs the sync status for given item
     * @param direction
     * @param count
     */
    private logItemSync(direction: 'up' | 'down', count = 1) {
        // increase count
        if (direction === 'up') {
            this.syncInfo.currentRunUpstreamCount += count;
            this.syncInfo.totalUpstreamCount += count;
        } else if (direction === 'down') {
            this.syncInfo.currentRunDownstreamCount += count;
            this.syncInfo.totalDownstreamCount += count;
        }

        let currentTimestamp = Date.now();
        if (this.lastSyncInfoUpdateTimestamp < currentTimestamp - 4000) {
            // emit change only every 4 seconds so we dont overload other components which subscribe to this subject
            this.lastSyncInfoUpdateTimestamp = currentTimestamp;
            this.syncInfo$.next(this.syncInfo);
        }
    }

    /**
     * Gets last downstream sync time of given item type
     * @param type
     */
    private getLastDownstreamSyncTime(type: string) {
        return new Promise<number>((resolve) => {
            this.storage
                .get('dp_sync_' + type)
                .then((syncTime) => {
                    if (syncTime != null) {
                        resolve(parseInt(syncTime));
                    } else {
                        resolve(0);
                    }
                })
                .catch((syncStatusErr) => {
                    this.logger.error('[SYNC DOWNSTREAM] could not get last sync time of ' + type, syncStatusErr);
                    resolve(0);
                });
        });
    }

    /**
     * Sets last downstream sync time of given item type
     * @param type
     * @param time
     */
    private setLastDownstreamSyncTime(type: string, time: number) {
        return new Promise((resolve, reject) => {
            this.storage
                .set('dp_sync_' + type, time)
                .then(() => {
                    this.logger.log('[SYNC DOWNSTREAM] updated last sync time for ' + type, time);
                    resolve(undefined);
                })
                .catch((syncTimeUpdateError) => {
                    this.logger.error('[SYNC DOWNSTREAM] could not set last sync time of ' + type, syncTimeUpdateError);
                    this.utils.sentryCaptureException(syncTimeUpdateError);
                    reject(syncTimeUpdateError);
                });
        });
    }

    /**
     * Helper function to determine if our exception belongs to a group of errors which result from storage space issues. If it does we throw the user to the auth page and prevent him from using the app until storage space is cleared. This is for the worst case scenario where no more space is available
     * @param err an error object - either DOMException or other Error objects from exception
     * @returns boolean indicating if the given error results from storage issues
     */
    private maybeHandleStorageSpaceError(err) {
        if (err?.code == DOMException.QUOTA_EXCEEDED_ERR) {
            this.handleStorageSpaceError();
            return true;
        } else if ((err?.error || err?.code == 500) && err?.reason == 'QuotaExceededError') {
            this.handleStorageSpaceError();
            return true;
        } /*else if (
			err?.code == DOMException.INVALID_STATE_ERR ||
			err?.message?.indexOf("Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing") != -1
		) {
			this.handleStorageSpaceError();
			return true;
		}*/

        return false;
    }

    /**
     * Function to work in tandem with maybeHandleStorageSpaceError
     * Shows error message and calls the redirect
     */
    private handleStorageSpaceError() {
        if (this.authSrv.isLoggedIn) {
            this.notify.error(
                'Die Synchronisation wurde unterbrochen, da kein freier Speicher mehr zur Verfügung steht. Bitte gib Speicherplatz frei und versuche es erneut.',
                'Speicher voll',
                'QUOTA_EXCEEDED_ERR',
                false,
                30000
            );
            this.authSrv.requireAuth('STORAGE_ERR');
        }
    }

    /**
     * Upload files for report type
     * @param fileData the file data with path and data string
     */
    async uploadFileForReportType(fileData: Array<{ path: string; data: string }>) {
        for (let file of fileData) {
            const mimeTypeOfFile = this.utils.getMimeTypeForFileEnding(this.utils.getFileEnding(file.path));
            let s3Put = await this.s3
                .putObject({
                    Body: file.data,
                    Bucket: 'dokupit',
                    Key: 'customer-data/' + file.path,
                    ContentType: `${mimeTypeOfFile}${mimeTypeOfFile === 'text/html' ? '; charset=UTF-8' : ''}`,
                })
                .promise();
            this.logger.log('[SYNC UPSTREAM] successfully saved report file with data', file, s3Put);
        }
    }

    /**
     * Download html and css file for report type
     * @param htmlPath the path to the html file on s3
     * @param cssPath the path to the css file on s3
     */
    async downloadFilesForReportType(htmlPath: string, cssPath: string) {
        let htmlSignedUrl = await this.attachmentSrv.getSignedS3UrlForAttachmentFilePath(htmlPath);
        let cssSignedUrl = await this.attachmentSrv.getSignedS3UrlForAttachmentFilePath(cssPath);

        // console.log(htmlSignedUrl);
        let htmlFileQuery = this.http.get(htmlSignedUrl, { responseType: 'text' }).toPromise();
        let cssFileQuery = this.http.get(cssSignedUrl, { responseType: 'text' }).toPromise();

        let reportTypeData = await Promise.all([htmlFileQuery, cssFileQuery]);
        return {
            html: reportTypeData[0],
            css: reportTypeData[1],
        };
    }

    setSyncBlock(enable: boolean) {
        this.logger.warn('[SYNC] Updating sync block ' + JSON.stringify(enable));
        this.syncBlock$.next(enable);
    }

    /**
     * Function to upload a single attachment to s3
     * @param s3RequestParams
     */
    async uploadSingleAttachment(s3RequestParams) {
        return await this.s3
            .upload(s3RequestParams) // uploads in AWS.S3.ManagedUpload.minPartSize chunks - minimum 5MB chunks
            .promise();
    }
}
